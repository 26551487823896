import {useState, useEffect, useRef} from 'react';
import {Box, Menu, Pressable, Center, Text as TextNative, HStack, Divider, useTheme} from 'native-base';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionBarHelper from '~/Helper/ActionBarHelper';
import _ from 'lodash';
import {sharePost, deletePost, setCommentationStatus} from '~/Helper/PostHelper';
import { useNavigate, useParams } from "react-router-dom";
import Moment from 'moment';
import 'moment/min/moment-with-locales';

import { useSelector } from 'react-redux';


const ActionBar = (props) => {
    const currentUser = useSelector((state) => state.user);

    const { colors } = useTheme();
    const [actionItems, setActionItems] = useState([]);
    const [visibleComment, setVisibleComment] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    
    const handleGetVisibleComment = () => {
      return visibleComment;
    };

    const sharePost = async (post) => {
        /*Moment.locale('de');
        const date = Moment(post.created_at).format('LLL');
        let message = `Schau dir mal diesen Beitrag an`;

        if (post?.title !== null) {
            message = `Schau dir mal diesen Beitrag an "\n${post.title}" vom ${date}`;
        } 

        await Share.share({
            "message" : message + ': ' + 'https://www.meet4ride.de/forum/detail/' + post.post_id,
            "url" : 'https://www.meet4ride.de/forum/detail/' + post.post_id,
            "title" : 'test' 
        });*/
    };

    const buttonComments = {
        name : 'Kommentieren', 
        icon : {
            size: 25,
            icon : 'fa-solid fa-comment',
        }, 
        onPress : () => {
            props.handler.setParentComment({});
            setVisibleComment(!visibleComment);
        }
    };

    const buttonEdit = {
        menu : <Menu w='900' marginBottom={2} bg={'coolGray.100'} placement={'top right'} trigger={(triggerProps) => {
            return <Pressable {...triggerProps} cursor='pointer' alignItems='center' opacity={0.8} py="3" flex={1}>
              <Center>
                <FontAwesomeIcon color='white' size={25} icon={'fa-solid fa-circle-ellipsis'} />
                <TextNative color="white" fontSize="12">Mehr</TextNative>
              </Center>
            </Pressable>;
          }}>

          {!parseInt(props.post.is_system) &&   
            <Menu.Item mb={3} onPress={() => {
                navigate('/posts/form/edit/'+ params.post_id)
            }}>
                <HStack alignItems={'center'}>
                <FontAwesomeIcon color={colors.muted['500']} size={25} icon={'fa-solid fa-pencil'} />
                <Divider orientation="vertical" />
                <TextNative ml={3}>Bearbeiten</TextNative>
                </HStack>
            </Menu.Item>
          }
  
          <Menu.Item onPress={() => {
              deletePost(props.post.post_id).then(() => {
                navigate('/posts');
              });
              
          }}>
            <HStack alignItems={'center'}>
              <FontAwesomeIcon color={colors.danger['500']} size={25} icon={'fa-solid fa-times'} />
              <Divider orientation="vertical" />
              <TextNative color={colors.danger['500']} ml={3}>Löschen</TextNative>
            </HStack>
          </Menu.Item>
        </Menu>,
      };

    let buttonBase = [{
        name : 'Teilen', 
        icon : {
          size: 25,
          icon : 'fa-solid fa-share-alt',
        }, 
        onPress : () => sharePost(props.post)
      }
    ];
    
    useEffect(() => {

        if (!_.isEmpty(currentUser)) {
            
            if (currentUser.data.id == props.post.user_id) {
                buttonBase.push({...buttonEdit});
            }

            if (props.post.comments_disabled == '0') {
                buttonBase.unshift(buttonComments);
            }

            setActionItems([...buttonBase]);
        }

    },[visibleComment]);

    return (
        <Box style={{ position: 'absolute', bottom: 0, width: '100%'}} >
          <ActionBarHelper {...props} items={actionItems} />
        </Box>
    );
  
  };

  export default ActionBar;