import {useState, useEffect, useRef, useContext} from 'react';
import {Box, Menu, Pressable, Center, Text, HStack, Divider, useTheme} from 'native-base';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionBarHelper from '~/Helper/ActionBarHelper';
import { useSelector } from 'react-redux';
import DateHelper from '~/Helper/DateHelper';
import Config from '~/Config/BaseConfig';
import { useNavigate } from "react-router-dom";
import TourHelper from '~/Helper/TourHelper.js';
import _ from 'lodash';
import {toasty} from '~/Helper/Base';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import uuid from 'react-uuid';
import { isMobile } from 'react-device-detect';

import AddToCalendar from 'react-add-to-calendar';

const ActionBar = (props) => {
    const currentUser = useSelector((state) => state.user);
    const menuSettings = {
        w : isMobile ? 900 : 'auto',
        placement: isMobile ? 'top right' : 'left',
        marginBottom: isMobile ?  2 : 0,
        marginRight: isMobile ? 0 : '25px'
    };


    const { colors } = useTheme();
    const [actionItems, setActionItems] = useState([]);
    const [visibleComment, setVisibleComment] = useState(false);    
    const tourUsers = props.tourUsers;
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    const handleGetVisibleComment = () => visibleComment;

    const shareTour = async () => {
        const startDate = DateHelper.formatDate(props.tour.start_date + ' ' + props.tour.start_time, 'L LT');
        /*
        const result = await Share.share({
            message : `Schau dir mal diese Tour an:\n${props.tour.title} am ${startDate}: ` + 'https://www.meet4ride.de/touren/detail/' + props.tour.tour_id,
            url : 'https://www.meet4ride.de/touren/detail/' + props.tour.tour_id,
            title : 'Tour' 
        });
        */
    };

    const _tourAttendAction = async(status) => {
        props.handler.setLoading(true);

        /*status: maybe, safe, no */
        if (!_.isEmpty(currentUser)) {
            await fetch(Config.host + '/api/touren/attend/' + status + '/' + props.tour.tour_id + '/', {
                headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash}
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.status == 'error' && json.message == 'refresh_driver_profile') {
                    SweetAlert.fire({
                        'title' : 'Fahrerprofil unvollständig',
                        'text' : `Um an der Tour teilnehmen zu können, bitten wir dich dein Fahrerprofil zuverfolgständigen`,
                        showCancelButton: true,
                        customClass : {
                            confirmButton: 'btn btn-danger'
                        },
                        confirmButtonText: 'Vervollständigen',
                        cancelButtonText: 'Jetzt nicht'
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            navigate('/profile/driver')
                        }
                    });

                } else {
                    if (status == 'safe') {
                        toasty('Du nimmst teil', 'success');
                    }
                    if (status == 'maybe') {
                        toasty('Du bist interessiert', 'warning');
                    }
                    if (status == 'no') {
                        toasty('Du hast dich abgemeldet');
                    }

                    props.handler.refreshTour();
                }
            })
        };
    }

    const _tourDeleteAction = async() => {

        const _doDelete = async () => {
            await fetch(Config.host + '/api/touren/remove/' + props.tour.tour_id + '/', {
                headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash}
            })
            .then((response) => {
                props.handler.setLoading(false);
                navigate(-1);
            })
        }

        SweetAlert.fire({
            'text' : `Möchtest du die Tour wirklich löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        }).then((result) => {
            if (result.isConfirmed) {
                props.handler.setLoading(true);
                
                if (!_.isEmpty(currentUser)) {
                    _doDelete();
                };
            }
        })
    }

    const buttonYes = {
        name : 'Zusagen', 
        icon : {
            size: 25,
            icon : 'fa-solid fa-plus',
        }, 
        onPress : () => {
            _tourAttendAction('safe');
        }
    };

    const buttonMaybe = {
        name : 'Interessiert', 
        icon : {
            size: 25,
            icon : 'fa-solid fa-star',
        }, 
        onPress : () => {
            _tourAttendAction('maybe');
        }
    };

    const buttonDissmiss = {
        name : 'Absagen', 
        icon : {
            size: 25,
            icon : 'fa-solid fa-times',
        }, 
        onPress : () => {
            _tourAttendAction('no');
        }
    };


    const _addToCalendar = async (child) => {
        /*
        Moment.locale('de');
        let calendar = null;

        
        let eventTitle = '';
        const tourType = props.tour.tourtype;

        if (tourType == 'tour' ||tourType == 'quicktrip') {
            eventTitle += 'Tour ';
        } else if (tourType == 'training') {
            eventTitle += 'Training ';
        } else if (tourType == 'Event') {
            eventTitle += 'Veranstaltung ';
        }
        
        eventTitle += '"'+ props.tour.title +'" von ' + props.tourFull.guides.guide.fullname;
        
        var startDate = Moment(props.tour.start_date + ' ' + props.tour.start_time);

        const eventDetails = {
            title : eventTitle,
            startDate: new Date(startDate.format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T')),
            endDate: new Date(startDate.add(props.tour.duration, 'hours').format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T')),
            location: props.tour.start_location
        }


        return <AddToCalendar event={eventDetails}>{child}</AddToCalendar>
        */      
    };
    
    
    
    const buttonEdit = {
        menu : <Menu key={uuid()} {...menuSettings} trigger={(triggerProps) => {
            return <Pressable {...triggerProps}  alignItems='center' opacity={0.8} py="3" flex={1}>
                <Center>
                    <FontAwesomeIcon color='white' size={'2xl'} icon={'fa-solid fa-gear'} />
                    <Text color="white" fontSize="11">GUIDE</Text>
                </Center>
            </Pressable>;
            }}>
            <Menu.Item paddingTop={4} paddingBottom={4} onPress={() => {
                navigate('/touren/form/add/' + props.tour.tourtype + '/' + props.tour.tour_id)
            }}>
                <HStack alignItems={'center'}>
                    <FontAwesomeIcon color={colors.muted['500']} size={'xl'} icon={'fa-solid fa-pencil'} />
                    <Divider orientation="vertical" />
                    <Text ml={3}>Bearbeiten</Text>
                </HStack>
            </Menu.Item>

            <Menu.Item paddingTop={4} paddingBottom={4} onPress={() => {
                navigate('/touren/form/copy/' + props.tour.tourtype + '/' + props.tour.tour_id)
            }}>
                <HStack alignItems={'center'}>
                    <FontAwesomeIcon color={colors.muted['500']} size={'xl'} icon={'fa-solid fa-copy'} />
                    <Divider orientation="vertical" />
                    <Text ml={3}>Kopieren</Text>
                </HStack>
            </Menu.Item>

            <Menu.Item paddingTop={4} paddingBottom={4} onPress={() => {
                _tourDeleteAction();
            }}>
                <HStack alignItems={'center'}>
                    <FontAwesomeIcon color={colors.danger['500']} size={'xl'} icon={'fa-solid fa-times'} />
                    <Divider orientation="vertical" />
                    <Text color={colors.danger['500']} ml={3}>Löschen</Text>
                </HStack>
            </Menu.Item>
        </Menu>,
    };


    const buttonMore = {/*
        name : 'Teilen', 
        icon : {
            size: 25,
            icon : 'fa-solid fa-share-alt',
        }, 
        onPress : () => {
            _tourAttendAction('safe');
        }*/
    };

    /*{

        menu : <Menu key={uuid()} bg={'coolGray.100'} {...menuSettings}  trigger={(triggerProps) => {
            return <Pressable {...triggerProps} alignItems='center' opacity={0.8} py="3" flex={1}>
              <Center>
                <FontAwesomeIcon color='white' size={'2xl'} icon={'fa-solid fa-circle-ellipsis'} />
                <Text color="white" fontSize="11">MEHR</Text>
              </Center>
            </Pressable>;
          }}>
          <Menu.Item paddingTop={4} paddingBottom={4} onPress={shareTour}>
            <HStack alignItems={'center'}>
              <FontAwesomeIcon color={colors.muted['500']} size={'xl'} icon={'fa-solid fa-share-alt'} />
              <Divider orientation="vertical" />
              <Text ml={3}>Tour teilen</Text>
            </HStack>
          </Menu.Item>
          <Menu.Item paddingTop={4} paddingBottom={4} onPress={_addToCalendar}>
            <HStack alignItems={'center'}>
              <FontAwesomeIcon color={colors.muted['500']} size={'xl'} icon={'fa-regular fa-calendar-circle-plus'} />
              <Divider orientation="vertical" />
              <Text ml={3}>Zum Kalender hinzufügen</Text>
            </HStack>
          </Menu.Item>
  
        </Menu>,
      };*/

    let buttonBase = [];
    
    useEffect(() => {
      const tourStatus = TourHelper.getTourStatus(props.tour.start_date, props.tour.start_time, props.tour.duration);

      if (!_.isEmpty(currentUser)) {
        buttonBase.push({...buttonMore});
        let canUserAttend = true;

        if (currentUser?.data?.id && currentUser?.data?.id == props.tour.guide_id) {    
            canUserAttend = false;
            buttonBase.push({...buttonEdit});
        }
        

        if (tourStatus.status > 1 && canUserAttend) {
          
            if (typeof _.findKey(props.tourUsers['yes'], ['id', currentUser.data.user_id]) !== 'undefined') {
                setActionItems([buttonDissmiss, ...buttonBase]);
            } else if (typeof _.findKey(props.tourUsers['maybe'], ['id', currentUser.data.user_id]) !== 'undefined') {
                setActionItems([buttonYes, buttonDissmiss, ...buttonBase]);
            } else {
                setActionItems([buttonYes, buttonMaybe, ...buttonBase]);
            }
        
        } else {
            setActionItems(buttonBase);
        }


      };

    }, [currentUser]);


    return (    
        <Box style={{ position: 'absolute', bottom: 0, width: '100%'}} >
          <ActionBarHelper {...props} items={actionItems} />
        </Box>
    );
  
  };

  export default ActionBar;