import { Text, useTheme } from "native-base";

const DashedLine = ({style}) => {
    const {colors} = useTheme();

    return <div style={{
        height:0,
        borderTopWidth: 1,
        borderTopStyle: 'dashed',
        borderTopColor: colors.muted['500'],
        marginTop: 25, 
        marginBottom: 25,
        ...style
    }}>&nbsp;</div>;
};

export default DashedLine;