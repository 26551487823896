import React, { useState, useEffect, useRef } from 'react';
import { HStack, Avatar, Text, Box, Skeleton, Pressable, Heading, View, Badge, useTheme, Actionsheet, Divider, VStack, useToast} from 'native-base';
import UserHelper from '~/Helper/UserHelper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';
import { chipColors } from '~/Config/PostConfig';
import Config from '~/Config/BaseConfig';
import { toasty } from '~/Helper/Base';
import { simpleFormData } from '~/Helper/FormHelper';
import {sharePost, deletePost, setCommentationStatus} from '~/Helper/PostHelper';
import PostContent from '~/Components/Posts/Detail/Content';
import ReactionBar from '~/Components/Reactions/ReactionBar';
import DateHelper from '~/Helper/DateHelper';
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import uuid from 'react-uuid';
import Comments from '~/Components/Comments/CommentBox';

import { Dropdown } from 'semantic-ui-react'

const PostItem = ({ post}) => {
    const { colors } = useTheme();
    const toast = useToast();
    const currentUser = useSelector((state) => state.user);
    const [visible, setVisible] = useState(true);
    const [postData, setPostData] = useState(post);
    const [pollData, setPollData] = useState(null);
    const [imagesData, setImagesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const SweetAlert = withReactContent(Swal);
    const navigate = useNavigate();

    const _load = async () => {
        
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/forum/get/' + post.post_id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({ 'includes': 'post,poll,images,reactions'})
            })
            .then((res) => res.json())
            .then((json) => {
                if (typeof json.post.tags == 'string') {
                    json.post.tags = JSON.parse(json.post.tags);
                }

                setPostData(json.post);
                setPollData(json.poll);
                setImagesData(json.images);
                setLoading(false);
          });
        };
    };

    useEffect(() => {
        if (currentUser && loading) {
            _load();
        }
    }, [currentUser])
    
    const _deletePost = async(post_id) => {
        SweetAlert.fire({
            'text' : `Möchtest du den Beitrag wirklich löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                fetch(Config.host + '/api/forum/remove/' + post_id + '/', {
                    headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash}
                }).then(() => {
                    toasty('Beitrag gelöscht');
                    setVisible(false);
                });
            }
        });
    };

    const _commentationStatus = async(post_id, status) => {
        await setCommentationStatus(post_id, status).then(() => {
            setPostData({...postData, comments_disabled: status});
            const text = (status == 0) ? 'Kommentarfunktion aktiviert' : 'Kommentarfunktion deaktiviert';
            toasty(text);
        });
    }

    const [showComments, setShowComments] = React.useState(false);
    
    if (visible) {
        return (
            <div style={{backgroundColor: 'white', padding: 15, marginBottom: 15, zIndex: null}}>
                {(loading)
                ?   
                    <VStack padding={15} space={3}>
                        <HStack space={3}>
                            <Skeleton h={50} w={50} rounded="full" />
                            <Skeleton.Text lines={2} />
                        </HStack>
                        <Skeleton.Text mt={3} lines={2} />
                        <Skeleton mt={3} h={150}/>
                    </VStack>
                
                : <>
                    <HStack zIndex={0} justifyContent={'space-between'} alignItems={'center'}>
                        <HStack maxW={'70%'} space={3} justifyContent={'flex-start'} alignItems={'center'}>
                            <Avatar
                                alt={'UserImage'} 
                                alignSelf="center" 
                                source={{uri:UserHelper.getUserimage(postData.user)}} 
                                size="md"
                            />
                            <VStack>
                                <HStack alignItems={'flex-end'}>
                                    <Heading fontSize={'lg'}>{UserHelper.buildUsername(postData.user)}</Heading>
                                    <Text fontSize={'xs'} ml={1}>{DateHelper.humanize(postData.created_at)}</Text>
                                </HStack>
                                
                                <HStack style={{marginLeft: -2}} flexWrap={'wrap'}>
                                    
                                    {typeof postData.tags == 'object' && postData.tags.length > 0 && 
                                        postData.tags.map((item, idx) => 
                                            <Badge
                                                rounded={'full'}
                                                _text={{fontSize:10, padding: 0, color: chipColors[item].text}}
                                                style={{margin: 2, borderWidth:0, paddingLeft: 8, paddingRight: 8, backgroundColor: chipColors[item].bg}}
                                                key={`chip_${idx}`} 
                                                >{item}</Badge>

                                        )
                                    }
                                </HStack>
                            </VStack>
                        </HStack>
                        
                    </HStack>

                    <View p={1}>
                        <PostContent key={uuid()} post={postData} poll={pollData} images={imagesData} reloadFunc={_load}/>
                    </View>
                    

                    <Divider h={.3} my={2} /> 
                    
                    <HStack zIndex={1050} space={3} alignItems={'center'} justifyContent={'space-between'} p={1}>
                        <ReactionBar id={postData.post_id} type={'post'} ownerUserId={postData.user_id} />

                        {!showComments && postData.commentCount >= 0 && parseInt(postData.comments_disabled) === 0 && 
                            <Pressable onPress={() => setShowComments(true)}>
                                <HStack alignItems={'center'} space={2} justifyContent={'flex-start'}>
                                    <Text>{postData.commentCount}</Text>
                                    <FontAwesomeIcon color={colors.coolGray['500']} size={15} icon={'fa-regular fa-comment'} />
                                </HStack>
                            </Pressable>
                        }

                        {showComments && postData.commentCount >= 0 && parseInt(postData.comments_disabled) === 0 && 
                            <Pressable onPress={() => setShowComments(false)}>
                                <HStack alignItems={'center'} space={2} justifyContent={'flex-start'}>
                                    <Text>Kommentare ausblenden</Text>
                                </HStack>
                            </Pressable>
                        }
                        
                        {currentUser?.data?.id > 0 && currentUser?.data?.id == postData.user_id && 

                            <Dropdown style={{zIndex: 5}} direction='left' className='actions-dropdown' text={<FontAwesomeIcon size={20} color={colors.black} icon="fa-solid fa-ellipsis" />}>
                                <Dropdown.Menu>
                                    
                                    <>
                                        {!parseInt(postData.is_system) && postData.comments_disabled == '0' && 
                                            <Dropdown.Item onClick={() => _commentationStatus(postData.post_id, 1)} text={
                                                <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Badge w={30} h={30} rounded={'full'}><FontAwesomeIcon icon={'fa-solid fa-comment-times'} /></Badge>
                                                    <Text>Kommentare deaktivieren</Text>
                                                </HStack>
                                            } />
                                        }

                                        {!parseInt(postData.is_system) && postData.comments_disabled == '1' && 
                                            <Dropdown.Item onClick={() => _commentationStatus(postData.post_id, 0)} text={
                                                <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Badge w={30} h={30} rounded={'full'}><FontAwesomeIcon icon={'fa-solid fa-comment-check'} /></Badge>
                                                    <Text>Kommentare aktivieren</Text>
                                                </HStack>
                                            } />
                                        }
                                        
                                    
                                        {!parseInt(postData.is_system) &&
                                            <Dropdown.Item onClick={() => navigate('/posts/edit/' + postData.post_id)} 
                                                text={
                                                    <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Badge w={30} h={30} rounded={'full'}><FontAwesomeIcon icon={'fa-regular fa-pencil'} /></Badge>
                                                    <Text>Bearbeiten</Text>
                                                </HStack>
                                            } />
                                        }
                                        
                                        <Dropdown.Item onClick={() => _deletePost(postData.post_id)} text={
                                                <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                                <Badge w={30} h={30} rounded={'full'}><FontAwesomeIcon icon={'fa-solid fa-times'} /></Badge>
                                                <Text>Löschen</Text>
                                            </HStack>
                                        } />
                                    </>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </HStack>

                    {showComments && postData.comments_disabled == '0' && <View mt={10}><Comments master_id={postData.post_id} type={'post'}/></View>}

                </>
                }
        
            </div>
        );
    }
};

export default React.memo(PostItem);