import React, {useState, useEffect, FlatList} from 'react';
import Loader from '~/Components/Template/Loader';
import {Spinner, Divider} from 'native-base';
import { useSelector } from 'react-redux';
import ActionBar from '~/Components/Posts/List/ActionBar';
import Config from '~/Config/BaseConfig';
import {
    Box,
    View,
    Badge,
    HStack,
    Flex,
    Input,

    Stack,
    Button,
    Text,
    Center,
    useTheme
} from 'native-base';
import Moment from 'moment';
import _, { set } from 'lodash';
import { simpleFormData } from '~/Helper/FormHelper';
import PostItem from '~/Components/Posts/List/Item';
import {toasty, wait} from '~/Helper/Base';
import InfiniteScroll from 'react-infinite-scroll-component';
//import PostFakeBox from '~/components/Posts/PostFakeBox';
import {Helmet} from "react-helmet";
import { AdMobBanner } from '~/Components/AdMobs';
import uuid from 'react-uuid';

const PostScreen = ({route, ...props }) => {
    const { colors } = useTheme();
    const currentUser = useSelector((state) => state.user);
    const [scrollPos, setScrollPos] = React.useState(0);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    
    let limit = 100;

    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [endReached, setEndReached] = useState(false);

    const [triggerLoad, setTriggerLoad] = React.useState(false);
    const [triggerReload, setTriggerReload] = React.useState(false);

    const _fetchList = async () => {
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/forum/collection/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({ 'filter': JSON.stringify({
                    "active" : 1,
                    "limit" : limit, 
                    "page": page
                })})
            })
            .then((re) => re.json())
            .then((json) => {
                if (json.length == 0) {
                    setEndReached(true);
                } else {
                    let itemsToAdd = [];
                    
                    json.map((item) => {
                        if (_.filter(items, _item => parseInt(_item.post_id) != parseInt(item.post_id))) {
                            itemsToAdd.push(item);
                        }
                    })

                    setItems([...items, ...itemsToAdd]);
                }
                setLoading(false);
            });
        };
    };
    
    
    useEffect(() => {
        Moment.locale('de');

        if (currentUser && items.length == 0) {
            _handleReload();
        }
    }, [currentUser]);
    
    /**
     * Load and append Items to List
    */
    useEffect(() => {
        if (((triggerLoad && !triggerReload) || (!triggerLoad && triggerReload)) ) {
                    
            if (triggerLoad) {
                setTriggerLoad(false);
            }

            if (triggerReload) {
                setTriggerReload(false);
            }

            _fetchList();    
        }
    }, [triggerLoad, triggerReload])
    

    /** *******************************************************************
     *  FUNCTIONS
     */
    
    /**
     * Keep data and load new tours
     */
    const _handleLoad = () => {
        setLastPage(page);
        setPage(page+1);
        setTriggerLoad(true);
    }

    /**
     * Handle: Reload Data List
     * @param resetFilter   
     *          Set true if filter is relevant for request
     */
    const _handleReload = (resetFilter, extendData = {}) => {
        setLoading(true);
        setItems([]);
        setPage(0);
        setEndReached(false);
        setTriggerReload(true);
    }

    /**
     * Handle fetch Scrollposition
    */
    const handleScroll = (v) => {
        //setScrollPos(window.pageYOffset);
    };

    const _onEndReached = () => {
        if (!endReached) {
            _handleLoad();
        }
    }
    
    
    return (
    <div style={{marginTop: 30}}>
        <React.Fragment>
            <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={_handleLoad}
                hasMore={!endReached}
                style={{overflowX: 'hidden'}}
                loader={<Spinner m={15} mb={150} size={'lg'} />}
                endMessage={
                    <Box _text={{textAlign:'center', textTransform: 'uppercase', color: colors.muted['400']}} style={{margin: 15, marginTop: 10, padding: 15, marginBottom: 150}}>Keine weiteren Einträge</Box>
                }

                >
                {items.map((item, index) => {
                    return (
                        <div id={uuid()}>
                            <PostItem post={item} />
                        </div>
                    )
                })}
            </InfiniteScroll>

            <ActionBar />
        </React.Fragment>
        <Helmet><title>Beiträge</title></Helmet>
    </div>
    );

    
}


export default PostScreen;