import _ from "lodash";
import { Heading, Text, Image} from "native-base";
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';

export const convertBikes = (bikes) => {
    let response = [];

    if (typeof bikes == 'string' && bikes != 'undefined' && bikes != 'null') {
        response = JSON.parse(bikes);
    }

    return response;
}

export const BikeName = ({bike}) => {
    let data = [];

    if (!_.isNil(bike.manufacturer) && !_.isEmpty(bike.manufacturer))
        data.push(bike.manufacturer);

    if (!_.isNil(bike.series) && !_.isEmpty(bike.series))
        data.push(bike.series);

    if (!_.isNil(bike.yearof) && !_.isEmpty(bike.yearof))
        data.push('('+ bike.yearof +')');
    
    if (!_.isEmpty(data)) {
        return <Heading color={StylesConfig.color.base} size={'md'}>{data.join(' ')}</Heading>;
    }
};

export const BikeDetails = ({bike}) => {
    let data = [];

    {!_.isNil(bike.color)&& !_.isEmpty(bike.color) && 
        data.push(<Text>Farbe: {bike.color}</Text>);
    }
    
    {!_.isNil(bike.type) && !_.isEmpty(bike.type) &&  
        data.push(<Text>Typ: {bike.type}</Text>);
    }
    
    {!_.isNil(bike.displacement)&& !_.isEmpty(bike.displacement) && 
        data.push(<Text>Hubraum: {bike.displacement}</Text>);
    }
    
    {!_.isNil(bike.kennzeichen)&& !_.isEmpty(bike.kennzeichen) && 
        data.push(<Text>Kennzeichen: {bike.kennzeichen}</Text>);
    }

    return <>{data}</>;
};

export const BikeImage = ({bike}) => {
    if (!_.isNil(bike.image)) {
        return (
            <a data-fancybox href={Config.uploadDir +'bikes/'+ bike.image}>
                <Image rounded={'full'} size="lg" alt={'Bike Image'} source={{uri : Config.uploadDir +'bikes/'+ bike.image}}/>
            </a>
        );
        
    }
};

export const hasBikes = (bikes) => {
    let response = false;

    if (typeof bikes == 'string') {
        bikes = convertBikes(bikes);
    }
    
    if (!_.isNil(bikes) && (_.isObject(bikes))) {
        response = true;
    }

    if (_.isArray(bikes) && bikes.length == 0) {
        response = false;
    }

    return response;
};