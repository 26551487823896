import * as React from 'react';
import { View, StyleSheet} from "react-native";
import {Helmet} from "react-helmet";
import UserList from '~/Views/Users/List';

const Users = () => {
    return (
        <>
            <View style={{ height: '100%' }}>
                <UserList/>
            </View>
            <Helmet><title>Mitgliederliste</title></Helmet>
        </>
      
  );
}

const tabStyles = StyleSheet.create({
  topbar: {
    position: "relative",
    top: 0,
    marginTop: 0
  }
});

export default Users;