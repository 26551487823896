import {useEffect, useState} from 'react';
import Config from '~/Config/BaseConfig';
import {Box, Badge, HStack, Flex, Input, Stack, View, FlatList, Avatar, VStack, Text, useTheme, Actionsheet, Divider, IconButton, Center, Pressable} from 'native-base';

import Moment from 'moment';
import 'moment/min/moment-with-locales'

import { useNavigate } from "react-router-dom";

//Helpers
import { useSelector } from 'react-redux';

import UserHelper from '~/Helper/UserHelper';
import {simpleFormData} from '~/Helper/FormHelper';
import Loader from '~/Components/Template/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import uuid from 'react-uuid';
import { Dropdown } from 'rsuite';


const NotificationItem = ({item, setNotificationOpen}) => {
    const [visible, setVisible] = useState(true);
    const { colors } = useTheme();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.user);
    
    const _goto = async (item) => {
        if (item.type == 'post') {
            setNotificationOpen(false);
            navigate('/posts/detail/' + item.master_id)
        }
    
        if (item.type == 'friends_approved' || item.type == 'friends_add') {
            setNotificationOpen(false);
            navigate('/members/direct/' + item.creator_user_id)
        }
    
        if (item.type == 'tour' || item.type == 'tour_comment'  || item.type == 'tour_attend'  || item.type == 'tour_dismiss') {    
            setNotificationOpen(false);
            navigate('/touren/detail/' + item.master_id)
        }
        
    
    }


    const _deleteNotification = async (itemId) => {
    
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/notifications/remove/' + currentUser.data.id + '/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash},
                body: simpleFormData({data : JSON.stringify({id : itemId})})
            })
            .then((res) => res.json());
        };
    }

    return (<>
      {visible && 
        <div maxW={'100%'} style={{padding: 10}} backgroundColor={(item.viewed == '0') ? colors.coolGray['200'] : colors.muted['50'] }>
            
                <div style={{display: 'flex', flexDirection: 'row', gap: '20px', zIndex: 1900}}>
                    
                    <Pressable onPress={() => _goto(item)}>
                        <Avatar size={'lg'} source={{uri : UserHelper.getUserimage(item.creator)}}>
                            {UserHelper.isOnline(item.creator) && <Avatar.Badge bg="green.500"/>}
                        </Avatar>
                    </Pressable>

                    <Pressable  style={{flex: 1, flexShrink: 1}} onPress={() => _goto(item)}>
                        <VStack>
                            <Text isTruncated noOfLines={2} style={{maxW:'80%'}}>{item.message}</Text>
                            <Box _text={{fontSize: 'xs'}}>{item.humanized}</Box>
                        </VStack>
                    </Pressable>
                    
                    <Pressable onPress={() => {
                        _deleteNotification(item.notification_id);
                        setVisible(false);
                    }}>
                        <Badge rounded={'full'} w={25} h={25}>
                            <FontAwesomeIcon icon={'fa-solid fa-times'} />
                        </Badge>
                    </Pressable>
                    
                </div>
            
        </div>
      }
      </>
    );
  };

const Notifications = ({setNotificationOpen}) => {
  const { colors } = useTheme();
  const currentUser = useSelector((state) => state.user);
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  
  
  const _onMount = async () => {
    if (!_.isEmpty(currentUser)) {
      fetch(Config.host + '/api/notifications/collection/', {
        method: 'POST',
        headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash},
        body: simpleFormData({filter : JSON.stringify({
            user_id : currentUser.data.id,
            page : page,
            limit : limit
          })
        })
      })
      .then((res) => res.json())
      .then((json) => {
        setItems(json);
        setLoading(false);
      });
    };
  }

  
    
  useEffect(() => {
    Moment.locale('de');

    if (currentUser) {
        setLoading(true);
        _onMount()
    }

  }, [currentUser]);
  

    
  return (
      <View style={{ height: '100%' }}>
        <Loader loading={loading} />
        
         {items.length > 0 && 
          <FlatList  
              data={items}
              maxToRenderPerBatch={20}
              renderItem={({item, index}) => 
                <NotificationItem setNotificationOpen={setNotificationOpen} key={index} item={item} />
              }
          />
          }
          {items.length == 0 && 
            <Center><Box mt={5} textAlign={'center'}>Keine Benachrichtigungen vorhanden</Box></Center>
          }
        
      </View>
  );
}


export default Notifications;