// Import React and Component
import * as React from 'react';
import { View, HStack, ScrollView, Box, Image, Flex, AspectRatio, Pressable, useTheme, VStack, Text, Avatar, Divider, Heading, Badge} from 'native-base';
import StylesConfig from '~/Config/Styles';
import Config from '~/Config/BaseConfig';
import Loader from '~/Components/Template/Loader';
import { simpleFormData } from '~/Helper/FormHelper';
import { useSelector } from 'react-redux';
import {chipColors} from '~/Config/PostConfig';

import UserHelper from '~/Helper/UserHelper';
import ActionBar from '~/Components/Posts/Detail/ActionBar';
import PostComments from '~/Components/Comments/CommentBox';
import ReactionBar from '~/Components/Reactions/ReactionBar';
import PostContent from '~/Components/Posts/Detail/Content';
import DateHelper from '~/Helper/DateHelper';
import _ from 'lodash';

import { useParams } from "react-router-dom";

const PostDetailScreen = ({...props}) => {  
    const [item, setItem] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [parentComment, setParentComment] = React.useState({});
    const [reloadComments, setReloadComments] = React.useState(true);
    const [layoutCords, setLayoutCords] = React.useState({});
    const currentUser = useSelector((state) => state.user);
    const params = useParams();

    React.useEffect(() => {
        if (currentUser) {
            _fetchItem();
        }
    }, [currentUser]);
    

    const _fetchItem = async () => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/forum/get/' + params.post_id + '/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({'includes' : 'post,poll,images'})
            })
            .then((res) => res.json())
            .then((json) => {                
                if (typeof json.post.tags == 'string') {
                    json.post.tags = JSON.parse(json.post.tags);
                }
                setItem(json);
                setLoading(false);
            });
        };
    };

      if (loading == true) {

        return (<View style={{ flex: 1 }}><Loader loading={loading} /></View>);
    
      } else {
        return (
            <>
                <View h={'100%'}>
        
                    <Loader loading={loading} />
                    <View style={{ flex: 1, padding: 0, marginBottom: 80 }}>
        
                    <View style={StylesConfig.styles.card.wrapper}>
                        <View style={StylesConfig.styles.card.header}>
                            <HStack justifyContent={'space-between'} alignItems={'center'}>
                                <HStack maxW={'70%'} space={3} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Avatar
                                        alt={'UserImage'} 
                                        alignSelf="center" 
                                        source={{uri:UserHelper.getUserimage(item.post.user)}} 
                                        size="md"
                                    />
                                    <VStack>
                                        <HStack alignItems={'flex-end'}>
                                            <Heading fontSize={'lg'}>{UserHelper.buildUsername(item.post.user)}</Heading>
                                            <Text fontSize={'xs'} ml={1}>{DateHelper.humanize(item.post.created_at)}</Text>
                                        </HStack>
                                        
                                        <HStack style={{marginLeft: -2}} flexWrap={'wrap'}>
                                            
                                            {typeof item.post.tags == 'object' && item.post.tags.length > 0 && 
                                                item.post.tags.map((item, idx) => 
                                                    <Badge
                                                    rounded={'full'}
                                                    _text={{fontSize:10, padding: 0, color: chipColors[item].text}}
                                                    style={{margin: 2, borderWidth:0, paddingLeft: 8, paddingRight: 8, backgroundColor: chipColors[item].bg}}
                                                    key={`chip_${idx}`} 
                                                    >{item}</Badge>
                                                )
                                            }
                                        </HStack>
                                    </VStack>
                                </HStack>
                                
                            </HStack>
                        </View>
                        <View style={{...StylesConfig.styles.card.body, paddingBottom: 0, paddingTop: 5}}>
                            <PostContent post={item.post} poll={item.poll} images={item.images} reloadFunc={_fetchItem}/>

                            <Divider h={.3} my={2} /> 
                    
                            <HStack space={3} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                                <ReactionBar id={item.post.post_id} type={'post'} ownerUserId={item.post.user_id}/>
                            </HStack>
                        </View>


                    </View>
        
                        <View onLayout={(event) => { setLayoutCords({ ...layoutCords, description: event.nativeEvent.layout.y }) }}></View>
                        
                        {item.post.comments_disabled == '0' && 
                            <View style={StylesConfig.styles.card.wrapper}>
                                <View style={StylesConfig.styles.card.header}>
                                    <Text style={StylesConfig.styles.card.header_text}>Kommentare</Text>
                                </View>

                                <View style={StylesConfig.styles.card.body}>
                                    <PostComments
                                        master_id={item.post.post_id}
                                        type={'post'}
                                        key={'postCommentsBox'}
                                    />

                                </View>
                            </View>
                        }
                    
                    </View>
        
                </View>
                            
                <ActionBar
                    key="postsDetailActionBar"
                    post={item.post}
                    handler={{
                        setParentComment: setParentComment,
                        setReloadComments: setReloadComments,
                        refreshPost : _fetchItem,
                        setLoading : setLoading
                    }}
                    handlerProps={{
                        parentComment: parentComment,
                        reloadComments: reloadComments
                    }} />
        </>  
        );
    
      }
};

export default PostDetailScreen;