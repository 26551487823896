import React from "react";
import {View, Text, Spinner, Image, VStack, Input, HStack, Box, Button, Center, Heading, useTheme} from 'native-base';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import history from '~/Services/History';
import Config from '~/Config/BaseConfig';
import Loader from '~/Components/Template/Loader';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ReactSession } from 'react-client-session';
import {simpleFormData} from '~/Helper/FormHelper';
import {wait} from '~/Helper/Base';

import GooglePlacesAutocomplete, {geocodeByPlaceId, geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';

export default function Address(props) {

    const {colors} = useTheme();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});

    React.useEffect(() => {
        let registredFormData = ReactSession.get('register_formdata');
        setFormData({...registredFormData});
    }, []);


    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
                <Loader loading={loading} />
                <Center>
                    <Box shadow={5} padding={8} rounded={'full'} backgroundColor={colors.success['600']}>
                        <FontAwesomeIcon color='white' size={60} icon={'fa-solid fa-check'} />
                    </Box>

                    <Heading mt={5} size="md" color={'white'}>Fertig!</Heading>
                        
                    <VStack space={3} w={['2/3', '2/6', '1/3']}>
                        
                        <Text p={5} fontSize={'lg'} textAlign={'center'} color='white'>
                            Du hast soeben eine E-Mail zur Verifizierung erhalten. Bitte klicke auf den Link um dein Konto zu bestätigen, danach 
                            kannst du dich bei M4R mit deinen Zugangsdaten anmelden. 
                            {"\n"}
                            {"\n"}
                            Schau auch bitte in deinem Spam-Ordner nach. Solltest du keine E-Mail erhalten habe, bitte kontaktieren uns:
                        </Text>

                        <HStack space={3} mt={3}>
                            <Button flex={1} backgroundColor={'#FFFFFF20'} onPress={() => {
                                window.location = 'mailto:support@meet4ride.de?subject=Problem bei der Registrierung';
                            }}>E-Mail schreiben</Button>
                            <Button flex={1} backgroundColor={'success.500'} onPress={() => navigate('/auth/login')}>Zum Login</Button>
                        </HStack>
                    </VStack>
                </Center>
            <Helmet>
                <title>Login</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
        </VStack>;
}
