
import {useState, useEffect} from 'react';
import UserPill from '~/Components/Users/UserPill';
import { HStack,Box, Divider, useTheme} from 'native-base';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import uuid from 'react-uuid';

const TourUsers = (props) => {
    const [safeUsers, setSafeUsers] = useState([]);
    const [maybeUsers, setMaybeUsers] = useState([]);
    const { colors } = useTheme();

    const loadUser = (user, type) => {
        
        if (type == 'safe') {
            let safeArr = safeUsers;
            safeArr.push(<Box key={uuid()} style={{marginBottom: 10}}><UserPill navigation={props.navigation} user={user} /></Box>);
            setSafeUsers(safeArr);
        } else {
            let maybeAr = maybeUsers;
            maybeAr.push(<Box key={uuid()} style={{marginBottom: 10}}><UserPill navigation={props.navigation} user={user} /></Box>);
            setMaybeUsers(maybeAr);
        }
    };

    useEffect(() => {
        if (!_.isEmpty(props.tourUsers.yes)) {            
            props.tourUsers.yes.map((item) => {
                loadUser(item, 'safe');
            });
        }
        
        if (!_.isEmpty(props.tourUsers.maybe)) {
            props.tourUsers.maybe.map((item) => {
                loadUser(item, 'maybe');
            });
        }
    }, []);

    return(<>
        <Row>
            <Col xs={3} sm={2}><Box _text={{fontSize: 'lg', fontWeight: 'bold', color : colors.success['500']}}>Zugesagt</Box></Col>
            <Col>
                {safeUsers.length > 0 && 
                    <Box>
                        <HStack flexWrap={'wrap'}>
                            {safeUsers}
                        </HStack>
                    </Box>
                }
            </Col>
        </Row>
        <Divider my={2}/>
        <Row>
            <Col xs={3} sm={2}><Box _text={{fontSize: 'lg', fontWeight: 'bold', color : colors.warning['500']}}>Interessiert</Box></Col>
            <Col>
                {maybeUsers.length > 0 && 
                    <Box>
                        <HStack flexWrap={'wrap'}>
                            {maybeUsers}
                        </HStack>
                    </Box>
                }
            </Col>
        </Row>
    </>
    );
    
}

export default TourUsers;