import {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

import Config from '~/Config/BaseConfig';
import {Center, Image, View, Text, Pressable} from 'native-base';
import StylesConfig from '~/Config/Styles';
import _ from 'lodash';

const Commercial = ({pos, _width, _height, showBlock, style}) => {
    const currentUser = useSelector((state) => state.user);

    const [item, setItem] = useState(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const _fetchItem = async () => {
        if (!_.isEmpty(currentUser)) {
            
            await fetch(Config.host + '/api/common/commercial/'+ parseInt(pos) +'/' + currentUser.data.id + '/', {
                method: 'POST', headers: {...Config.defaultHeaders},
            }).then(res => res.json())
            .then((json) => {
                if (json.status == 'true') {
                    Image.getSize(json.image, (width, height) => {
                        var ratio = height/width;
                        setWidth(_width);
                        setHeight(_width * ratio);
                    });

                    setItem(json);
                }
            });
            
        }
    };

    useEffect(() => {
        _fetchItem();
    }, []);

    
    if (item !== null) {
        return (
            <Center style={{...style}}>
                <Pressable onPress={() => {
                    if (item?.link?.length > 0) {
                        window.open(item.link);
                    }
                }}>
                    {showBlock != true 
                        ?   <img src={item.image} className={'img-fluid'} />
                        :   <View style={StylesConfig.styles.card.wrapper}>
                                <View style={StylesConfig.styles.card.body}>
                                    <Text style={{...StylesConfig.styles.card.header_text, fontSize: 11}}>WERBUNG</Text>
                                    <img src={item.image} className={'img-fluid'} />
                                </View>
                            </View>
                    }


                    
                </Pressable>
            </Center>
        );
    }

}

export default Commercial;