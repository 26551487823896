import React from "react";
import {Text,VStack, Input, HStack, Button, Heading, useTheme} from 'native-base';
import {Helmet} from "react-helmet";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import history from '~/Services/History';
import Loader from '~/Components/Template/Loader';

import InputMask from 'react-input-mask';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ReactSession } from 'react-client-session';
import Moment from 'moment';
import 'moment/min/moment-with-locales';

export default function Birthday(props) {

    const {colors} = useTheme();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const inputRef = React.useRef();
    const [inputValue, setInputValue] = React.useState(null);

    const _submitForm = async () => {
        setLoading(true);
        
        if (formData?.birthday) {
            let BirthdayMoment = Moment(formData.birthday);
            let now = Moment();
            
            if (_.isNaN(now.diff(BirthdayMoment, 'years'))) {
                SweetAlert.fire('Bitte gib ein gültiges Datum an');
                setLoading(false);
                return;
            }
            
            if (now.diff(BirthdayMoment, 'years') > 100) {
                SweetAlert.fire('So alt kannst du nicht sein ;) - bitte gib ein gültiges Datum an');
                setLoading(false);
                return;
            }
            
            ReactSession.set('register_formdata', {...formData});
            navigate('/auth/register/address');

        } else {
            SweetAlert.fire('Bitte gib dein Geburtsdatum an');
        }

        
    };

    React.useEffect(() => {
        let registredFormData = ReactSession.get('register_formdata');
        setFormData({...registredFormData});
        if (registredFormData?.birthday) {
            let dates = registredFormData?.birthday.split('-');
            let newDate = [dates[2], dates[1], dates[0]];
            setInputValue(newDate.join('.'));
        }

    }, []);

    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
                <Loader loading={loading} />

                <VStack space={3} w={['2/3', '2/6', '1/6']}>
                    <Text p={5} fontSize={'lg'} textAlign={'center'} color='white'>
                        <Heading color={'white'}>Fast geschafft!</Heading>
                        {"\n"}
                        {"\n"}
                        Wann hast du Geburtstag?
                    </Text>
                    
                    <InputMask onChange={(event) => {
                            let dateValue = event?.target?.value;
                            if (dateValue.indexOf('_') == -1) {
                                let dates = dateValue.split('.');
                                let newDate = [dates[2], dates[1], dates[0]];
                                setFormData({...formData, birthday: Moment(newDate.join('-')).format('YYYY-MM-DD')});
                            }
                            setInputValue(dateValue);

                        }} mask={'99.99.9999'} value={inputValue}>
                        {(inputProps) => <Input {...inputProps} style={{textAlign:'center', fontSize: 30}}  type="date" disableUnderline />}
                    </InputMask>

                    <HStack space={3} mt={3}>
                        <Button flex={1} backgroundColor={'#FFFFFF20'} onPress={() => history.back()}>Zurück</Button>
                        <Button flex={1} backgroundColor={'success.500'} onPress={() => _submitForm()}>Weiter</Button>
                    </HStack>
                </VStack>

            <Helmet>
                <title>Login</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
        </VStack>;
}
