import React from "react";
import {View, Text, VStack, HStack, Button, Heading, useTheme, Checkbox, Pressable} from 'native-base';
import {Helmet} from "react-helmet";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import history from '~/Services/History';
import Config from '~/Config/BaseConfig';
import Loader from '~/Components/Template/Loader';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ReactSession } from 'react-client-session';
import {simpleFormData} from '~/Helper/FormHelper';

import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';

export default function Address(props) {

    const {colors} = useTheme();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const [formatedAddress, setFormatedAddress] = React.useState(null);
    
    const [dsvgo, setDsvgo] = React.useState(false);
    const [haftung, setHaftung] = React.useState(false);

    const _submitForm = async () => {
        
        
        if (_.isString(formData.lat) === false && _.isNumber(formData.lat) === false ) {
            SweetAlert.fire('Bitte gib eine Adresse an');
            return;
        }
        
        if (dsvgo === false || haftung === false) {
            SweetAlert.fire('Zustimmung erforderlich', 'Bitte akzeptiere die Datenschutzbestimmungen und den Haftungsausschuss.');
            return;
        }

        setLoading(true);

        await fetch(Config.host + '/api/common/register/', {
            method: 'POST',
            headers: {...Config.defaultHeaders},
            body : simpleFormData({...formData})
        }).then((res) => {
            
            if (res.ok) {
                
                ReactSession.set('register_formdata', '');
                navigate('/auth/register/success');

            } else {

                SweetAlert.fire({
                    title: 'Fehler',
                    text : 'Leider ist ein Fehler aufgetreten. Bitte kontaktieren den Support unter: support@meet4ride.de',
                    showCancelButton: true,
                    confirmButtonText: 'Support kontaktieren',
                    cancelButtonText: 'Eingabe korrgieren'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.open('mailto:support@meet4ride.de?subject=Problem bei der Registrierung');
                    }
                });
            }

            setLoading(false);
        });

    };

    const _getLocationText = (filldata) => {
        let locationText = [];
        
        if (formData?.street) locationText.push(formData.street);
        if (formData?.zip) locationText.push(formData.zip);
        if (formData?.city) locationText.push(formData.city);
        if (formData?.region) locationText.push(formData.region);
        if (formData?.coutry) locationText.push(formData.country);
        
        if (_.isEmpty(locationText)) {
            return '';
        }

        setFormatedAddress(locationText.join(', '));
    };

    const _addressComponentRunner = (details, filldata, types) => {
        if ('address_components' in details) {
            
            _.forEach(types, (field, idx) => {
                let item = _.find(details.address_components, function(o){
                    if (o.types.includes(field)) return o;
                });
                
                if (item) {
                    filldata[idx] = (['region', 'street', 'country'].includes(idx)) 
                        ? item.short_name
                        : item.long_name;
                }
            });
            
        }
        
        if (filldata.street != null) {
            filldata.street =  filldata.street + ' ' + filldata.street_number;
        }
        
        return filldata;
    }

    const _setLocationValues = async(details) => {
        
        let filldata = {
            'street': null,
            'street_number' : null,
            'zip' : null,
            'city' : null,
            'country' : null,
            'region' : null,
            'formatedAddress' : null
        };
        
        let latLng = {
            'lat' : null,
            'lng' : null,
        }
        
        if ('geometry' in details && 'location' in details.geometry) {
            latLng = {'lat': details.geometry.location.lat(), 'lng' : details.geometry.location.lng()};
        }
        
        filldata = _addressComponentRunner(details, filldata, {
            street_number: 'street_number', 
            street : 'route', 
            city: 'locality', 
            region : 'administrative_area_level_1',
            country : 'country',
            zip: 'postal_code'});
            

        setFormData({...formData, ...filldata, ...latLng});
    };

    //Aachener Str. 1, Solingen

    const _handleSelect = (selection) => {
        geocodeByPlaceId(selection?.value?.place_id)
        .then(result => {
            _setLocationValues(result[0]);
        });
        
    }

    React.useEffect(() => {
        _getLocationText();
    });

    React.useEffect(() => {
        let registredFormData = ReactSession.get('register_formdata');
        setFormData({...registredFormData});
    }, []);


    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
                <Loader loading={loading} />

                <VStack space={3} w={['2/3', '2/6', '1/3']}>
                    <Text p={4} fontSize={'lg'} textAlign={'center'} color='white'>
                        <Heading color={'white'}>...und zuletzt:</Heading>
                        {"\n"}
                        {"\n"}
                        Wo kommst du her?
                        {"\n"}
                        <Text fontSize={15} fontWeight={'bold'}> Deine Adresse wird nicht öffentlich geteilt!</Text>
                    </Text>
                    {formatedAddress && <Text textAlign={'center'} color={'white'}><Text color={'white'} fontWeight={'bold'}>Ausgewählte Adresse:</Text> {formatedAddress}</Text>}
                    <GooglePlacesAutocomplete
                        selectProps={{
                            value: formatedAddress,
                            placeholder: 'Adresse eingeben...',
                            onChange: _handleSelect,
                        }}
                        
                        apiOptions={{ language: 'de', region: 'de' }}
                        apiKey={Config.googleApiToken} />

                    <Text textAlign={'center'} color='white' lineHeight={'sm'} mt={2} fontSize={'sm'}>
                        Je genauer du deine Adresse angibst, desto genauer werden dir Touren und Mitglieder vorgestellt.
                        {"\n"}
                        {"\n"}    
                    </Text>
                    
                    <View>
                    
                        <VStack width={'100%'} justifyContent={'flex-start'} alignItems={'flex-start'} space={1} mb={3}>
                            <Checkbox colorScheme="success" onChange={(isSelected) => setDsvgo(isSelected)}>
                                <Text fontSize={'sm'} color={'white'}>Datenschutzbestimmungen gelesen und akzeptiert</Text>
                            </Checkbox>

                            <Pressable style={{marginLeft: 26}} onPress={() => {
                                window.open(Config.host + '/pub/law/privacy/')   
                            }}>
                                <Text color={'white'} fontSize={9}>ANZEIGEN</Text>
                            </Pressable>
                        </VStack>
                        
                        <VStack justifyContent={'flex-start'} alignItems={'flex-start'} space={1} mb={3}>
                            <Checkbox colorScheme="success" mt={3} onChange={(isSelected) => setHaftung(isSelected)}>
                                <Text fontSize={'sm'} color={'white'}>Haftungsausschuss gelesen und akzeptiert für Touren, Trainings und Events.</Text>
                            </Checkbox>

                            <Pressable style={{marginLeft: 26}} onPress={() => {
                                window.open(Config.host + '/pub/law/disclaimer/')     
                            }}>
                                <Text color={'white'} fontSize={9}>ANZEIGEN</Text>
                            </Pressable>
                        </VStack>

                    </View>

                    <HStack space={3} mt={3}>
                        <Button flex={1} backgroundColor={'#FFFFFF20'} onPress={() => history.back()}>Zurück</Button>
                        <Button flex={1} backgroundColor={'success.500'} onPress={() => _submitForm()}>Registrierung abschließen</Button>
                    </HStack>
                </VStack>

            <Helmet>
                <title>Login</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
        </VStack>;
}
