import React from 'react';

import { View, Box, HStack, Avatar, Image, Text, ScrollView, Heading, Button, Badge, useTheme, Skeleton, Pressable} from "native-base";
import Loader from '~/Components/Template/Loader';
import { useDispatch, useSelector} from "react-redux";
import { useNavigate, redirect  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from "lodash";
import UserHelper from "~/Helper/UserHelper";
import StylesConfig from '~/Config/Styles';
import { ReactSession } from 'react-client-session';
import uuid from 'react-uuid';

const Friendship = () => {
    const currentUser = useSelector((state) => state.user) || JSON.parse(ReactSession.get('user'));
    const [friendlist, setFriendlist] = React.useState([]);
    const [waitingFriendlist, setWaitingFriendlist] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const SweetAlert = withReactContent(Swal);
    const { colors } = useTheme();
    const navigate = useNavigate();

    const _fetchFriendlist = async () => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
            await fetch(Config.host + '/api/user/get/' + currentUser.data.id + '/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'includes':'friends'})
            })
            .then((res) => res.json())
            .then((json) => {
                let waiting = [];
                let approved = [];
                json?.friends.map(item => {
                    if (item.type == 'sender' && item.status == 2) {
                        waiting.push(item);
                    } else {
                        approved.push(item);
                    }
                });
                
                setWaitingFriendlist(waiting);
                setFriendlist(approved);
                setLoading(false);
            });
        };
    };

    const _removeFriend = async (user_id, name) => {
        SweetAlert.fire({
            'text' : `Möchtest du ${name} wirklich löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                setLoading(true);

                    if (!_.isEmpty(currentUser)) {
            
                        fetch(Config.host + '/api/user/action/friends/delete/' + currentUser.data.id + '/', {
                            method: 'POST',
                            headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                            body : simpleFormData({'receiver_id' : user_id})
                        })
                        .then(() => {
                            _fetchFriendlist();
                        })
                    };
            }
        });
    }

    const _approveFriend = async (user_id) => {
        !loading && setLoading(true);
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/friends/approve/' + currentUser.data.id + '/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'receiver_id' : user_id})
            })
            .then(() => {
                _fetchFriendlist();
            })
        };
    }

    React.useEffect(() => {
        if (currentUser && loading) {
            _fetchFriendlist();
        }
    }, [currentUser]);

    const ViewTpl = ({user, status, type}) => {

        return <HStack key={user.id} marginBottom={5} justifyContent={'space-between'}>

            <a data-fancybox={uuid()} href={'/members/detail/' + user.id} data-type={'iframe'} style={{flex:2}} >
                <HStack alignItems={'center'}>
                    <Avatar source={{uri : UserHelper.getUserimage(user)}} mr={3}>
                        {UserHelper.isOnline(user) ? <Avatar.Badge size={3} bg={'green.500'} /> : <Avatar.Badge size={3} bg={'red.500'} />}
                    </Avatar>
                    <Box>
                        <Text>{UserHelper.buildUsername(user)}</Text>
                        
                        {user?.city && 
                            <Text fontSize={'xs'} isTruncated >aus {user.city}</Text>
                        }
                    </Box>
                </HStack>
            </a>

            <HStack flex={1} justifyContent={'flex-end'} alignItems={'center'} space={3}>
                {type == 'sender' && status == 2 && 
                    <HStack justifyContent={'flex-end'}>
                        <Pressable style={{flex:1}} onPress={() => {
                            _removeFriend(user.id, UserHelper.buildUsername(user));
                        }}>
                            <Badge borderRadius={'full'} h={10} w={10} variant={'solid'} colorScheme="error"><FontAwesomeIcon color={'white'} icon={'fa-solid fa-times'} /></Badge>
                        </Pressable>
                        
                        <Pressable style={{flex:1}} onPress={() => {
                            _approveFriend(user.id)
                        }}>
                            <Badge borderRadius={'full'} h={10} w={10} variant={'solid'} colorScheme="success"><FontAwesomeIcon color={'white'} icon={'fa-solid fa-check'} /></Badge>
                        </Pressable>
                    </HStack>
                }

                {type == 'receiver' && status == 1 && 
                    <HStack justifyContent={'flex-end'}>
                        <Pressable style={{flex:1}} onPress={() => {
                            _removeFriend(user.id, UserHelper.buildUsername(user));
                        }}>
                            <Badge borderRadius={'full'} variant={'solid'} colorScheme="error">
                                <FontAwesomeIcon color={'white'} icon={'fa-solid fa-times'} />
                            </Badge>
                        </Pressable>
                    </HStack>
                }
                
                {type == 'receiver' && status == 2 && 
                    <HStack bg={colors.muted['300']} borderRadius={'full'} justifyContent={'flex-end'}>
                        <Badge bg={colors.muted['300']} borderLeftRadius={'full'}><FontAwesomeIcon color={'white'} icon={'fa-solid fa-hourglass-start'} /></Badge>

                        <Pressable  style={{flex:1}} onPress={() => {
                            _removeFriend(user.id, UserHelper.buildUsername(user));
                        }}>
                            <Badge borderRadius={'full'} variant={'solid'} colorScheme="error">
                                <FontAwesomeIcon icon={'fa-solid fa-times'} color={'white'} size={15} />
                            </Badge>
                        </Pressable>
                        
                    </HStack>
                }
            </HStack>
        </HStack>

    };

    const SkeletonPlaceholder = ({key}) => {
        return (<HStack p={5} alignItems={'center'}>
            <Skeleton w={50} h={50} mr={3} rounded={'full'}/>
            <Skeleton.Text lines={2} />
        </HStack>);
    };

    if (loading) {
        return <Loader loading={loading} />
    } else {
        return (<>
                <View>
                
                    {waitingFriendlist.length == null && friendlist.length == null && 
                        [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17].map((i, index) => <SkeletonPlaceholder />)
                    }

                    {waitingFriendlist.length > 0 &&
                        <View style={StylesConfig.styles.card.wrapperMt}>
                            <View style={StylesConfig.styles.card.header}>
                            <Text style={StylesConfig.styles.card.header_text}>ANFRAGEN</Text>
                            </View>

                            <View style={StylesConfig.styles.card.body}>
                            {
                                waitingFriendlist.map((item, idx) => {
                                    return (
                                        <ViewTpl key={idx} type={'sender'} status={item.status} user={item.user} />
                                    );
                                })
                            }
                            </View>
                        </View>
                    }

                    <View style={StylesConfig.styles.card.wrapperMt}>
                        <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>FREUNDE</Text>
                        </View>

                        <View style={StylesConfig.styles.card.body}>
                        {
                        (friendlist.length > 0)
                            
                            ?
                            friendlist.map((item, idx) => {
                                return (
                                    <ViewTpl key={idx} type={'receiver'} status={item.status} user={item.user} />
                                );    
                            })
                            
                            :
                            
                            <Box>
                                <Text style={{padding: 15, paddingTop:5, textAlign: 'center'}}>Keine Einträge vorhanden</Text>
                            </Box>
                        }
                        </View>
                    </View>
                    
                
                

                </View>
            </>
        )
    }
};

export default Friendship;