export const subnavItems = [
    {
        icon : 'fa-regular fa-user-group',
        iconColor : '',
        title : 'Freundeliste',
        titleStyle : {},
        text : 'Verwalte deine Freunde',
        textStyle: {},
        internal : '/profile/friendship'
    },
    {
        icon : 'fa-regular fa-id-card',
        iconColor : '',
        title : 'Fahrer-Profil',
        titleStyle : {},
        textStyle: {},
        internal : '/profile/driver'
    },
    {
        icon : 'fa-regular fa-garage',
        iconColor : '',
        title : 'Garage',
        titleStyle : {},
        text : 'Deine Fahrzeuge',
        textStyle: {},
        internal : '/profile/garage'
    },
    {
        icon : 'fa-solid fa-user-shield',
        iconColor : '',
        title : 'Persönliche Daten',
        titleStyle : {},
        text : 'Adresse, Social Media, etc.',
        textStyle: {},
        internal : '/profile/personal'
    },
    {
        icon : 'fa-solid fa-key',
        iconColor : '',
        title : 'Anmelde Daten',
        titleStyle : {},
        text : 'E-Mail Adresse und Kennwort',
        textStyle: {},
        internal : '/profile/auth'
    },
    {
        icon : 'fa-solid fa-truck-medical',
        iconColor : '',
        title : 'Notfall Einstellungen',
        titleStyle : {},
        text : 'Für den Fall der Fälle',
        textStyle: {},
        internal : '/profile/emergency'
    },
    {
        icon : 'fa-solid fa-shield-check',
        iconColor : '',
        title : 'Privatsphäre & mehr',
        titleStyle : {},
        text : 'Benachrichtigungen, Sprache, etc.',
        textStyle: {},
        internal : '/profile/settings'
    },
    {
        divider : true
    },
    {
        icon : 'fa-regular fa-browser',
        iconColor : '',
        title : 'Deine Notfall Seite',
        text : 'für Rettungsdienste',
        titleStyle : {},
        textStyle: {},
        showSOSLink : true
    },
    {
        image : '~/Assets/layout/logo.png',
        title : 'M4R unterstützen',
        titleStyle : {},
        text : 'mit einer Spende',
        textStyle: {},
        external : 'https://paypal.me/mee4ride?locale.x=de_DE'
    },
    {
        divider : true
    },
    {
        icon : 'fa-regular fa-right-from-bracket',
        title : 'Abmelden',
        logout : true
    },
];