import * as React from 'react';
import 'semantic-ui-css/semantic.min.css'
//import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@sweetalert2/theme-material-ui/material-ui.css';
import 'react-toastify/dist/ReactToastify.css';
import "@fancyapps/ui/dist/fancybox.css";
import 'rsuite/dist/rsuite.min.css';
import '~/Assets/less/main.css';

import {  Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { ReactSession } from 'react-client-session';
import { NativeBaseProvider, extendTheme} from "native-base";
import { Provider } from 'react-redux';
import { store } from '~/Services/Store';
import { ToastContainer} from 'react-toastify';

import Dashboard from '~/Views/Dashboard';
import Config from '~/Config/BaseConfig';

import {subnavItems as profileItems} from '~/Components/Profile/Subnav.conf.js';
import Fancybox from '~/Components/Template/Modal';
import ProfileOverview from '~/Views/Profile/Overview';
import Friendship from '~/Views/Profile/Friendship';
import Driver from '~/Views/Profile/Driver';
import Personal from '~/Views/Profile/Personal';
import AuthData from '~/Views/Profile/AuthData';
import Emergency from '~/Views/Profile/Emergency';
import Settings from '~/Views/Profile/Settings';
import Garage from '~/Views/Profile/Garage';

import Users from '~/Views/Users';
import UserDetail from '~/Views/Users/Detail';


import Touren from '~/Views/Touren';
import TourList from '~/Views/Touren/List';
import TourDetail from '~/Views/Touren/Detail';
import TourFormSelection from '~/Views/Touren/FormSelection';
import TourForm from '~/Views/Touren/Form';

import Posts from '~/Views/Posts';
import PostSingle from '~/Views/Posts/Detail';
import PostForm from '~/Views/Posts/Form';

import Support from '~/Views/Help/Support';
import {Helmet} from "react-helmet";
/** AUTH */
import Splash from '~/Views/Auth/Splash';
import Login from '~/Views/Auth/Login';
import Forgotten from '~/Views/Auth/Forgotten';
import Welcome from '~/Views/Auth/Register/Welcome';
import Email from '~/Views/Auth/Register/Email';
import Gender from '~/Views/Auth/Register/Gender';
import Birthday from '~/Views/Auth/Register/Birthday';
import Address from '~/Views/Auth/Register/Address';
import Success from '~/Views/Auth/Register/Success';
//import AdFree from '~/Views/Purchase/AdFree';

import BaseLayout from '~/Components/Template/2column';
import SmallContainerLayout from '~/Components/Template/2columnMinContainer';
import IFrameTemplate from '~/Components/Template/iframe';
import SubnavLayout from '~/Components/Template/SidebarContainer';
import uuid from 'react-uuid';

import {initializePurchases} from '~/Helper/PurchaseHelper'

function App() {
    library.add(far, fab, fas, fad);
    ReactSession.setStoreType("cookie");
    
    React.useEffect(() => {
        initializePurchases();
    }, []);

    const theme = extendTheme({
        components : {
            Heading: {
                baseStyle : {
                    fontFamily: 'Quicksand'
                }
            },
            
            Input : {
                baseStyle : {
                    backgroundColor: 'white'
                }
            }
        }
    });
    
    return (
        <Provider store={store}>
            <NativeBaseProvider theme={theme}>
                <Fancybox options={{ infinite: false, hash:false, preload:false}}>
                    <div className="wrapper">
                        <Routes>
                            <Route path="/" element={<Splash />} />
                            
                            <Route element={<SmallContainerLayout />}>
                                <Route path="/posts" element={<Posts />} />
                                <Route path="/posts/detail/:post_id" element={<PostSingle />} />
                                <Route path="/help/support" element={<Support />} />
                                <Route path="/posts/add" element={<PostForm />} />
                                <Route path="/posts/edit/:post_id" element={<PostForm />} />
                                {/*<Route path="/purchase/adfree" element={<AdFree />} />*/}
                            </Route>

                            <Route element={<IFrameTemplate/>}>
                                <Route path="/members/detail/:user_id" element={<UserDetail />} />
                            </Route>

                            <Route element={<BaseLayout/>}>
                                <Route path="/dashboard" element={<Dashboard />} />
                                
                                <Route path="/members/direct/:user_id" element={<UserDetail />} />
                                <Route>
                                    <Route path="/profile" element={<ProfileOverview />} />
                                    <Route element={<SubnavLayout items={profileItems} />}>
                                        <Route path="/profile/friendship" element={<Friendship />} />
                                        <Route path="/profile/driver" element={<Driver />} />
                                        <Route path="/profile/personal" element={<Personal />} />
                                        <Route path="/profile/auth" element={<AuthData />} />
                                        <Route path="/profile/emergency" element={<Emergency />} />
                                        <Route path="/profile/settings" element={<Settings />} />
                                        <Route path="/profile/garage" element={<Garage />} />
                                    </Route>
                                </Route>

                                <Route>
                                    <Route path="/members" element={<Users />} />
                                    <Route  element={<Touren />}>
                                        <Route path={'/touren'} element={<Navigate to={'/touren/all'}/>}/>
                                        <Route path="/touren/all" element={<TourList key={'all'} listViewtype={'all'}/>} /> 
                                        <Route path="/touren/own" element={<TourList key={'own'} listViewtype={'own'}/>} /> 
                                        <Route path="/touren/scheduled" element={<TourList key={'scheduled'} listViewtype={'scheduled'}/>} /> 
                                    </Route>
                                    <Route path={'/touren/detail/:tour_id'} element={<TourDetail/>} />
                                    <Route path={'/touren/form/select'} element={<TourFormSelection/>} />
                                    <Route path={'/touren/form/:action/:type'} element={<TourForm/>} />
                                    <Route path={'/touren/form/:action/:type/:tour_id'} element={<TourForm/>} />

                                    
                                </Route>
                                
                            </Route>
                            
                            

                            <Route>
                                <Route path="/auth/login" element={<Login />} />
                                <Route path="/auth/forgotten" element={<Forgotten />} />
                                
                                <Route>
                                    <Route path="/auth/register/welcome" element={<Welcome />} />
                                    <Route path="/auth/register/email" element={<Email />} />
                                    <Route path="/auth/register/gender" element={<Gender />} />
                                    <Route path="/auth/register/birthday" element={<Birthday />} />
                                    <Route path="/auth/register/address" element={<Address />} />
                                    <Route path="/auth/register/success" element={<Success />} />
                                </Route>
                            </Route>

                        </Routes>
                        <ToastContainer />
                    </div>
                </Fancybox>
            </NativeBaseProvider>
            <Helmet><title>Meet4Ride • Deine #1 Motorrad Community</title></Helmet>
        </Provider>
      );
  }

export default App;
