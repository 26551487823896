import * as React from 'react';
import ActionBarHelper from '~/Helper/ActionBarHelper';
import {Box} from 'native-base';
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';

const ActionBar = ({...props}) => {
    const navigate = useNavigate();
    const items = [
      {
        name : 'Beitrag',
        icon : {
          size: 25,
          icon : 'fa-solid fa-plus'
        },
        onPress : () => {
            navigate('/posts/add')
        }
      }
    ];
    
    return (
        <Box>
            <ActionBarHelper key={uuid()} items={items} {...props} />
        </Box>
    );
  
  };

  export default ActionBar;