import Moment from 'moment';
import 'moment/min/moment-with-locales';
import Config from '~/Config/BaseConfig';
import _ from 'lodash';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { store } from '~/Services/Store';

export const sharePost = async (post) => {
    /*
    Moment.locale('de');

    const date = Moment(post.created_at).format('LLL');
    let message = `Schau dir mal diesen Beitrag an: `;

    if (post?.title !== null) {
        message = `Schau dir mal diesen Beitrag an:\n${post.title} vom ${date} - `;
    } 

    await Share.share({
        "message" : message + 'https://www.meet4ride.de/forum/detail/' + post.post_id,
        "url" : 'https://www.meet4ride.de/forum/detail/' + post.post_id,
        "title" : 'test' 
    });*/
}

export const deletePost = async(post_id) => {
    const state = store.getState();
    const currentUser = state?.user;
    const SweetAlert = withReactContent(Swal);
    
    if (!_.isEmpty(currentUser)) {
        SweetAlert.fire({
            'text' : `Möchtest du den Beitrag wirklich löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                fetch(Config.host + '/api/forum/remove/' + post_id + '/', {
                    headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash}
                });
            }
        });
    };
};


export const setCommentationStatus = async(post_id, status) => {
    const state = store.getState();
    const currentUser = state?.user;

    if (!_.isEmpty(currentUser)) {
        fetch(Config.host + '/api/forum/set/commentation_status/' + post_id + '/comments_disabled/' + '/'+ status +'/', {
            headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash}
        });
    };
}