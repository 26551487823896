import React from "react";
import {View, Text, Spinner, Image, VStack, Input, HStack, Box, Button, Center, Heading, useTheme} from 'native-base';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import history from '~/Services/History';
import Config from '~/Config/BaseConfig';
import Loader from '~/Components/Template/Loader';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ReactSession } from 'react-client-session';
import {simpleFormData} from '~/Helper/FormHelper';

export default function Email(props) {

    const {colors} = useTheme();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    
    const _submitForm = async () => {
        setLoading(true);

        if (_.isEmpty(formData?.email)) {
            SweetAlert.fire('Bitte vergib eine E-Mail Adresse.');
            return;
        }

        if (_.isEmpty(formData?.pin)) {
            SweetAlert.fire('Bitte setz ein Kennwort.');
            return;
        }
        
        await fetch(Config.host + '/api/common/usercheck/', {
            method: 'POST',
            headers: {...Config.defaultHeaders},
            body : simpleFormData({'mail' : formData.email})
        }).then((res) => {
            
            if (res.ok) {
                ReactSession.set('register_formdata', {...formData});
                navigate('/auth/register/gender');
            } else {
                SweetAlert.fire({
                    text : 'E-Mail bereits vorhanden, bitte nutze die "Kennwort vergessen" Funktion',
                    showCancelButton: true,
                    confirmButtonText: '"Kennwort vergessen" nutzen',
                    cancelButtonText: 'Eingabe korrgieren'
                });
            }
            
            setLoading(false);
        });

        
    };

    React.useEffect(() => {
        setFormData({...ReactSession.get('register_formdata')});
    }, []);

    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
                <Loader loading={loading} />

                <Text p={5} fontSize={'lg'} textAlign={'center'} color='white'>
                    Bitte lege deine Zugangsdaten fest:
                </Text>
                
                <VStack space={3} w={['2/3', '2/3', '1/3']}>
                    <Input 
                        placeholder={'E-Mail'} 
                        floatOnFocus
                        autoCapitalize='none'
                        value={(formData?.email) ? _.toLower(formData.email) : null}
                        size={'lg'}
                        keyboardType={'email-address'}
                        onChangeText={(text) => {setFormData({...formData, email: _.toLower(text)})}}  
                        style={{backgroundColor: 'white', textAlign: 'center'}}
                        />
                    
                    <HStack space={2}>

                        <View flex={5}>
                        <Input 
                            placeholder={'Kennwort'} 
                            floatOnFocus
                            value={(formData?.pin) ? formData.pin : null}
                            size={'lg'}
                            autoCapitalize='none'
                            secureTextEntry={secureTextEntry}
                            onChangeText={(text) => {setFormData({...formData, pin: text})}}  
                            style={{backgroundColor: 'white', textAlign: 'center'}}
                        />

                        </View>
                        <Button flex={1} onPress={() => setSecureTextEntry(!secureTextEntry)} backgroundColor={'white'} size={'sm'}>
                            <FontAwesomeIcon color="#000000" size={'sm'} icon={'fa-solid fa-eye'}   />
                        </Button>
                    </HStack>
                    

                    <HStack space={3}>
                        <Button flex={1} backgroundColor={'#FFFFFF20'} onPress={() => history.back()}>Zurück</Button>
                        <Button flex={1} backgroundColor={'success.500'} onPress={() => _submitForm()}>Weiter</Button>
                    </HStack>
                </VStack>  
            

            
            
            <Helmet>
                <title>Login</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
        </VStack>;
}
