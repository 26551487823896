import ActionBarHelper from '~/Helper/ActionBarHelper';
import { useNavigate } from "react-router-dom";

const ActionBar = () => {
    const navigate = useNavigate();
    
    const items = [
      {
        name : 'Tour',
        icon : {
          size: 25,
          icon : 'fa-solid fa-plus'
        },
        onPress : () => {
            navigate('/touren/form/select')
        }
      },
      {
        name : 'Kurztrip',
        icon : {
          size: 25,
          icon : 'fa-regular fa-angles-right'
        },
        onPress : () => {
            navigate('/touren/form/add/quicktrip')
        }
      }
    ];

    return <ActionBarHelper key="TourenActionBar" items={items} />;
  
  };

  export default ActionBar;