import React, { useEffect } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import _ from "lodash";

function Fancybox({...props}) {
    const delegate = props.delegate || "[data-fancybox]";
    
    useEffect(() => {
        let opts = props.options || {};

        opts.buttons = ['close'];


        NativeFancybox.bind(delegate, opts);

        return () => {
            NativeFancybox.destroy();
        };
    }, []);

    return <>{props.children}</>;
}

export default Fancybox;