import { Flex, View, Text, useTheme} from 'native-base';

const FormatedView = (props) => {
    const {colors} = useTheme();
    
    return (<View style={{padding: 10}}>
        <Text style={{
            color: colors.muted['400'],
            textTransform: 'uppercase',
            marginBottom: '5px'
        }}>{props.title}</Text>

        <Flex flexDirection='row'>
        
            <Text _text={{fontSize: 16}}>
                {props.value}
            </Text>

        </Flex>
    </View>);
}

export default FormatedView;