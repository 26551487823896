import _ from 'lodash';
import { toast } from 'react-toastify';

/**
 * Wait
 * 
 * Let user wait sometime
 * 
 * @param Int timeout 
 * @returns Promise
 */
    export const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }

/** 
 * Number Format
*/
    export const number_format = (number) => {
        number = number.toFixed(2) + '';
        var x = number.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? ',' + x[1] : '';
        
        var rgx = /(\d+)(\d{3})/;
        
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }
    
        return x1 + x2;
    }


/**
 * First Letter Uppercase
 * 
*/
    export const ucFirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    
/**
 * Toast Message
 */
    export const toasty = (string, type = 'info', settings = {}) => {
        let options = {
            //position: "bottom-center",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        }
        
        options = _.extend(options, settings);
        
        if (type == 'info') {
            toast.info(string, options);
        }

        if (type == 'success') {
            toast.success(string, options);
        }

        if (type == 'danger') {
            toast.danger(string, options);
        }

        if (type == 'warning') {
            toast.warning(string, options);
        }

        if (type == 'error') {
            toast.error(string, options);
        }

        if (type == 'default') {
            toast(string, options);
        }
        
    }

    export function checkImageURL(URL) {
        const awaitIt = async() => {
            await fetch(URL)
            .then((res) => {
                if (res.status == 404) {
                    return false;
                } else {
                    return true;
                }
            });
        }
    
        return awaitIt();
    }

    export const fileExists = async(url) => {
        return await fetch(url, { method: 'HEAD' })
    }

    export function isValidHttpUrl(s) {
        if (typeof s  !== 'undefined') {
           try {
                const callBack = async () => {
                    /*const supports = await Linking.canOpenURL(s);
                    return supports;*/
                };
           } catch(e) {};
           
            return false;
        }
    }
    