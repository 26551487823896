import * as React from 'react';
import { Text, Button, Heading, HStack, IconButton, VStack, View, useTheme, Box, Divider, Pressable, Flex} from "native-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const FormSelector = () => {
    const { colors } = useTheme();
    const navigate = useNavigate();

  return (
    <>

      <View my={10} justifyContent={'center'} alignItems={'center'}>
        <FontAwesomeIcon size={'4x'} color={colors.primary['600']} icon="fa-regular fa-file" />
        <Heading size={'lg'}>Vorlage auswählen</Heading>
      </View>

      <VStack mt={5} space={5} justifyContent={'flex-start'}>

        <Pressable onPress={() => navigate('/touren/form/add/tour')} _hover={{backgroundColor: 'white'}} p={5}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <HStack space={15} justifyContent={'flex-start'} alignItems={'center'}>
                    <Box w={100} justifyContent={'flex-start'} alignItems={'center'}>
                        <Box backgroundColor={'white'} p={3} rounded={'md'}>
                            <FontAwesomeIcon icon={'fa-solid fa-route'} size={'4x'} color={colors.primary['600']}/>
                        </Box>
                    </Box>
                    
                    <View w={'60%'}>
                        <Heading>Tour</Heading>  
                        <Text mt={2}>
                            <Text fontWeight={'bold'}>Geeignet für:</Text> Im Voraus geplante Touren mit detailierten 
                            Inforamtionen wie z.B. einer Routenbeschreibung, Uhrzeit, Dauer, etc.
                        </Text>
                    </View>
                </HStack>
                <Box p={5}><FontAwesomeIcon icon={'fa-solid fa-chevron-right'} size={'2xl'} color={colors.primary['600']}/></Box>

            </HStack>
        </Pressable>

        <Divider />
        
        <Pressable onPress={() => navigate('/touren/form/add/quicktrip')} _hover={{backgroundColor: 'white'}} p={5}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <HStack space={15} justifyContent={'flex-start'} alignItems={'center'}>
                    <Box w={100} justifyContent={'flex-start'} alignItems={'center'}>
                        <Box backgroundColor={'white'} p={3} rounded={'md'}>
                            <FontAwesomeIcon icon={'fa-solid  fa-forward'}  size={'4x'} color={colors.primary['600']}/>
                        </Box>
                    </Box>
                    
                    <View w={'60%'}>
                        <Heading>Kurztrip</Heading>  
                        <Text mt={2}>
                            <Text fontWeight={'bold'}>Geeignet für: </Text>
                            Spontane und kurzfristige Ausfahrten.
                        </Text>
                    </View>
                </HStack>
                <Box p={5}><FontAwesomeIcon icon={'fa-solid fa-chevron-right'} size={'2xl'} color={colors.primary['600']}/></Box>
            </HStack>
        </Pressable>

        <Divider />

        <Pressable onPress={() => navigate('/touren/form/add/training')} _hover={{backgroundColor: 'white'}} p={5}>
            <HStack justifyContent={'space-between'} alignItems={'center'}> 
                <HStack space={15} justifyContent={'flex-start'} alignItems={'center'}>
                    <Box w={100} justifyContent={'flex-start'} alignItems={'center'}>
                        <Box backgroundColor={'white'} p={3} rounded={'md'}>
                            <FontAwesomeIcon icon={'fa-solid  fa-graduation-cap'}  size={'4x'} color={colors.primary['600']}/>
                        </Box>
                    </Box>
                    
                    <View w={'60%'}>
                        <Heading>Training</Heading>  
                        <Text mt={2}>
                            <Text fontWeight={'bold'}>Geeignet für: </Text>
                            Kurventraining, Sicherheitstrainings, Freie Trainings etc.
                        </Text>
                    </View>
                </HStack>
                <Box p={5}><FontAwesomeIcon icon={'fa-solid fa-chevron-right'} size={'2xl'} color={colors.primary['600']}/></Box>
            </HStack>
        </Pressable>

        <Divider />

        <Pressable onPress={() => navigate('/touren/form/add/event')} _hover={{backgroundColor: 'white'}} p={5}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <HStack space={15} justifyContent={'flex-start'} alignItems={'center'}>
                    <Box w={100} justifyContent={'flex-start'} alignItems={'center'}>
                        <Box backgroundColor={'white'} p={3} rounded={'md'}>
                            <FontAwesomeIcon icon={'fa-brands fa-angellist'}  size={'4x'} color={colors.primary['600']}/>
                        </Box>

                    </Box>
                    
                    <View w={'60%'}>
                        <Heading>Event</Heading>  
                        <Text mt={2}>
                            <Text fontWeight={'bold'}>Geeignet für: </Text>
                            Stammtischtreffen, Go-Kart, etc. 
                        </Text>
                    </View>
                </HStack>
                <Box p={5}><FontAwesomeIcon icon={'fa-solid fa-chevron-right'} size={'2xl'} color={colors.primary['600']}/></Box>
            </HStack>
        </Pressable>
      </VStack>
      
      </>

    
    
  );
};

export default FormSelector;