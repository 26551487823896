import { useEffect, useState, useRef } from 'react';
import { Box, Badge, HStack, Flex, Heading, Stack, View, FlatList, Avatar, VStack, Text, useTheme, Actionsheet, Divider, Button} from 'native-base';
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {toasty} from '~/Helper/Base';
import { useSelector } from 'react-redux';
import { simpleFormData } from '~/Helper/FormHelper';
import Loader from '~/Components/Template/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from 'lodash';

const SupportScreen = ({ ...props }) => {
    const currentUser = useSelector((state) => state.user);

    const { colors } = useTheme();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const richText = useRef();
    const [response, setResponse] = useState(null);

    const fields = {
        thema : [
            'Allgemeine Anfrage', 
            'Fehler',
            'Hassrede',
            'Belästigung',
            'Spam',
            'Werbung',
            'Partnerschaft',
            'Sonstiges' 
        ]
    };

    useEffect(() => {
        setFormData({message :'', thema: 'Allgemeine Anfrage'});
    }, [response])

    useEffect(()=> {
        if (currentUser) {
            setFormData({message :'', thema: 'Allgemeine Anfrage'});
            setResponse(null);
        }
    }, [currentUser]);

    const _submitForm = async () => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/support/send/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash},
                body: simpleFormData({thema: formData.thema, message: formData.message})
            })
            .then((response) => {
                if (response.ok) {
                    setResponse('success');
                    toasty('Anfrage versendet');
                } else {
                    setResponse('error');
                    toasty('Anfrage konnte nicht versendet werden');
                }
                setLoading(false);  
            })
        };
    };

    return (
        
        <View>
            <Loader loading={loading} />

            <View style={StylesConfig.styles.card.wrapperMt}>
                <View style={StylesConfig.styles.card.header}>
                    <Text style={StylesConfig.styles.card.header_text}>Support kontaktieren</Text>
                </View>

                <View style={StylesConfig.styles.card.body}>
                    {response == 'success' &&

                        <VStack justifyContent={'center'} alignItems={'center'}>
                            <Box bg={'success.400'} rounded={'full'} padding={5}>
                                <FontAwesomeIcon icon={'fa-solid fa-check'} size={'4x'} color={'white'} />
                            </Box>
                            <Heading mt={3}>Vielen Dank!</Heading>
                            <Text mt={3} textAlign={'center'}>Deine Anfrage wurde versendet. Wir werden uns deiner Anfrage annehmen und uns bei dir melden.</Text>

                            <Button 
                                onPress={() => setResponse(null)}
                                bg={'muted.600'} mt={5}>Neue Anfrage einreichen</Button>
                        </VStack>
                    }

                    {response == 'error' &&

                        <VStack justifyContent={'center'} alignItems={'center'}>
                            <Box bg={'danger.400'} rounded={'full'} padding={5}>
                                <FontAwesomeIcon icon={'fa-solid fa-times'} size={'4x'} color={'white'} />
                            </Box>
                            <Heading mt={3}>Ups!</Heading>
                            <Text mt={3} textAlign={'center'}>Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.</Text>

                            <Button 
                                onPress={() => setResponse(null)}
                                bg={'muted.600'} mt={5}>Neue Anfrage einreichen</Button>
                        </VStack>
                    }

                    {response === null &&
                        <>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Thema"
                                className="mb-3"
                                >
                                <Form.Select 
                                    value={formData.bike?.type}
                                    onChange={e => setFormData({...formData, type: e.target.value})}
                                    >
                                    {fields.thema.map((item, index) => {
                                        return <option value={item}>{item}</option>
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                            
                            
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Nachricht: "
                                className="mb-3"
                            >
                                <textarea
                                    className='form-control'
                                    value={formData?.message}
                                    onChange={(e) => {
                                        setFormData({...formData, message : e.target.value})
                                    }} 
                                    />
                            </FloatingLabel>
                            
                            <HStack space={5} mt={5}>
                                <Flex flex={1}>
                                    <Button onPress={() => {
                                        _submitForm();
                                    }}>Absenden</Button>
                                </Flex>
                            </HStack>
                        </>
                    }
                </View>
            </View>
            
        </View>

    );
}


export default SupportScreen;