import {HStack, View} from 'native-base';
import { Outlet } from "react-router-dom";
import React, {createRef} from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Subnav from '~/Components/Template/Elements/Subnav';

import Sticky from 'react-sticky-box';

const SidebarNav = ({items, children}) => {
    let contextRef = createRef();

    return (<View w={'100%'} h={'100%'}>
        <MobileView>
            {children}
            <Outlet />
        </MobileView>
        
        <BrowserView>
            <HStack space={5}>
                <View w={290} top={0}>
                    <Sticky offsetTop={20} offsetBottom={20}>
                        <Subnav items={items} />
                    </Sticky>
                </View>
                
                <View flex={1}>
                    {children}
                    <Outlet />
                </View>

                
            </HStack>
        </BrowserView>
        
    </View>);


}

export default SidebarNav;