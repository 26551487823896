import _ from "lodash";
import { BikeName, BikeDetails, BikeImage, convertBikes} from '~/Helper/GarageHelper';
import { useState, useEffect } from 'react';
import { VStack, HStack, useTheme, Badge } from "native-base";

const Bikes = ({bikes}) => {
    const [userBikes, setUserBikes] = useState(null);
    const { colors } = useTheme();

    useEffect(() => {
        setUserBikes(convertBikes(bikes));
    }, []);


    return (
        <VStack space={5}>
          
        { userBikes != null && userBikes.length > 0  && 
            userBikes.map((item, index) =>
                
                <HStack justifyContent={'flex-start'} alignItems={'center'} key={index} space={4} bgColor={'white'}>
                            
                    <BikeImage bike={item} />
                    
                    <VStack>
                        <BikeName bike={item} />
                        <BikeDetails bike={item} />
                    </VStack>
                </HStack>
            )
        }

        </VStack>
    );
};


export default Bikes;