import { Flex, View, Text} from 'native-base';
import StylesConfig from '~/Config/Styles';

const FormatedView = (props) => {
    
    return (<View style={styles.detail_list_container}>
        <Text style={styles.detail_list_item_small}>{props.title}</Text>
        <Flex flexDirection='row'>
        <Text style={styles.detail_list_item_text}>
            {props.value}
        </Text>
        </Flex>
    </View>);
}

export default FormatedView;

const styles = {
    detail_list_container: {
        padding: 10,
    },
    detail_list_item_small: {
        color: StylesConfig.color.middleGray,
        textTransform: 'uppercase',
        marginBottom: 5
    },
    detail_list_item_text: {
        fontSize: 19
    }
};