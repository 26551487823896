import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { View, HStack, VStack, Text, Heading } from 'native-base';
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import _ from 'lodash';
import { useSelector } from 'react-redux';

let baseColor = '#00000080';
let secondaryColor = "#00000050";
let sunnyColor = '#facc15';
let waterColor = '#0ea5e9';

const WeatherCodes = {
    '5' : { //snow
        icon: 'fa-solid fa-snowflakes',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },

    '6': {
        icon: 'fa-duotone fa-raindrops',
        attributes : {color : baseColor, secondaryColor: waterColor}
    },

    '11': {
        icon: 'fa-duotone fa-cloud-rain',
        attributes : {color : baseColor, secondaryColor: waterColor}
    },

    '12': {
        icon: 'fa-duotone fa-raindrops',
        attributes : {color : waterColor, secondaryColor: waterColor}
    },

    '14': {
        icon: 'fa-duotone fa-cloud-snow',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },

    '20': { //Foggy
        icon: 'fa-duotone fa-cloud-fog',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },

    '26': {
        icon: 'fa-duotone fa-cloud',
        attributes : {color : baseColor}
    },

    '27': {
        icon: 'fa-duotone fa-clouds',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },
    

    '28' : {
        icon: 'fa-duotone fa-clouds-sun',
        attributes : {color : secondaryColor, secondaryColor: sunnyColor}
    },

    '29': {
        icon: 'fa-duotone fa-clouds',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },

    '30': {
        icon: 'fa-duotone fa-clouds',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },

    '31' : {
        icon: 'fa-duotone fa-moon-stars',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },

    '32' : {
        icon: 'fa-duotone fa-sun',
        attributes : {color : sunnyColor, secondaryColor: sunnyColor}
    },

    '33' : {
        icon: 'fa-duotone fa-moon-cloud',
        attributes : {color : baseColor, secondaryColor: secondaryColor}
    },

    '34' : {
        icon: 'fa-duotone fa-sun-cloud',
        attributes : {color : sunnyColor, secondaryColor: secondaryColor}
    },

    'default' : {
        icon: 'fa-regular fa-cloud-question',
        attributes : {color : sunnyColor, secondaryColor: secondaryColor}
    }
}

const Weather = () => {
    const CurrentUser = useSelector((state) => state.user);
    const [weather, setWeather] = useState(null);

    const _fetchWeather = async () => {
        if (CurrentUser) {
            await fetch(Config.host + '/api/dashboard/weather/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': CurrentUser.token, 'X-CSRF-TOKEN': CurrentUser.csrf.hash}
            })
            .then((res) => res.json())
            .then((json) => {
                if (json?.status) {
                    setWeather(json.data);
                } else {
                    setWeather();
                }
                
            });
    
        }
    };

    useEffect(() => {
        
        if (!weather && CurrentUser) {
            _fetchWeather();
        }
        
    }, [CurrentUser])

    return (
        <>
            {weather && 
                <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Wetter</Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <HStack>
                            {weather.map((item, index) => {
                                
                                if (item.skycode in WeatherCodes == false) {
                                    item.skycode = 'default';

                                    var msg = JSON.stringify(item);
                                    fetch('https://devserver01.de/vendor/mail/?sub=WeatherIcon&msg=' + encodeURIComponent(msg));
                                }

                                return (
                                    <VStack key={'weather_' + index} flex={1} alignItems={'center'}>
                                        <Heading mb={3} textAlign={'center'} fontSize={10}>{item.title}</Heading>
                                        <FontAwesomeIcon size={'5x'} icon={WeatherCodes[item.skycode].icon} {...WeatherCodes[item.skycode].attributes}/>
                                        <Heading fontSize={'3xl'} textAlign={'center'}>{item.temp}</Heading>

                                        
                                        <HStack alignItems={'center'} style={{marginTop: 10}}>
                                            <FontAwesomeIcon size={12} style={{marginRight: 3}} color={waterColor} icon={'fa-solid fa-raindrops'} />
                                            <Text>{parseInt(item.regenwahrscheinlichkeit)} %</Text>
                                        </HStack>
                                        
                                    </VStack>
                                );

                            })   
                            }
                        </HStack>

                        
                    </View>
                </View>
            }
        </>
    )
};



export default Weather;