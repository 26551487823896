import React,{ useEffect, useState} from 'react';
import { Avatar, Flex, Text, View, Pressable} from 'native-base';

import Moment from 'moment';
import 'moment/min/moment-with-locales'

import StylesConfig from '~/Config/Styles';
import UserHelper from '~/Helper/UserHelper';

import { useLocation, useNavigate } from "react-router-dom";
import uuid from 'react-uuid';

const UserPill = (props)  => {
    const [user, setUser] = useState({});
    const [isOnline, setIsOnline] = useState(null);
    const [username, setUsername] = useState('');
    const [userImage, setUserImage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const _checkOnlineStatus = (user, last_login_date) => {
        let defaultLang = 'de';

        if (typeof user.settings == 'string') {
            let settings = JSON.parse(user.settings);
            if (settings?.language?.length > 0) {
                defaultLang = settings.language.toLowerCase();
            }
        }
        
        const now = new Moment();
        let dateObj = new Moment(last_login_date);  
        now.locale(defaultLang);
        dateObj.locale(defaultLang);

        let diff = now.diff(dateObj, 'minutes', true);
        
        if (diff <= 10) {
            setIsOnline(true);
        } else {
            setIsOnline(false);
        }
    };

    useEffect(() => {
        setUser(props.user);
        
        if (props.user != null) {
            setUsername(UserHelper.buildUsername(props.user));
            setUserImage(UserHelper.getUserimage(props.user));
            _checkOnlineStatus(props.user, props.user.last_login);    
        }

    }, [isOnline]);

    const _styleConfig = () => {
        if (props.minified == true) {
            return {
                avatar : 'xs',
                backgroundColor: null,
                marginRight: 0
            }
        }
        
        if (_isOnlyName()) {
            return {
                avatar : 'sm',
                backgroundColor: null,
                marginRight: 3
            }
        }

        return {
            avatar : 'sm',
            backgroundColor: StylesConfig.color.lightGray,
            marginRight: 3
        }
    };
    

    const _isOnlyName = () => {
        return !(typeof props.onlyName === 'undefined' && !(typeof props.onlyName ==='boolean' && props.onlyName === false));
    };

    const _isOnlyAvatar = () => {
        return !(typeof props.onlyAvatar === 'undefined' && !(typeof props.onlyAvatar ==='boolean' && props.onlyAvatar === false));
    };


    if (user != {} && userImage != '') {
        
        let avatarElemet = <Avatar size={_styleConfig().avatar} bg="cyan.500" source={{uri :userImage}}>{(isOnline) ? <Avatar.Badge bg="green.500"/> : ''}</Avatar>;
        
        if (_isOnlyAvatar()) {
            return (<a data-fancybox={uuid()} data-type="iframe" href={'/members/detail/' + user.id} className='no-deco'>{avatarElemet}</a>);
        }
        
        if (_isOnlyName()) {
            return (
                <a data-fancybox={uuid()} data-type="iframe" href={'/members/detail/' + user.id} className='no-deco'>
                    <Text style={{fontWeight: 'bold'}}>{username}</Text>
                </a>
            );
        }
        
        
        return (
            <View>
                <a data-fancybox={uuid()} data-type="iframe" href={'/members/detail/' + user.id} className='no-deco'>
                    <Flex mr={_styleConfig().marginRight} direction='row' align='center' alignSelf='flex-start' borderRadius="full" bg={_styleConfig().backgroundColor}>
                        {
                            ( _isOnlyName() === false ) 
                                ? avatarElemet 
                                : ''  
                        }
                        <Text style={{width:'auto', marginLeft: 5, marginRight: 15, fontWeight: (_isOnlyName()) ? 'bold' : 'normal'}}>{username}</Text>
                    </Flex>
                </a>
            </View>
        );
    }
    
    

};

export default React.memo(UserPill);