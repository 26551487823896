import React from "react";
import {View, Text, Spinner, Image, VStack, Input, HStack, Box, Button, Center, Heading, useTheme} from 'native-base';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import history from '~/Services/History';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ReactSession } from 'react-client-session';

export default function Welcome(props) {

    const {colors} = useTheme();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);
    const [formData, setFormData] = React.useState({});
    
    const _submitForm = () => {
        if (_.isEmpty(formData?.name)) {
            SweetAlert.fire('Bitte teil uns deinen Vornamen mit.');
            return;
        }

        if (_.isEmpty(formData?.lastname)) {
            SweetAlert.fire('Bitte teil uns deinen Nachnamen mit.');
            return;
        }

        ReactSession.set('register_formdata', {...formData});
        navigate('/auth/register/email');
    };

    React.useEffect(() => {
        setFormData({...ReactSession.get('register_formdata')});
    }, []);



    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
            
                <Heading size="md" color={'white'}>Deine Registrierung bei</Heading>
                <Image
                    source={require('~/Assets/layout/logo_large.png')}
                    h={150}
                    w={500}
                    resizeMode={'contain'}
                    style={{margin: 0, marginTop: -40}}
                />
                
                <Text p={5} fontSize={'lg'} textAlign={'center'} color='white'>
                    Teile mir bitte deinen Namen mit:
                </Text>
                
                <VStack space={3} w={['2/3', '2/3', '1/3']}>
                    <Input 
                        placeholder={'Vorname *'} 
                        floatOnFocus
                        value={formData?.name}
                        size={'lg'}
                        onChangeText={(text) => {setFormData({...formData, name: _.trim(text)})}}  
                        style={{backgroundColor: 'white', textAlign: 'center'}}
                        />
                    
                    

                    <Input 
                        placeholder={'Nachname *'} 
                        floatOnFocus
                        value={formData?.lastname}
                        size={'lg'}
                        onChangeText={(text) => {setFormData({...formData, lastname: _.trim(text)})}}  
                        style={{backgroundColor: 'white', textAlign: 'center'}}
                        />
                    
                    

                    <Input 
                        placeholder={'Möchtest du einen Spitznamen vergeben?'} 
                        floatOnFocus
                        value={formData?.username}
                        size={'lg'}
                        onChangeText={(text) => {setFormData({...formData, username: _.trim(text)})}}  
                        style={{backgroundColor: 'white', textAlign: 'center'}}
                        />

                    <HStack space={3}>
                        <Button flex={1} backgroundColor={'#FFFFFF20'} onPress={() => history.back()}>Zurück</Button>
                        <Button flex={1} backgroundColor={'success.500'} onPress={() => _submitForm()}>Weiter</Button>
                    </HStack>
                </VStack>  
            

            
            
            <Helmet>
                <title>Login</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
        </VStack>;
}
