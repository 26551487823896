import React,{useState, useEffect, useRef, useContext} from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StylesConfig from '~/Config/Styles';
import { Box, Divider, HStack, Skeleton, useTheme, Text, View, Heading, Image, AspectRatio} from 'native-base';
import TourHelper from '~/Helper/TourHelper';
import UserPill from '~/Components/Users/UserPill';
import { useSelector } from 'react-redux';
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from 'lodash';

const TourItem = ({tour_id, distance}) => {
    const currentUser = useSelector((state) => state.user);

    const [tourUsersModal, setTourUsersModal] = useState(false);
    const { colors } = useTheme();
    const [tourData, setTourData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tourStatus, setTourStatus] = useState(null);
    const [labelBar, setLabelBar] = useState(null);

    const backgroundColor = 'white';

    const _load = async () => {
        if (!_.isEmpty(currentUser)) {
            await fetch(Config.host + '/api/touren/get/' + tour_id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({ 'includes': 'tour, image, guides, tourUsers'})
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.tour?.extend && typeof json.tour.extend == 'string') {
                    json.tour.extend = JSON.parse(json.tour.extend);
                    
                    if (json.tour.extend !== false && json.tour.extend != null && _.isEmpty(json.tour.extend) == false)  {
                        let tmpLabelBar = TourHelper.getLabelsBar(json.tour.extend, {
                            top:0, right:0, position: 'relative'
                        });

                        if (tmpLabelBar) {
                            setLabelBar(tmpLabelBar);
                        }
                    }
                }

                setTourStatus(TourHelper.getTourStatus(json.tour.start_date, json.tour.start_time, json.tour.duration));
                setTourData(json);
                setLoading(false);
          });
        };
    };

    useEffect(() => {
        if (currentUser) {
            _load();
        }
    }, [currentUser])

    const _getAttended = () => {
        let yes = tourData.tourUsers.yes.length;
        let maybe = tourData.tourUsers.maybe.length;
        let maxdrivers = tourData.tour.max_drivers;

        let difference = 100000;
        let append = [];

        if (maxdrivers > 0) {
            difference = maxdrivers;
            difference -= yes;
        }

        if (yes > 0) {
            append.push(
                <HStack alignItems={'center'} key={'user_yes'} direction='row' align='center' alignSelf='center' borderRadius="full" bg={StylesConfig.color.success}>
                    <Box p='1' bg='white' borderRadius='full'><FontAwesomeIcon icon={'fa-solid fa-check'} /></Box>
                    <Box mx='2' ml='1' _text={{ color: 'white' }}>{yes}</Box>
                </HStack>);
        }

        if (maybe > 0) {
            append.push(
                <HStack alignItems={'center'} key={'user_maybe'} direction='row' align='center' alignSelf='center' borderRadius="full" bg={StylesConfig.color.warning}>
                    <Box p='1' bg='white' borderRadius='full'><FontAwesomeIcon style={{ color: StylesConfig.warning }} icon={'fa-solid fa-hourglass'} /></Box>
                    <Box mx='2' ml='1' _text={{ color: 'white' }}>{maybe}</Box>
                </HStack>);
        }

        if (difference < 100000 && difference != 0) {
            append.push(
                <HStack alignItems={'center'} direction='row' align='center' alignSelf='center' borderRadius="full" bg={StylesConfig.color.lightGray}>
                    <Box p='1' bg='white' borderRadius='full'><FontAwesomeIcon icon={'fa-solid fa-plus'} /></Box>
                    <Box mx='2' ml='1' _text={{ color: '#2f2f2f' }}>{difference}</Box>
                </HStack>
            );
        }


        if (append.length > 0) {
            return append;
        }
    };
    

    if (tourData == null) {
        
        return (
            <HStack marginBottom={11}>
                <Skeleton w={35} h={180} />

                <View style={{ padding: 15, paddingRight: 40, width: '100%' }}>
                    <Skeleton.Text lines={2} />
                    <Divider my={2} />
                    <Skeleton.Text lines={2} />
                    <Divider my={2} />
                    <Skeleton rounded={'full'} w={81} h={30} />
                </View>
            </HStack>
        );

    } else {

        let detailBarText = TourHelper.getDetailBar(tourData.tour);
        
        if (!isNaN(distance)) {
            detailBarText += ' • ' + parseFloat(distance).toFixed(2).replace('.', ',') + ' km entfernt';
        }

        return (<>
                    
                    <View style={{
                        flex: 1, 
                        flexDirection: 'row', 
                        marginBottom: 10, 
                        marginRight: 10, 
                        backgroundColor: backgroundColor,
                        opacity: (tourStatus?.status == 1) ? .5 : 1
                        }}>

                        {tourData?.tour && TourHelper.getSpeedIndicator(tourData.tour)}
                        
                        <View style={{ padding: 15, paddingRight: 40, width: '100%' }}>

                            {tourData?.tour && TourHelper.getFormattedTourStatus(tourData.tour)}
                            
                            <Link to={'/touren/detail/' + tour_id}>
                                {tourData?.tour && 
                                    <Heading style={{
                                        color: colors.primary['600'],
                                        textAlign: 'left',
                                    }}>{tourData.tour.title}</Heading>
                                }

                                {labelBar && <><Divider my={2} />{labelBar}</>}

                                <Divider my={2} />
                                
                                {tourData?.tour && 
                                    <Box _text={{
                                        fontSize: 'sm'
                                    }}>{detailBarText}</Box>
                                }

                                <Divider my={2} />

                                {tourData?.tour && 
                                    <Box _text={{
                                        fontSize: 'sm'
                                    }}>{tourData?.tour?.start_location}</Box>
                                }
                            </Link>
                            <Divider my={2} />

                            {tourData?.guides &&                         
                                <HStack alignItems='center' space={0} _text={{ fontSize: 'md' }}>
                                    <UserPill user={tourData.guides.guide} />

                                    {tourData?.tour?.sec_guide_id && 
                                        <UserPill user={tourData.guides.sec_guide} />
                                    }
                                    <HStack alignItems='center' space={3} _text={{ fontSize: 'md' }}>
                                        {_getAttended()}
                                    </HStack>
                                </HStack>
                            }


                        </View>
                        
                        
                    </View>

                </>
            

        );

    }

};

export default React.memo(TourItem);