import {HStack, View, Text, ScrollView} from 'native-base';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';
import { setSettings } from '~/Services/SettingsSlice';
import Config from '~/Config/BaseConfig'
import { simpleFormData } from '~/Helper/FormHelper';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { Outlet } from "react-router-dom";
import Sidebar from '~/Components/Template/Elements/Navigation';
import Topbar from '~/Components/Template/Elements/Topbar';
import { Container, Offcanvas } from 'react-bootstrap';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BaseTemplate = ({children}) => {
    const currentUser = useSelector((state) => state.user);
    const settings = useSelector((state) => state.settings);

    const [drawerVisible, setDrawerVisible] = React.useState(false);

    let CookieUser = ReactSession.get('user');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleClose = () => (
        dispatch(setSettings({...settings, drawer_visible: !settings.drawer_visible}))
    );

    React.useEffect(() => {
        setDrawerVisible(settings.drawer_visible);
    }, [settings])

    const _validateAuth = async () => {
        if (!currentUser) {
            if (CookieUser) {
                CookieUser = JSON.parse(CookieUser);
                if (CookieUser?.token) {
                    dispatch(setUser({...CookieUser}));
                    
                    fetch(Config.host + '/api/auth/check/', {
                        method: 'POST',
                        headers: {...Config.defaultHeaders},
                        body: simpleFormData({'token': CookieUser.token})
                    })
                    .then((response) => {
                        if (!response.ok) {
                            navigate('/auth/login');
                        }
                    })
                } else {
                    navigate('/auth/login');
                }
            } else {
                navigate('/auth/login');
            }
            
        } else {
            fetch(Config.host + '/api/auth/check/', {
                method: 'POST',
                headers: {...Config.defaultHeaders},
                body: simpleFormData({'token': currentUser.token})
            })
            .then((response) => {
                if (!response.ok) {
                    navigate('/auth/login');
                }
            })
        }
    }

    React.useEffect(() => {
        _validateAuth();
    });

    const _sideBarWith = 250;

    return (<div style={{width: '100vw', height: '100vh'}} w={'100vw'} h={'100vh'}>
        <HStack>
            <MobileView>
                <Offcanvas show={drawerVisible} onHide={handleClose}>
                    <Offcanvas.Body>
                        <div onClick={() => setDrawerVisible(false)} className='offcanvas-close-button'><FontAwesomeIcon size={'xl'} icon={'fa-solid fa-chevron-left'} /></div>
                        <Sidebar />
                    </Offcanvas.Body>
                    
                </Offcanvas>
            </MobileView>

            <BrowserView>
                {drawerVisible && 
                    <div style={{width: _sideBarWith, height: '100vh', position:'fixed', backgroundColor: 'white'}}>
                        <Sidebar />
                    </div>
                }
            </BrowserView>

            <View flex={1} ml={(drawerVisible && isBrowser) ? _sideBarWith : 0} h={'100vh'}>
                
                <Topbar />
                
                <Container>
                    <Outlet />
                </Container>
            </View>
        </HStack>
    </div>);


}

export default BaseTemplate;