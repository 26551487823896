import * as React from 'react';
import { Button, HStack, View, useTheme, Box, Flex, AspectRatio, Image, ScrollView, Switch, Text, Center, Heading, Pressable, Divider, Badge, VStack, Skeleton } from 'native-base';
import Moment from 'moment';
import 'moment/min/moment-with-locales'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from 'lodash';

import Loader from '~/Components/Template/Loader';

import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import {toasty} from '~/Helper/Base';
import { simpleFormData } from '~/Helper/FormHelper';
import {chipColors} from '~/Config/PostConfig';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { Dropdown } from 'semantic-ui-react'
import uuid from 'react-uuid';

import { CheckPicker } from 'rsuite';
import { Row } from 'react-bootstrap';

const PostFormScreen = ({...props}) => {
    const [loading, setLoading] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [formData, setFormData] = React.useState({tags: []});
    const [pollData, setPollData] = React.useState([]);
    const [imagesData, setImagesData] = React.useState([]);
    const [avaiableTags, setAvaiableTags] = React.useState([]);
    const { colors } = useTheme();
    const currentUser = useSelector((state) => state.user);
    const params = useParams();
    const SweetAlert = withReactContent(Swal);
    const [categoryOptions, setCategoryOptions] = React.useState([]);
    const imagePickerRef = React.useRef();

    const navigate = useNavigate();
    
    const _defaultPollValue = {value: null, users : []};
    
    const _onMount = async (post_id) => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
            
            fetch(Config.host + '/api/forum/get/' + post_id + '/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({'includes' : 'post,poll,images'})
            })
            .then((res) => res.json())
            .then((json) => {    
                            
                if (typeof json.post.tags == 'string') {
                    json.post.tags = JSON.parse(json.post.tags);
                }

                if (!_.isEmpty(json.images)) {
                    _.forEach(json.images, (item, i) => {
                        json.images[i] = {
                            uri : Config.uploadDir + item,
                            isExistingImage : true
                        }
                    })
                }

                setFormData(json.post);
                setPollData(json.poll);
                setImagesData(json.images);
                setLoading(false);
            });
        };
    
    }

    const _save = async () => {
        if (!loading) setLoading(true);
        
        let postData = formData;
            postData.images = [];
            postData.poll_data = pollData;
    
        if (postData?.user) delete postData.user;
        if (postData?.humanized) delete postData.humanized;

        if (typeof postData?.poll_data == 'object') {
            _.forEach(postData.poll_data, (item, key) => {
                if (typeof item == 'object') {
                    if ('percent' in item) delete item.percent;
                    if ('user_count' in item) delete item.user_count;
                }
                postData.poll_data[key] = item;
            })
        }

        if ('post_id' in postData) {
            postData.post_id = parseInt(postData.post_id);
            if (postData.post_id == 0) {
                setLoading(false);
                return false;
            }
        }

        if (!_.isEmpty(currentUser)) {
            const body = new FormData();
            
            if (imagesData.length > 0)  {
                _.forEach(imagesData, (item) => {
                    if (!item?.isExistingImage) {
                        let fileName = item.fileName || 'postimage.jpg';
                        body.append('file_image[]', item);
                    } else {
                        let splittedImage = item.uri.split('/');
                        if (splittedImage.length > 0) {
                            postData.images.push(splittedImage.at(-1));
                        }
                        
                    }
                });
            }
            
            body.append('formData', JSON.stringify(postData));
            
            fetch(Config.host + '/api/forum/save/', {
                method: 'POST',
                headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : body
            }).then((res) => res.json())
            .then((json) => {
                toasty('Beitrag wurde gespeichert');
                setLoading(false);
                navigate('/posts');
            });
        };
                
    };

    React.useEffect(() => {
        if (currentUser && params?.post_id) {
            _onMount(params.post_id);
            setIsEdit(true);  
        }
        
        if (currentUser && !params?.post_id) {
            setLoading(false);
        } 
    }, [currentUser]);


    React.useEffect(() => {        
        Moment.locale('de');
        
        let tags = avaiableTags;

        Object.keys(chipColors).map((key) => {
            tags.push(key);
        });
        
        let options = []
        Object.keys(chipColors).map((item, index) => {
            options.push({key: uuid(), label: item, value: item});
        });
        setCategoryOptions(options)

        setAvaiableTags(tags);
    }, []);


    const removeImage = (idx) => {
        SweetAlert.fire({
            'text' : `Bild löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                let _images = imagesData;
                _images.splice(idx, 1);
                setImagesData([..._images]);
            }
        });
    }

    const removePollOption = (index) => {
        SweetAlert.fire({
            'text' : `Option löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                let _data = pollData;
                _data.splice(index, 1);
                setPollData([..._data]);
            }
        });
    }

    const _handleImagePick = async(event) => {
        if (event?.target?.files.length > 0) {

            let imageObj = imagesData || [];
            
            for (var i = 0; i < event.target.files.length; i++) {
                imageObj.push(event.target.files[i]);
            }
            
            setImagesData([...imageObj]);
        }
        
    }

    const initPoll = () => {
        setPollData([_defaultPollValue]);
    };

    
    
    return (
        <>
            
            <div>
                
                <Loader loading={loading} />
            
            
                <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>
                            Beitrag {(isEdit ? 'bearbeiten' : 'erstellen')}
                        </Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <FloatingLabel
                                controlId="floatingInput"
                                label="Überschrift (optional)"
                                className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.title} 
                                onChange={(e) => setFormData({...formData, title: e.target.value})} 
                                />
                        </FloatingLabel>
                        
                        
                        <CheckPicker 
                            className={'mb-3'} 
                            label={'Kategorie'} 
                            placeholder={'Bitte auswählen...'} 
                            searchable={false}
                            data={categoryOptions} 
                            onSelect={(items) => {
                                setFormData({...formData, tags: items})
                            }}
                            />

                        {/* */}

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Beitrag"
                            className="mb-3"
                        >
                            <textarea
                                className='form-control'
                                style={{height: '150px'}}
                                value={formData?.post}
                                onChange={(e) => {
                                    setFormData({...formData, post : e.target.value});
                                }} 
                                />
                        </FloatingLabel>
                        
                        {imagesData.length > 0 &&
                            <VStack mt={50}>
                                <Heading>Bilder</Heading>
                                <Box my={2} borderBottomWidth={1} borderBottomColor={colors.muted['100']}/>
                                <HStack style={{gap: 15}} mt={25} flex={2} flexWrap={'wrap'}>
                                    {imagesData.map((item, index) => {
                                        return (
                                            <AspectRatio key={index} ratio={{base: 1/1}} w={'150px'} h={'150px'} style={{overflow: 'hidden'}}>
                                                <Pressable onPress={() => removeImage(index)}>
                                                    <img src={ item?.isExistingImage ? item.uri : URL.createObjectURL(item) } className='img-responsive' />
                                                    <Badge rounded={'full'} right={'50%'} mr={-21} top={'50%'} mt={-21} justifyContent={'center'} alignItems={'center'} h={42} w={42} bg={'danger.600'} position={'absolute'}>
                                                        <FontAwesomeIcon color={'white'} icon={'fa-solid fa-times'} /> 
                                                    </Badge>
                                                </Pressable>
                                            </AspectRatio>
                                        );
                                    })}
                                </HStack>
                            </VStack>
                        }
                        <input type={'file'} multiple ref={imagePickerRef} onChange={_handleImagePick} accept={"image/png, image/jpeg"} style={{display:'none'}}/>

                        {pollData.length > 0 &&
                            <VStack mt={50}>
                                <Heading>Umfrage</Heading>
                                <Box my={2} borderBottomWidth={1} borderBottomColor={colors.muted['100']}/>
                                {pollData.map((item, index) => {

                                    return (
                                        <HStack key={index} space={3} alignItems={'center'}>
                                            <Badge rounded={'full'} h={30} w={30} bg={'muted.200'}>
                                                <Heading fontSize={'xs'}>{index+1}</Heading>
                                            </Badge>
                                            <View flex={1}>
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Option"
                                                    className="mb-3"
                                                    >
                                                    <input
                                                        className='form-control'
                                                        type={'text'} 
                                                        value={pollData[index].value} 
                                                        onChange={(e) =>{
                                                            let _pollItem = pollData;
                                                            _pollItem[index].value = e.target.value;
                                                            setPollData([..._pollItem]);
                                                        }} 
                                                        />
                                                </FloatingLabel>
                                                
                                            </View>
                                            <Pressable onPress={() => removePollOption(index)}>
                                                <Badge rounded={'full'} h={33} w={33} bg={'danger.600'}>
                                                    <FontAwesomeIcon color={'white'} icon={'fa-solid fa-times'}/>
                                                </Badge>
                                            </Pressable>
                                        </HStack>
                                    );
                                })}
                                
                                <Pressable onPress={() => setPollData([...pollData, _defaultPollValue])} style={{marginTop: 25}}>
                                    <Badge rounded={'full'} p={3} _text={{fontSize: 'sm'}} flex={1} bg={'coolGray.200'}>
                                        + Auswahloption hinzufügen
                                    </Badge>
                                </Pressable>
                                
                            </VStack>
                        }
                            
                        <VStack mt={50} borderWidth={1} borderRadius={'xl'} p={3} space={3} borderColor={colors.muted['200']}>
                            <Text textAlign={'center'} flex={1} flexWrap={'wrap'}>
                                Füge noch etwas zu deinem Beitrag hinzu:
                            </Text>
                        
                            <HStack space={3} justifyContent={'center'}>
                                <Pressable onPress={() => imagePickerRef?.current.click()}>
                                    <Badge p={2.5} bg={(imagesData.length > 0) ? 'primary.600' : 'coolGray.500' } rounded={'full'}>
                                        <FontAwesomeIcon color={'white'}  icon="fa-solid fa-images" />
                                    </Badge>
                                </Pressable>
                            
                                <Pressable onPress={() => initPoll()}>
                                    <Badge p={2.5} bg={(pollData.length > 0) ? 'primary.600' : 'coolGray.500' } rounded={'full'}>
                                        <FontAwesomeIcon color='white' icon="fa-solid fa-list-timeline" />
                                    </Badge>
                                </Pressable>
                                
                                
                            </HStack>

                        </VStack>


                    
                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack space={5} justifyContent={'center'}>
                            <Button bgColor={colors.muted['300']} onPress={() => navigate('/posts')}>Abbrechen</Button>
                            <Button onPress={() => _save()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
                
            
                
        </div>
        
    </>
    );
    

};

export default PostFormScreen;
