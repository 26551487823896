import { Box, HStack, Text, VStack, Heading, useTheme } from 'native-base';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserLabels = (props) => {
    const { colors } = useTheme();

    const getLabel = function(type, value){
        let icon = null;
        let style = {
            box : {
                width: 40,
                height: 40,
                justifyContent : 'center',
                alignItems:'center',
                backgroundColor: 'red'
            },
            icon : {
                size: 'lg',
                color:'white',
                icon : ''
            },
            text : '',
            globalBox : {
                backgroundColor: colors.light['100'],
                margin: 6,
                flexGrow : 1
            }
        };
        
        value = parseInt(value);

        if (type == 'asGuideDone') {
            style.box.backgroundColor = colors.info['300'];
            style.icon.icon = 'fa-solid fa-route';
            if (value == 1) {
                style.heading = `${value} Tour`;
            } else {
                style.heading = `${value} Touren`;
            }
            
            style.text = 'geleitet';

        } else if (type == 'asEventer') {
            
            style.box.backgroundColor = colors.amber['500'];
            style.icon.icon = 'fa-brands fa-angellist';
            style.heading = `${value} Event`;
            style.text = 'veranstaltet';

        } else if (type == 'asTrainer') {
            
            style.box.backgroundColor = '#4580a7';
            style.icon.icon = 'fa-solid fa-graduation-cap';
            if (value == 1) {
                style.heading = `${value} Training`;
            } else {
                style.heading = `${value} Trainings`;
            }
            
            style.text = 'geleitet';

        } else if (type == 'Mod') {
            
            style.box.backgroundColor = colors.blue['300'];
            style.icon.icon = 'fa-solid fa-user-shield';
            style.heading = 'Moderator/in';

        } else if (type == 'Admin') {
            
            style.box.backgroundColor = colors.primary['600'];
            style.icon.icon = 'fa-solid fa-user-crown';
            style.heading = 'Administrator';

        } else if (type == 'isGuide') {
            
            style.box.backgroundColor = colors.info['300'];
            style.icon.icon = 'fa-solid fa-route';
            style.heading = 'Tour-Guide';

        } else if (type == 'isTrainer') {
            
            style.box.backgroundColor = '#5aa8db';
            style.icon.icon = 'fa-solid fa-graduation-cap';
            style.heading = 'Trainer/in';

        } else if (type == 'km') {
            
            style.box.backgroundColor = colors.primary['600'];
            style.icon.icon = 'fa-solid fa-road';
            style.heading = `${parseInt(value)} km`;
            style.text = 'gefahren';

        } else if (type == 'drivenTours') {
            
            style.icon.icon = 'fa-solid fa-motorcycle';
            
            if (value < 5) {
                style.box.backgroundColor = colors.warning['300'];
                style.heading = 'Gelegenheits-Fahrer/in';
            } else if (value < 10) {
                style.box.backgroundColor = colors.warning['400'];
                style.heading = 'Viel-Fahrer/in';
            } else if (value <= 20) {
                style.box.backgroundColor = colors.warning['500'];
                style.heading = 'Viel-Viel-Fahrer/in';
            } else if (value > 20) {
                style.box.backgroundColor = colors.warning['600'];
                style.heading = 'Immer-dabei-Fahrer/in';
            }


            style.text = `${value} Touren`;
        }



        if (style.icon.icon != '') {
            return (
                <HStack style={{...style.globalBox}}>
                    <Box style={{...style.box}}>
                        <FontAwesomeIcon size={style.icon.size} color={style.icon.color} icon={style.icon.icon}/>
                    </Box>
                    <VStack justifyContent={'center'} style={{paddingHorizontal: 10}}>
                        <Heading size={'sm'}>{style.heading}</Heading>
                        {style.text != '' && <Text fontSize={'sm'}>{style.text}</Text>}
                    </VStack>
                </HStack>);
        }
    };

    
    return (
        <HStack flexDirection={'row'} flexWrap='wrap' justifyContent={'space-between'} flex={2}>
            {props.statistics.asGuideDone > 0 && getLabel('asGuideDone', props.statistics.asGuideDone)}    
            {props.statistics.asGuideDone > 0 && getLabel('isGuide')}    
            {props.statistics.asTrainer > 0 && getLabel('isTrainer')}    
            {props.statistics.asEventer > 0 && getLabel('asEventer', props.statistics.asEventer)}
            {props.statistics.asTrainer > 0 && getLabel('asTrainer', props.statistics.asTrainer)}    
            {props.statistics.drivenTours > 0 && getLabel('drivenTours', props.statistics.drivenTours)}    
            {props.statistics.km > 0 && getLabel('km', props.statistics.km)}    
            {props.usertype == 1 && getLabel('Mod')}    
            {props.usertype == 2 && getLabel('Admin')}    
        </HStack>
    );


};

export default UserLabels;