
export default {
    color : {
        base : '#248dbd',

        success: '#27ae60',
        successLight: '#52be7f',
        successDark: '#239c56',
        
        warning: '#f1c40f',
        warningLight: '#f6db6f',
        warningDark: '#d8b00d',

        danger: '#e74c3c',
        dangerLight: '#e95d4f',
        dangerDark: '#b83c30',
        
        ultraLightGray : '#f7f7f7',
        lightGray: '#e9ecef',
        middleGray: '#9b9b9b',
        gray: '#6c757d'
    },

    styles : {
        card : {
            wrapper : {
                
            },
            wrapperMt : {
                marginTop: 30
            },
            header : {
                padding: 25,
                backgroundColor: '#f7f7f7' 
            },
            
            header_text : {
                textTransform : 'uppercase',
                color: '#248dbd',
                fontSize: 20
            },

            body : {
                backgroundColor: 'white',
                padding: 20
            },
        },

        rnui : {
            inputStyles : {borderBottomWidth:1, borderBottomColor: '#e4e9f2', paddingBottom: 10, paddingTop: 10, paddingLeft: 15}
        }
    },

};