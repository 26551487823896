// Import React and Component
import React from 'react';
import { HStack, VStack, Divider, Modal, TextArea, View, Text, Box, Skeleton, Pressable, Input, Avatar, Flex} from 'native-base';
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import Loader from '~/Components/Template/Loader';
import StylesConfig from '~/Config/Styles';
import UserPill from '~/Components/Users/UserPill';
import ReactionBar from '~/Components/Reactions/ReactionBar';
import DateHelper from '~/Helper/DateHelper';
import {toasty, wait} from '~/Helper/Base';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import UserHelper from '~/Helper/UserHelper';
import uuid from 'react-uuid';

const _isUserOwner = (currentUser, commentUserId) => {
    return (!_.isEmpty(currentUser) && currentUser?.data?.id === commentUserId);
};


const _stripTags = function(text){
    if (typeof text == 'string') {
        return text.replace(/(<([^>]+)>)/gi, "");
    }
};


const CommentsList = ({ master_id, type}) => {

    const currentUser = useSelector((state) => state.user);

    const [comments, setComments] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [triggerLoad, setTriggerLoad] = React.useState(false);

    const _fetchComments = async () => {
        if (!_.isEmpty(currentUser)) {
            await fetch(Config.host + '/api/comments/collection/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({'master_id' : master_id, 'type': type})
            })
            .then((res) => res.json())
            .then((json) => {
                setComments(json);
                setLoading(false);
            }).catch((error) => {
                setComments([]);
                setLoading(false);
            });
        }
    };

    React.useEffect(() => {
        if (triggerLoad === true) {
            
            _fetchComments();

            if (triggerLoad) {
                setTriggerLoad(false);
            }
        }

    }, [triggerLoad])

    React.useEffect(() => {
        if (currentUser) {
            setTriggerLoad(true);
        }
    }, [currentUser]);
    
    const functions = {
        setTriggerLoad: setTriggerLoad,
        setLoading: setLoading,
    }

    return (
        <>
            {loading

                ?   <View style={{ flex: 1 }}>
                        <HStack>
                            <Skeleton size="5" rounded="full" />
                            <Skeleton.Text px="4" />
                        </HStack>
                    </View>

                :   <View>
                        <RecrusiveRun func={{...functions}} key={uuid()} comments={comments}  type={type} master_id={master_id} />
                        <CommentBar func={{...functions}} type={type} master_id={master_id}/>
                    </View> 
            
            }
        </>

    );

}

const CommentBar = ({type, master_id, parentComment = null, func}) => {
    
    const currentUser = useSelector((state) => state.user);
    const [userImage, setUserimage] = React.useState('');
    const [commentBoxFormData, setCommentBoxFormData] = React.useState({
        type: type,
        master_id : master_id
    });
    
    const commentInput = React.useRef();
    
    const _onSubmit = async() => {
        func.setLoading(true);

        if (!_.isEmpty(currentUser) && commentBoxFormData?.comment) {
            await fetch(Config.host + '/api/comments/add/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'token': currentUser.token,
                    'X-CSRF-TOKEN': currentUser.csrf.hash
                },
                body: simpleFormData(commentBoxFormData)
            })
            .then(() => {
                func.setTriggerLoad(true);
            })
        }
    };

    React.useEffect(() => {
        if (currentUser) {
            setCommentBoxFormData({ ...commentBoxFormData,
                user_id: currentUser.data.user_id,
                lang : currentUser.data.settings.language
            });
            
            setUserimage(UserHelper.getUserImagePath(currentUser.data.image));
        }

        return () => {
            _disableAnswer();
        }
    }, []);
    
    const _disableAnswer = function(){
        if (commentInput?.current) {
            commentInput?.current.clear();
            commentInput?.current.blur();
        }
    };

    const _handleBlur = () => {
        if (commentInput?.current && commentInput?.current?.value == '') {
            commentInput?.current.clear();
        }
    };
    
    return (
        <Input 
            w={'100%'} 
            fontSize={16}
            borderWidth={1}
            ref={commentInput}
            h={50}
            placeholder='Kommentieren ...'
            InputLeftElement={(userImage != '' && <Avatar ml={2} borderWidth='0' size='sm' bg="cyan.500" source={{uri: userImage}} />)}
            variant="rounded"  
            InputRightElement={
                <Pressable onPress={() => _onSubmit()}>
                    <Box borderRadius="full" p={2} mr={2} bg={StylesConfig.color.base}>
                        <FontAwesomeIcon size={'lg'} color='white' icon='fa-solid fa-paper-plane-top' />
                    </Box>
                </Pressable>
            }
            bg={'white'}
            _focus={{bg:'white'}}
            onBlur={_handleBlur}
            value={commentBoxFormData.value}
            onChangeText={value => setCommentBoxFormData({ ...commentBoxFormData,
                    comment: value,
                    parent_comment_id: parentComment?.comment_id,
                })
            } 
        />
    );
  
};

const RecrusiveRun = ({ comments, childsLevel = false, func, type, master_id}) => {
    let output = [];
    
    if (comments.length > 0) {
        comments.map((item, index) => {
            let childs = null;

            if (item?.children?.length > 0) {
                childs = <RecrusiveRun comments={item.children} func={func}  type={type} master_id={master_id} childsLevel/>;
            }

            output.push(<CommentItem key={uuid()} func={func} type={type} master_id={master_id} item={item} childs={childs} childsLevel={childsLevel} />);
        });

    }

    return output;
};

const EditBox = ({item, edit, func, master_id, type}) => {
    const [isEdit, setIsEdit] = React.useState(false);
    const editInputRef = React.useRef(null);
    const currentUser = useSelector((state) => state.user);
    const [editFormData, setEditFormData] = React.useState(item);
    
    const _handleSubmitEdit = async () => {
        const submitData = {
            comment_id: editFormData.comment_id,
            comment: editFormData.comment
        };
        
        if (!_.isEmpty(currentUser)) {
            await fetch(Config.host + '/api/comments/add/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData(submitData)
            })
            .then((res) => {
                func.setLoading(true);
                func.setTriggerLoad(true);
            })
            .catch((error) => console.log(error));
        }
    }

    const _handleEditTextChange = async (e) => {
        setEditFormData({ ...editFormData, comment: e.target.value})
    }

    React.useEffect(() => {
        setIsEdit(edit);
        if (edit) {
            wait(400).then(() => {  
                editInputRef?.current?.focus();
            });
        }
    }, [edit])

    return (
        <>
        {isEdit  
            ?   <Input 
                    h={50}
                    
                    ml={2}
                    mr={2}
                    fontSize={18}
                    borderWidth={0}
                    ref={(INPUT) => editInputRef.current = INPUT}
                    variant="rounded"  
                    InputRightElement={
                        <Pressable onPress={() => _handleSubmitEdit()}>
                            <Box borderRadius="full" p={2} mr={2} bg={StylesConfig.color.base}>
                                <FontAwesomeIcon size={'lg'} color='white' icon='fa-solid fa-paper-plane-top' />
                            </Box>
                        </Pressable>
                    }
                    onBlur={() => {
                        if (editFormData.comment == item.comment) {
                            setEditFormData(item);
                            func.setEditParent(false);
                            setIsEdit(false);
                        }
                    }}
                    value={editFormData.comment}
                    onChange={_handleEditTextChange}
                />   
            
            
        :   <Pressable onLongPress={() => _isUserOwner(currentUser, item.user_id) && setIsEdit(true)}>
                <Box ml={2}>{_stripTags(item.comment)}</Box>
            </Pressable>
    }</>)
};

const CommentItem = ({item, childs, childsLevel, func, master_id, type}) => {
    const currentUser = useSelector((state) => state.user);

    const currentItem = item.item;
    const humanized = DateHelper.humanize(currentItem.created_at);
    let hasChilds = childsLevel;
    const SweetAlert = withReactContent(Swal);

    const [answer, setAnswer] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [answerData, setAnswerData] = React.useState({});
    
    const marginLeft = (childsLevel == true) ? {ml: 3} : {};

    const _deleteComment = async (comment_id) => {
        SweetAlert.fire({
            'text' : `Möchtest du den Kommentar wirklich löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                func.setLoading(true);
                if (!_.isEmpty(currentUser)) {
                    fetch(Config.host + '/api/comments/delete/' + comment_id + '/', {
                        method: 'POST',
                        headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                    })
                    .then((res) => res.json())
                    .then((json) => {
                        toasty('Eintrag gelöscht', 'success');
                        func.setTriggerLoad(true);
                    })
                    .catch((error) => console.log(error));
                }
            }
        });
    };

    const backgroundColor = ((!_.isEmpty(currentUser) && currentUser?.data?.id == currentItem.user_id) 
        ? 'blueGray.200' 
        : 'coolGray.100');


    return (
        <VStack {...marginLeft} key={uuid()}>
            
            <HStack alignItems='center' zIndex={2}>
                <Box style={{position:'absolute', zIndex: 2}}>
                    <UserPill onlyAvatar user={currentItem.user} />
                </Box>
                {childsLevel && 
                    <Box position='absolute' style={{ left: -13, top: -17, width: 17, height: 18, zIndex:1 }} borderBottomLeftRadius={25} borderLeftWidth={2} borderBottomWidth={2} borderColor='muted.200'></Box>
                }
            </HStack>

            <HStack justifyContent={'flex-start'}>
            
                <Divider key={'test'} zIndex={1} ml={3} bgColor={(hasChilds) ? "muted.200" : "muted.50"} orientation="vertical" />
                    
                <Box flex='1'>

                    {/* KOMMENTARBOX */}
                    
                        <Box borderRadius='md' bg={backgroundColor} style={{marginLeft: 25, marginTop:-16, padding: 5, paddingRight: 0}}>
                            <Box flexDirection={'row'} flexWrap={'wrap'} alignItems='baseline'>
                                <UserPill onlyName user={currentItem.user} />
                                <Text style={{ color: 'muted.400', fontSize: 11, marginLeft: 5}}>{humanized}</Text>
                            </Box>
                            <Box style={{marginLeft: -4}}>
                                <EditBox edit={edit} func={{...func, setEditParent: setEdit}} item={currentItem} master_id={master_id} type={type} />
                            </Box>
                        </Box>
                    

                    {/* AKTIONSLEISTE */}
                    <HStack justifyContent={'space-between'} style={{marginLeft: 30, paddingBottom:30}}>
                    
                        {answer && !edit && 
                            <VStack w={'100%'}>
                                <HStack space={3} alignItems={'center'} p={1} style={{paddingLeft: 30}} width='100%' alignSelf='center' >
                                    <Box>Antwort an</Box>
                                    <UserPill onlyName user={currentItem.user} />
                                </HStack>
                                
                                <CommentBar func={func} flex={1} type={type} master_id={master_id} parentComment={currentItem}/>

                                <HStack space={3} alignItems={'center'} p={1} style={{paddingLeft: 30}} width='100%' alignSelf='center' >
                                    <Pressable onPress={() => {
                                        setAnswer(false);
                                    }}><Box>Abbrechen</Box></Pressable>
                                </HStack>
                            </VStack>
                        }

                        {!answer && !edit && <>
                                <View pb={2}><ReactionBar id={currentItem?.comment_id} type={type + '_comment'} ownerUserId={currentItem?.user_id} /></View>
                                
                                <HStack space={4} alignItems='center' justifyContent='flex-end'>
                                    <Pressable onPress={() => setAnswer(true)}><Box _text={{ color: 'muted.500', fontSize: 11, padding: 1 }}>
                                        <FontAwesomeIcon icon={'fa-solid fa-reply'} />    
                                    </Box></Pressable>
                                    
                                    {_isUserOwner(currentUser, currentItem?.user_id)  && 
                                        <Box>
                                            <Pressable style={{alignSelf: 'flex-start'}} onPress={() => setEdit(true)}>
                                                <Box _text={{ color: 'muted.500', padding: 1}}>
                                                    <FontAwesomeIcon icon={'fa-solid fa-edit'} />
                                                </Box>
                                            </Pressable>
                                        </Box>
                                    }

                                    {_isUserOwner(currentUser, currentItem?.user_id)  && 
                                        <Box>
                                            <Pressable style={{alignSelf: 'flex-start'}} onPress={() => {
                                                _deleteComment(currentItem.comment_id);
                                            }}><Box _text={{ color: 'muted.500', padding: 1 }}>
                                            <FontAwesomeIcon icon={'fa-solid fa-times'} />    
                                            </Box></Pressable>
                                        </Box>
                                    }
                                </HStack>
                            </>
                        }

                        {edit && !answer &&
                            <Pressable onPress={() => {
                                setEdit(false);
                            }}><Box>Abbrechen</Box></Pressable>
                        }
                    
                    </HStack>
                    
                    <Box>
                        {childs}
                    </Box>

                </Box>
            </HStack>
        </VStack>
    );
}

export default CommentsList;