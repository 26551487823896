export const chipColors = {
    'Allgemein' : {text: '#fff', bg : '#abafb2'},
    'Smalltalk' : {text: '#fff', bg : '#ffcc4d'},
    'Touren' : {text: '#fff', bg : '#3498db'},
    'Frage' : {text: '#fff', bg : '#b2d8ed'},
    'Umfrage' : {text: '#fff', bg : '#ff8500'},
    'Technik' : {text: '#fff', bg : '#01b4b9'},
    'Vorstellung' : {text: '#fff', bg : '#ef4689'},
    'Ankündigung' : {text: '#fff', bg : '#e60023'},
    'Gallerie' : {text: '#fff', bg : '#a2d471'},
    'An- & Verkauf' : {text: '#fff', bg : '#ec87bf'},
    
    'Aktion' : {text: '#fff', bg : '#9f1239'},
    'Wissenswertes' : {text: '#000', bg : '#fbbf24'},
}

const PostSettings = () => {

};

export default PostSettings;