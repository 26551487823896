import React from 'react';
import { VStack, View, Image, HStack, Pressable, Text, useTheme} from "native-base";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from "react-router-dom";
import Config from '~/Config/BaseConfig'
import { useSelector, useDispatch } from 'react-redux';
import { setSettings } from '~/Services/SettingsSlice';
import Commercial from '~/Components/Commercial';
import { isMobile } from 'react-device-detect';

const Navigation = () => {
    const {colors} = useTheme();
    const navigate = useNavigate();
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();
    const [active, setActive] = React.useState(window.location.pathname);
    
    const expandableMenus = [
        {
            header : {
                icon : 'fa-circle-question',
                name: 'Hilfe & Utilities'
            },
            items: [
                {
                    name: <><Text>Versicherungsvergleich  </Text><FontAwesomeIcon size={'xs'} icon="fa-solid fa-arrow-up-right-from-square" /></>,
                    external: 'https://a.partner-versicherung.de/click.php?partner_id=134570&ad_id=15&deep=motorradversicherung'
                },
                {
                    name: <><Text>Merchandise  </Text><FontAwesomeIcon size={'xs'} icon="fa-solid fa-arrow-up-right-from-square" /></>,
                    external: 'https://meet4ride.myspreadshop.de/'
                },
                {
                    name: 'Support kontaktieren',
                    internal: '/help/support'
                }
            ]
        },
        {
            header : {
                icon : 'fa-solid fa-section',
                name: 'Rechtliches'
            },
            items: [
                {
                    name: <><Text>Haftungsausschuss  </Text><FontAwesomeIcon size={'xs'} icon="fa-solid fa-arrow-up-right-from-square" /></>,
                    external: Config.host + '/pub/law/disclaimer/'
                },
                {
                    name: <><Text>Datenschutz  </Text><FontAwesomeIcon size={'xs'} icon="fa-solid fa-arrow-up-right-from-square" /></>,
                    external: Config.host + '/pub/law/privacy/'
                },
                {                
                    name: <><Text>Impressum  </Text><FontAwesomeIcon size={'xs'} icon="fa-solid fa-arrow-up-right-from-square" /></>,
                    external: Config.host + '/pub/law/imprint/'
                }
            ]
        }
    
    ];
    
    const CustomDrawerItem = ({...props}) => {
        let backgroundColor = '#ffffff';
        let color = '#00000090';
        let fontWeight = 'normal';

        if (props?.type == 'highlight') {
            backgroundColor = '#90d4f3';
            color = '#083a51';
        }

        if (active == props.onPress) {
            backgroundColor = colors.muted['100'];
            color = colors.primary['700'];
            fontWeight = 'bold';
        }
    
    
        return (
            <div className={'pointer'} onClick={() => {
                if (isMobile) {
                    dispatch(setSettings({...settings, drawer_visible: false}));
                }
                
                setActive(props.onPress);

                if (props.onPress.indexOf('http') != -1) {
                    window.open(props.onPress);
                } else {
                    navigate(props.onPress)
                }
                
            }}>
                <HStack 
                    style={{
                        paddingVertical: 15, 
                        justifyContent: 'flex-start', 
                        alignItems: 'center', 
                        backgroundColor: backgroundColor
                    }}>
                    <View style={{paddingRight: 15, paddingLeft: 15, width: 65, flexDirection: 'row', justifyContent: 'center'}}><FontAwesomeIcon color={color} icon={props?.icon} size={'lg'}/></View>
                    <Text style={{alignItems: 'center', flex:8, color: color, fontWeight: fontWeight}}>{props?.label}</Text>
                </HStack>
            </div>
        );
    
    }
    
    const DrawerExpandable = ({item, navigate}) => {
        const [expandable, setExpandableVisibility] = React.useState(false);
        
        let color = '#00000090';

        return (
            <View>
                <Pressable onPress={() => setExpandableVisibility(!expandable)}>
                    <HStack style={{paddingVertical: 15, justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'white'}}>
                        <View style={{paddingRight: 15, paddingLeft: 15, width: 65, flexDirection: 'row', justifyContent: 'center'}}><FontAwesomeIcon color={color} icon={item.header.icon} size={'lg'}/></View>
                        <Text style={{alignItems: 'center', flex:8, color: '#00000090'}}>{item.header.name}</Text>
                        <Text style={{alignItems: 'center', flex:1}}><FontAwesomeIcon icon={ (expandable) ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"} size={'lg'}/></Text>
                    </HStack>        
                </Pressable>
                
                <div style={{display: ((expandable) ? 'block' : 'none')}}>
                    <VStack style={{backgroundColor: '#00000010', paddingVertical: 10}}>
                        {item.items.map((_item, index) => {
                            
                            return (
                                <Pressable key={index} onPress={() => ((_item.internal) ? navigate(_item.internal) : window.open(_item.external))}>
                                    <HStack key={item.header + '_' + index} style={{paddingVertical: 15, justifyContent: 'flex-start', alignItems: 'center'}}>
                                        <View style={{paddingRight: 15, width: 65, flexDirection: 'row', justifyContent: 'center'}}>
                                            <FontAwesomeIcon icon={'fa-solid fa-chevron-right'} size={'lg'} color={'#00000070'}/>
                                        </View>
                                        <Text style={{alignItems: 'center', flex:8}}>{_item.name}</Text>
                                    </HStack>
                                </Pressable>
                            )
    
                        })}
                    </VStack>
                </div>
            </View>
        );
    }

    return (
        <div style={{flexDirection: 'column'}}>
            <div className='logo-backdrop' style={{ display:'flex', flexDirection: 'column', zIndex: 50, backgroundColor: '#248dbd', padding:0, alignItems: 'center', width: '100%', paddingTop: 50, paddingBottom: 50}}>
                <View style={{paddingBottom: 5}}>
                    <Image 
                        style={{width:50, height: 58}} 
                        source={require('~/Assets/layout/logo_signe.png')}/>
                </View>

                <View style={{marginBottom: 25, position:'relative'}}>
                    <Image 
                        style={{width:150, height: 28}} 
                        source={require('~/Assets/layout/logo_large.png')}/>
                </View>
            </div>

            <div>

                <CustomDrawerItem
                    label={'Dashboard'}
                    icon={'fa-solid fa-home'}
                    onPress={'/dashboard'}
                />

                <CustomDrawerItem
                    label={'Touren'}
                    icon={'fa-solid fa-route'}
                    onPress={'/touren/all'}
                />

                <CustomDrawerItem
                    label={'Mitglieder'}
                    icon={'fa-solid fa-user-friends'}
                    onPress={'/members'}
                />

                <CustomDrawerItem
                    label={'Beiträge'}
                    icon={'fa-solid fa-list'}
                    onPress={'/posts'}
                />

                <CustomDrawerItem
                    label={'Spendenkonto'}
                    icon={'fa-solid fa-hand-holding-dollar'}
                    onPress={'https://www.paypal.com/paypalme/mee4ride?locale.x=de_DE'}
                />
                
                {/*<CustomDrawerItem
                    label={'Werbefreier Zugang'}
                    type={'highlight'}
                    icon={'fa-solid fa-star'}
                    onPress={'/purchase/adfree'}
                />*/}

                <DrawerExpandable navigate={navigate} item={expandableMenus[0]} />

                <Commercial pos={1} _width={250} _height={250} style={{marginTop: 5}} />

                <DrawerExpandable navigate={navigate} item={expandableMenus[1]} />

            </div>

        </div>
    );
};

export default Navigation;