import React from "react";
import { useNavigate } from "react-router-dom";

import {View, Text, Spinner, Image, VStack} from 'native-base';
import {wait} from '~/Helper/Base';
import _ from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';
import { ReactSession } from 'react-client-session';
import Config from '~/Config/BaseConfig';
import { simpleFormData } from "~/Helper/FormHelper";

import {initializePurchases} from '~/Helper/PurchaseHelper'
import {Helmet} from "react-helmet";
export default function SplashScreen() {
    
    let CookieUser = null;

    try {
        CookieUser = ReactSession.get('user');
    } catch(e) {}

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkExistingUser = async () => {
        if (CookieUser) {
            CookieUser = JSON.parse(CookieUser);
        
            if (CookieUser?.data?.id) {
                
                await fetch(Config.host + '/api/auth/check/', {
                    method: 'POST',
                    headers: {...Config.defaultHeaders},
                    body: simpleFormData({'token': CookieUser.token})
                })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function(response) {
                    console.log('USER CHECKED SUCCESS');
                    dispatch(setUser(response.data));
                    
                    ReactSession.set('user', JSON.stringify(response.data));
                    initializePurchases();
                    navigate('/dashboard');

                })
                .catch(function(error) {
                    ReactSession.set('user', null);
                    navigate('/auth/login');
                });

            } else {
                ReactSession.set('user', null);
                navigate('/auth/login');
            }
        } else {
            ReactSession.set('user', null);
            navigate('/auth/login');
        }
    };

    React.useEffect(() => {
        wait(1000).then(() => {
            checkExistingUser();
        });

    }, []);

    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
            <Image
                source={require('~/Assets/layout/logo@3x.png')}
                h={50}
                w={100}
                resizeMode={'contain'}
                style={{margin: 30}}
            />
            <Spinner
                animating={true}
                color="#FFFFFF"
                size="large"
            />
        </VStack>;
}
