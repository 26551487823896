import React from 'react';

import { View, Box, HStack, VStack, Avatar, Image, Text, ScrollView, Heading, Button, Badge, useTheme, Skeleton, Pressable, Slider, Divider} from "native-base";
import Loader from '~/Components/Template/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from "lodash";
import UserHelper from "~/Helper/UserHelper";
import StylesConfig from '~/Config/Styles';
import { ReactSession } from 'react-client-session';
import Select from 'react-select'
import {toasty} from '~/Helper/Base';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Moment from 'moment';
import 'moment/min/moment-with-locales'

import { Camera, CameraResultType } from '@capacitor/camera';

import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import DatePicker from 'react-datepicker';
import { isBrowser, isMobile } from 'react-device-detect';


const Personal = () => {
    
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    const gMapsPlaces = React.useRef();
    const { colors } = useTheme();
    //const [cameraPermissionResponse, requestCameraPermission] = ImagePicker.useCameraPermissions();
    const regExUrl = new RegExp("^(http|https)://", "i");
    const imagePickerRef = React.useRef();
    const imageRef = React.useRef(null);

    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(true);
    const [formData, setFormData] = React.useState({});
    const [image, setImage] = React.useState(null);
    const [formatedAddress, setFormatedAddress] = React.useState(null);
    const [fields, setFields] = React.useState({
        'gender' : {
            'm' : 'männlich', 
            'w' : 'weiblich', 
            'g' : 'divers'
        },
        'relationship' : {
            'Keine Angabe' : 'Keine Angabe',
            'Ledig': 'Ledig',
            'In Beziehung': 'In Beziehung',
            'Verheiratet': 'Verheiratet',
            'Es ist kompliziert': 'Es ist kompliziert',
        }
    });

    React.useEffect(() => {
        _getLocationText();
    }, [formData]);
    
    React.useEffect(() => {
        if (currentUser && loading) {
            Moment.locale('de');
            _onMount();
        }
    }, [currentUser])

    const _onMount = async () => {
        if (!loading) setLoading(true);
        
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/get/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'includes': 'userdata'})
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.userdata.image.indexOf('placeholder') != -1) {
                    json.userdata.image = null;
                }
                setFormData(json.userdata);
                setLoading(false);
            });
        };
    };

    const _onUnMount = async () => {
        setLoading(true);
              
        if (!_.isEmpty(currentUser)) {
            let jsonFormData = {...formData};
            
            if ('bikes' in jsonFormData) delete jsonFormData.bikes;
            if ('token' in jsonFormData) delete jsonFormData.token;
            if ('driverlicense' in jsonFormData) delete jsonFormData.driverlicense;
            if ('usertype' in jsonFormData) delete jsonFormData.usertype;
            if ('fcm_token' in jsonFormData) delete jsonFormData.fcm_token;
            if ('id' in jsonFormData) delete jsonFormData.id;
            if ('email' in jsonFormData) delete jsonFormData.email;
            if ('pin' in jsonFormData) delete jsonFormData.pin;
            if ('locked' in jsonFormData) delete jsonFormData.locked;
            if ('notificationsettings' in jsonFormData) delete jsonFormData.notificationsettings;
            if ('settings' in jsonFormData) delete jsonFormData.settings;
            if ('outputname' in jsonFormData) delete jsonFormData.outputname;
            if ('image' in jsonFormData && !_.isNil(jsonFormData.image)) {
                let splitted = jsonFormData.image.split('/');
                
                if (splitted.length > 0) {
                    jsonFormData.image = splitted[splitted.length -1];
                }    
            }
            
            
            
            /** Udpdate current user data */
            let user = {...currentUser};
            user.data = {...currentUser.data, ...jsonFormData};
            
            const body = new FormData();
            body.append('multi', JSON.stringify({...jsonFormData}));
            
            if (image != null)  {
                if (image != null) {
                    body.append('file_image', image);
                }
            }
            
            fetch(Config.host + '/api/user/action/set/userdata/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: {'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : body
            })
            .then((res) => res.json())
            .then((json) => {
                if (json?.image_path) {
                    user.data.image = json.image_path;
                    setImage(null);
                    setFormData({...formData, image: json.image_path});
                }
                
                dispatch(setUser(user));
                ReactSession.set('user', JSON.stringify(user))   
                setLoading(false);
                toasty('Daten gespeichert', 'success');
            })
        };
        
    };

    const _handleImagePick = async(event) => {
        if (event?.target?.files?.length > 0) {
            setFormData({...formData, image: null});
            setImage(event.target.files[0]);
        }
    }

    const cameraPicker = async() => {
        let status = await Camera.requestPermissions();
        
        if (status.granted === false) {
            return;   
        }

        let result = await Camera.launchCameraAsync({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Uri,

        });

        if (!result.canceled && !result.cancel) {
            setFormData({...formData, image: null});
            setImage(image.webPath);
        }

    }

    const _fetchConfig = async (configType) => {
        await fetch(Config.host + '/api/config/get/' + configType +'/', {
            headers: { ...Config.defaultHeaders}
        })
        .then((res) => res.json())
        .then((json) => {
            let _fields = {...fields};
            _fields[configType] = json;
            setFields(_fields);
        });
    };

    const _setLocationValues = async(details) => {
        
        let filldata = {
            'street': null,
            'street_number' : null,
            'zip' : null,
            'city' : null,
            'country' : null,
            'region' : null,
            'formatedAddress' : null
        };
        
        let latLng = {
            'lat' : null,
            'lng' : null,
        }
        
        if ('geometry' in details && 'location' in details.geometry) {
            latLng = {'lat': details.geometry.location.lat(), 'lng' : details.geometry.location.lng()};
        }

        filldata = _addressComponentRunner(details, filldata, {
            street_number: 'street_number', 
            street : 'route', 
            city: 'locality', 
            region : 'administrative_area_level_1',
            country : 'country',
            zip: 'postal_code'});
        
        setFormData({...formData, ...filldata, ...latLng});
    };

    const _addressComponentRunner = (details, filldata, types) => {
        if ('address_components' in details) {
            
            _.forEach(types, (field, idx) => {
                
                let item = _.find(details.address_components, function(o){
                    if (o.types.includes(field)) return o;
                });
                
                if (item) {
                    filldata[idx] = (['region', 'street', 'country'].includes(idx)) 
                        ? item.short_name
                        : item.long_name;
                }
                
            });
            
        }
        
        if (filldata?.street_number) {
            filldata.street +=  ' ' + filldata.street_number;
        }

        return filldata;
    }

    const _getLocationText = () => {
        let locationText = [];

        if ('street' in formData && _.isEmpty(formData.street) == false) {
            locationText.push(formData.street);
        }
        
        if ('zip' in formData  && _.isEmpty(formData.zip) == false) {
            locationText.push(formData.zip);
        }
    
        if ('city' in formData && _.isEmpty(formData.city) == false) {
            locationText.push(formData.city);
        }
    
        if ('region' in formData && _.isEmpty(formData.region) == false) {
            locationText.push(formData.region);
        }
    
        if ('country' in formData && _.isEmpty(formData.country) == false) {
            locationText.push(formData.country);
        }
        
        if (_.isEmpty(locationText)) {
            return '';
        }

        return setFormatedAddress(locationText.join(', '));
    };

    const _handleSelect = (selection) => {
        geocodeByPlaceId(selection?.value?.place_id)
        .then(result => {
            _setLocationValues(result[0]);
        });
        
    }

    const _handleRemoveProfileImage = async () => {
        SweetAlert.fire({
            title: 'Profilbild',
            text : 'Möchtest du dein Profilbild wirklich löschen?',
            showCancelButton: true,
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        }).then((result) => {
            if (result.isConfirmed) {
                setFormData({...formData, image: null});
                setImage(null);
            }
        });
    }
    

    if (!formData) {
        return (<><Loader loading={loading} /></>);
    } else {
        return (
            <>
                <Loader loading={loading} />
                <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <HStack justifyContent={'space-between'} alignItems={'center'}>
                            <Text style={StylesConfig.styles.card.header_text}>Profilbild</Text>
                        </HStack>
                        
                    </View>

                    <View style={StylesConfig.styles.card.body} alignItems={'center'}>
                        
                        {image && !formData?.image && 
                            <Badge bgColor={'success.400'}>VORSCHAU</Badge>
                        }

                        {image && !formData?.image && 
                            <img id={'profileImage'} alt={'Profilbild'} style={{
                                width: 300,
                                height: 300,
                                borderRadius: 600
                            }} src={URL.createObjectURL(image)}/>
                        }

                        {!image && formData?.image && 
                            <img id={'profileImage'} alt={'Profilbild'} style={{
                                width: 300,
                                height: 300,
                                borderRadius: 600
                            }} src={Config.host + formData.image}/>
                        }
                    
                    
                        <VStack alignItems={'center'}>
                            <HStack mb={(image || formData?.image) ? 0 : 5} mt={(image || formData?.image) ? -6 : 0} space={3}>
                                
                                <Pressable borderRadius={'full'} bgColor={colors.muted['500']} p={3} onPress={() => imagePickerRef?.current.click()}>
                                    <FontAwesomeIcon size={25} color={'white'} icon="fa-solid fa-image" />
                                </Pressable>

                                {(image || formData?.image) &&
                                    <Pressable borderRadius={'full'} onPress={() => _handleRemoveProfileImage()} bgColor={colors.danger['500']} p={3}><FontAwesomeIcon size={25} color={'white'} icon={'fa-solid fa-times'}/></Pressable>
                                }
                            </HStack>
                            
                            <input type={'file'} ref={imagePickerRef} onChange={_handleImagePick} accept={"image/png, image/jpeg"} style={{display:'none'}}/>
                            
                            {(image == null && formData?.image == null) &&
                                <VStack>
                                    <Heading textAlign={'center'} fontSize={'lg'} color={colors.muted['400']}>Kein Bild ausgewählt</Heading>
                                    <Text textAlign={'center'}>Drücke auf das Bild- oder Kamerasymbol</Text>
                                    
                                </VStack>
                            }
                        </VStack>
                    </View>
                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
                
                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Steckbrief</Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Beschreibe dich mit wenigen Worten"
                            className="mb-3"
                        >
                            <textarea
                                className='form-control'
                                value={formData?.intro}
                                onChange={(e) => {
                                    setFormData({...formData, intro: e.target.value});
                                }} 
                                />
                        </FloatingLabel>
                    </View>
                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
                
                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Persönliche Angaben</Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Geschlecht"
                            className="mb-3"
                            >
                            <Form.Select 
                                value={formData?.gender}
                                onChange={(e) => {
                                    setFormData({...formData, gender: e.target.value});
                                }}
                                >
                                {Object.keys(fields.gender).map((item, index) => {
                                    return <option value={item}>{fields.gender[item]}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Spitzname"
                            className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.username} 
                                onChange={(e) => {
                                    setFormData({...formData, username: e.target.value});
                                }} 
                                />
                        </FloatingLabel>

                        
                        <HStack space={5} mt={5}>
                            <View flex={1}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Vorname"
                                    className="mb-3"
                                    >
                                    <input
                                        className='form-control'
                                        type={'text'} 
                                        value={formData?.name} 
                                        onChange={(e) => {
                                            setFormData({...formData, name: e.target.value});
                                        }} 
                                        />
                                </FloatingLabel>
                            </View>
                            <View flex={1}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Nachname"
                                    className="mb-3"
                                    >
                                    <input
                                        className='form-control'
                                        type={'text'} 
                                        value={formData?.lastname} 
                                        onChange={(e) => {
                                            setFormData({...formData, lastname: e.target.value});
                                        }} 
                                        />
                                </FloatingLabel>
                            </View>
                        </HStack>
                        
                        <View style={{height: 20}} />
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Geburtstag *"
                            className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'date'} 
                                value={formData?.birthday} 
                                onChange={(e) => {
                                    setFormData({...formData, birthday: Moment(e.target.value).format('YYYY-MM-DD')});
                                }} 
                                dateFormat={'dd.MM.yyyy'} />
                        </FloatingLabel>

                        
                        <View style={{height: 20}} />
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Beziehungsstatus"
                            className="mb-3"
                            >
                            <Form.Select 
                                value={formData?.relationship}
                                onChange={(e) => {
                                    setFormData({...formData, relationship: e.target.value});
                                }}
                                >
                                {Object.keys(fields.relationship).map((item, index) => {
                                    return <option value={item}>{fields.relationship[item]}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>


                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>

                <div style={{...StylesConfig.styles.card.wrapper, zIndex: 2}}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Standort</Text>
                    </View>

                    <View style={{...StylesConfig.styles.card.body, zIndex: 3}}>
                        <Box>Dein Standort wird ausschließlich dazu verwendet, dir Touren und Mitglieder in deiner Umgebung anzuzeigen. Andere Mitglieder können nur deine Postleitzahl und Stadt einsehen!</Box>
                        
                        <HStack space={2} mt={3} mb={3}>
                            <Text fontWeight={'bold'}>Aktuell: </Text>
                            <Text>{formatedAddress}</Text>
                        </HStack>
                        <GooglePlacesAutocomplete
                            selectProps={{
                                value: formatedAddress,
                                placeholder: 'Adresse eingeben...',
                                onChange: _handleSelect,
                            }}
                            
                            apiOptions={{ language: 'de', region: 'de' }}
                            apiKey={Config.googleApiToken} />

                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15, zIndex: 2}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </div>

                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Kontakt</Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Handynummer"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.number} 
                                onChange={(e) => {
                                    setFormData({...formData, number: e.target.value});
                                }} 
                                />
                        </FloatingLabel>

                        
                        <Box mb={3} mt={1} _text={{fontSize: 'xs'}}>Deine Handynummer ist nicht öffentlich einsehbar. Solltest du dich zu einer Tour o.ä. eingetragen haben, 
                            kann nur der TourGuide deine Handynummer sehen um dich zu kontaktieren.</Box>
                            
                            <View style={{height: 20}} />
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Telegram Benutzername"
                            className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.telegram} 
                                onChange={(e) => {
                                    setFormData({...formData, telegram: e.target.value});
                                }} 
                                />
                        </FloatingLabel>

                        <View style={{height: 20}} />
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Instagram Profil"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.instagram} 
                                onChange={(e) => {
                                    setFormData({...formData, instagram: e.target.value});
                                }} 
                                />
                        </FloatingLabel>

                        <Box mb={3} mt={1} _text={{fontSize: '2xs'}}>Benutzername ohne @ am Anfang</Box>

                        <View style={{height: 20}} />
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Facebook Profil"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.facebook} 
                                onChange={(e) => {
                                    setFormData({...formData, facebook: e.target.value});
                                }} 
                                />
                        </FloatingLabel>
                        <Box mt={1} _text={{fontSize: '2xs'}}>Bitte komplette Profiladresse, beginnend mit https:// angeben</Box>

                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
            </>
        );
    }
};

export default Personal;