import cloneDeep from 'lodash/cloneDeep';
import StylesConfig from '~/Config/Styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import Config from '~/Config/BaseConfig';
import _ from 'lodash';
import { simpleFormData } from '~/Helper/FormHelper';
import DateHelper from '~/Helper/DateHelper';
import { Box, Divider, HStack, Flex, Modal, useTheme, Text, View} from 'native-base';

const styles = {
    item: {},

    itemSpeed: {
        width: 35,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },

    itemSpeedSuccess: {
        backgroundColor: StylesConfig.color.success,
    },

    itemSpeedWarning: {
        backgroundColor: StylesConfig.color.warning,
    },

    itemSpeedDanger: {
        backgroundColor: StylesConfig.color.danger,
    },

    itemSpeedTraining: {
        backgroundColor: StylesConfig.color.base,
    },

    itemSpeedEvent: {
        backgroundColor: '#c084fc',
    }

};

const TourHelper = {
    
    getMapsLink : (lat, lng, address = null) => {

        const latInt = parseFloat(lat);
        const lngInt = parseFloat(lng);
    
        let options = { latitude: latInt +';' + lngInt};

        if (address != null) {
            options = {address: address, ...options};
        }

        let queryString = '';

        Object.keys(options).map((item) => {
            queryString += encodeURI(options[item]) +'+';
        });

        window.open('https://www.google.com/maps/search/?api=1&query='+ queryString);
    },

    getWeatherLink: (lat, lng, type) => {
        let href =  (type == 'ten-days') 
            ? '10tage' : 'stundlich';

        const url = `https://weather.com/de-DE/wetter/${href}/l/${lat},${lng}`;
        return window.open(url);
    },

    getDetailBar : (item) => {
        let output = '';
        Moment.locale('de');
        
        const now = Moment();
        const tomorrow = Moment().add('1', 'days');
        let hasText = false;

        if (item.start_date) {
            
            let start = Moment(item.start_date + ' ' + item.start_time);

            if (now.format('YYYYMMDD') == start.format('YYYYMMDD')) {
                output = 'Heute';
                hasText = true;
            }

            if (tomorrow.format('YYYYMMDD') == start.format('YYYYMMDD')) {
                output = 'Morgen';
                hasText = true;
            }
            
            let end = null;

            if (item.duration > 0) {
                end = cloneDeep(start);
                end = end.add(item.duration, 'h');
            }

            if (hasText == false) output += start.format('dd, DD.MM.Y');

            output += ' • ';

            output += start.format('HH:mm');

            if (end !== null) {
                output += ' - ' + end.format('HH:mm');
                output += ' • ';
                output += DateHelper.toHoursAndMinutes(item.duration, true) + ((item.duration == 1) ? ' Stunde' : ' Stunden');
            }
            

        }

        if (item.km > 0) {
            output += ' • ' + item.km + ' km';
        }

        if (item.distance != null && item.distance != 'null') {
            let distance = parseFloat(item.distance);

            output += ' • ca. ' + Math.round(distance) + ' km entfernt';
        }

        return output;
    },

    getTourStatus : (start_date, start_time, duration, returnDate) => {
        Moment.locale('de');
        let start = Moment(start_date + ' ' + start_time);
        let end = null;
        
        const now = Moment();

        let returnObj = {
            status : 0,
            text : ''
        };

        if (duration > 0) {
            end = cloneDeep(start);
            end = end.add(duration, 'h');
        } 
        
        if (start.diff(now, 'weeks', true) >= 4) {
            returnObj = {
                status : 5,
                text : 'Started ' + DateHelper.humanize(start)
            };
        }
        
        if (start.diff(now, 'days', true) >= 7) {
            returnObj = {
                status : 5,
                text : 'Started ' + DateHelper.humanize(start)
            };
        }
        
        if (start.diff(now, 'days', true) < 7) {
            returnObj = {
                status : 5,
                text : 'Started ' + DateHelper.humanize(start)
            };
        }
        
        if (start.diff(now, 'days', true) == 1) {
            returnObj = {
                status : 4,
                text : 'Started morgen'
            };
        }
        
        if (start.diff(now, 'days', true) < 1) {
            returnObj = {
                status : 3,
                text : 'Started in Kürze'
            };
        }

        if (now.format('X') > start.format('X')) {
            if (end != null) {
                returnObj = {
                    status : 1,
                    text : DateHelper.humanize(end)
                };    
            } else {
                returnObj = {
                    status : 1,
                    text : DateHelper.humanize(start)
                };
            }
            
        }
        if (end != null && now.format('X') > start.format('X') && now.format('X') < end.format('X')) {  
            returnObj = {
                status : 2,
                text : 'Läuft'
            };
        }
        

        if (returnDate) {
            returnObj.start = start;
            returnObj.end = end;
        }

        return returnObj;
    },

    getFormattedTourStatus : (item) => {
        const tourState = TourHelper.getTourStatus(item.start_date, item.start_time, item.duration);
        let style = {};

        if (tourState.status != 0)
            style = { color: StylesConfig.color.warning }

        if (tourState.status == 1)
            style = { color: StylesConfig.color.danger }
        
        if (tourState.status >= 5)
            style = { color: StylesConfig.color.gray }

        if (tourState.status != 0) {
            return <Text style={style}>{tourState.text}</Text>;
        }
    },

    getSpeedIndicator : (item) => {
        let output = <View />;
        let colStyle = '';
        let icon = '';
        
        if (item?.tourtype && (item?.tourtype == 'tour' || item.tourtype == 'quicktrip')) {

            if (item?.fahrstil.indexOf('gemütlich') != -1) {
                colStyle = [styles.itemSpeed, styles.itemSpeedSuccess];
                icon = 'far fa-tachometer-slow';
            }

            if (item?.fahrstil.indexOf('normal') != -1) {
                colStyle = [styles.itemSpeed, styles.itemSpeedWarning];
                icon = 'far fa-tachometer-average';
            }

            if (item?.fahrstil.indexOf('zügig') != -1) {
                colStyle = [styles.itemSpeed, styles.itemSpeedDanger];
                icon = 'far fa-tachometer-fast';
            }

            if (item?.fahrstil.indexOf('schnell') != -1) {
                colStyle = [styles.itemSpeed, styles.itemSpeedDanger];
                icon = 'far fa-tachometer-fastest';
            }

            if (item?.fahrstil.indexOf('training') != -1) {
                colStyle = [styles.itemSpeed, styles.itemSpeedTraining];
                icon = 'far fa-graduation-cap';
            }

        } else if (item?.tourtype && item.tourtype == 'training') {

            colStyle = [styles.itemSpeed, styles.itemSpeedTraining];
            icon = 'far fa-graduation-cap';

        } else if (item?.tourtype && item.tourtype == 'event') {

            colStyle = [styles.itemSpeed, styles.itemSpeedEvent];
            icon = 'fab fa-angellist';

        }

        if (icon != '') {
            output = <View style={colStyle}><FontAwesomeIcon color='white' size={'xl'} icon={icon} /></View>;
        }

        return output;
    },

    getTourLabelStatus : (extendData, type) => {
        if (typeof extendData != 'object') 
            return false;

        if (type == 'price') {
            return 'price' in extendData && extendData.price > 0;
        }

        if (type == 'onlyFriends') {
            return 'onlyFriends' in extendData && (extendData.onlyFriends == true || extendData.onlyFriends == 'true');
        }

        if (type == 'onlyGirls') {
            return 'onlyGirls' in extendData && (extendData.onlyGirls == true || extendData.onlyGirls == 'true');
        }

        return false;
    },

    getLabelsBar : (extendData, styles = {}) => {
        let labels = [];

        if (typeof extendData != 'object') 
            return null;

        if (extendData !== false && extendData != null) {
            if (TourHelper.getTourLabelStatus(extendData, 'price')) {
                labels.push(
                    <Box 
                        rounded={'full'} 
                        _text={{fontSize: 9, fontWeight: 'bold'}} 
                        style={{flexDirection: 'row', justifyContent:'center', alignItems:'center', paddingHorizontal: 5, backgroundColor: '#facc15'}}
                        >
                        <FontAwesomeIcon size={'2xs'} icon="fa-solid fa-euro-sign"  style={{marginHorizontal: 2}}/>
                        KOSTENPFLICHTIG
                    </Box>);
            }

            if (TourHelper.getTourLabelStatus(extendData, 'onlyFriends')) {
                labels.push(
                    <Box 
                        rounded={'full'} 
                        _text={{fontSize: 9, fontWeight: 'bold'}} 
                        style={{flexDirection: 'row', justifyContent:'center', alignItems:'center', paddingHorizontal: 5, backgroundColor: '#7dd3fc'}}
                        >
                        <FontAwesomeIcon size={'2xs'} icon="fa-duotone fa-user-group" style={{marginHorizontal: 2}}/> 
                        FRIENDS ONLY
                    </Box>);
            }

            if (TourHelper.getTourLabelStatus(extendData, 'onlyGirls')) {
                labels.push(
                    <Box 
                        rounded={'full'} 
                        _text={{fontSize: 9, fontWeight: 'bold'}} 
                        style={{flexDirection: 'row', justifyContent:'center', alignItems:'center', paddingHorizontal: 5, backgroundColor: '#f9a8d4'}}
                        >
                        <FontAwesomeIcon size={'2xs'} icon="fa-solid fa-venus"  style={{marginHorizontal: 2}}/> 
                        GIRLS ONLY
                    </Box>);
            }
        }
        
        if (labels.length > 0) {
            return (<HStack space={1} style={{position:'absolute', right: 3, top: 3, ...styles}}>{labels}</HStack>);
        }
    }

};



export default TourHelper;



