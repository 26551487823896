import {useState, useEffect, useRef} from 'react';
import { Button, HStack,View, useTheme, Box, Flex, Image, Switch, Text, Center, Heading, Pressable, Divider, VStack, Skeleton, Spinner, Progress} from 'native-base';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '~/Components/Template/Loader';
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import { useNavigate, useParams } from "react-router-dom";
import {toasty} from '~/Helper/Base';
import { simpleFormData } from '~/Helper/FormHelper';
import { DatePicker } from 'rsuite';
import DateHelper from '~/Helper/DateHelper';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import CircularPicker from '@fseehawer/react-circular-slider';
import { Col, Row } from 'react-bootstrap';

const TourForm = ({...props}) => {
    const currentUser = useSelector((state) => state.user);

    const gMapsPlaces = useRef();
    const [image, setImage] = useState(null);
    const [gpx, setGpx] = useState(null);
    const [extended_settings_visible, setExtendedSettingsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formtype, setFormType] = useState('tour');
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [extendedView, setExtendedView] = useState(false);
    const [lastLocations, setLastLocations] = useState(false);
    const [fields, setFields] = useState({
        'fahrstil' : {
            'gemütlich (StVO)' : 'gemütlich (StVO)', 
            'normal (StVO +10 km/h)' : 'normal (StVO +10 km/h)', 
            'zügig (StVO +20 km/h)' : 'zügig (StVO +20 km/h)', 
            'schnell (kein StVO)' : 'schnell (kein StVO)'
        },
        'communicationsystem': {
            0 : 'Keins', 
            1 : 'Sena', 
            2 : 'Cardo', 
            3 : 'Chain-App', 
            4 : 'WhatsApp-Call', 
            5 : 'Sonstiges'
        },
        'min_ccm' : {
            0 : 'Keine Einschränkung',
            125 : 'größer gleich 125ccm',
            300 : 'größer gleich 300ccm',
            600 : 'größer gleich 600ccm',
            1000 : 'größer gleich 1000ccm',
            1500 : 'größer gleich 1500ccm',
        },
        'routetype' : {
            0 : 'Landstraße',
            1 : 'Parkplatz',
            10 : 'Landstraße / Autobahn',
            20 : 'Autobahn',
            30 : 'Offroad',
            40 : 'Rennstrecke',
            50 : 'Privatgelände'
        },
        'users': {},

    });
    
    const { colors } = useTheme();
    const navigate = useNavigate();
    const params = useParams();

    const imagePickerRef = useRef();
    const gpxPickerRef = useRef();

    const _handleImagePick = async(event) => {
        if (event?.target?.files?.length > 0) {
            setFormData({...formData, image: null});
            setImage(event.target.files[0]);
        }
    }

    const _handleGpxPick = async(event) => {
        if (event?.target?.files?.length > 0) {
            setFormData({...formData, gpx: null});
            setGpx(event.target.files[0]);
        }
    }

    const _fetchUsers = async () => {
    
        if (!_.isEmpty(currentUser)) {
            
            fetch(Config.host + '/api/user/collection/flat/', {
                method: 'POST',
                headers: {
                    ...Config.defaultHeaders,
                    'token': currentUser.token,
                    'X-CSRF-TOKEN': currentUser.csrf.hash
                },
                body : simpleFormData({
                    'filter' : JSON.stringify({
                        'order' : 'name ASC, lastname ASC'
                    })
                }) 
            })
            .then((res) => res.json())
            .then(json => {
                let userList = {};
                json.map(item => {
                    let name = (item.fullname == item.outputname) ? item.fullname : item.outputname;
                    name = name + ' ('+ item.age +')';
                    userList[parseInt(item.id)] = name;
                });
                userList[null] = 'Keiner';
                setFields({...fields, users : userList});
            });
        };
    
    }

    const _loadTour = async(tour_id, appendData) => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
          fetch(Config.host + '/api/touren/get/' + tour_id + '/', {
            method: 'POST',
            headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
            body: simpleFormData({'includes' : 'tour'})      
            })
            .then((res) => res.json())
            .then((json) => {
                let tourData = json.tour;
    
                if (typeof tourData.extend == 'string' && tourData.extend != null ) {
                    tourData.extend = JSON.parse(tourData.extend);
                } else {
                    tourData.extend = {};
                }
    
                if (_.isEmpty(appendData) == false) {
                    setFormData({...tourData, ...appendData});
                } else {
                    setFormData(tourData);
                }
                setLoading(false);
            });
        };
    };

    const _loadLastStartLocations = async() => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
          fetch(Config.host + '/api/touren/helper/_user_start_locations/', {method: 'POST', headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash }})
            .then((res) => res.json())
            .then((json) => {
                
                if(_.isArray(json) && json.length > 0) {
                    setLastLocations(json);
                } else {
                    setLastLocations([]);
                }
            });
        };
    };

    useEffect(() => {
        setFormType(params.type);
        Moment.locale('de');

        if (currentUser) {
            _loadLastStartLocations();

            if (params?.tour_id) {
                setIsEdit(true);
            }
            
            let initFormData = {
                'start_date' : Moment().format('YYYY-MM-DD'),
                'start_time' : Moment().add(2, 'hours').format('HH') + ':00:00',
                'extend' : {}
            };
            
            
            if (params?.type == 'quicktrip' 
                && typeof params.tour_id === 'undefined'
                && params.action === 'add') {

                let quicktripFormData = {
                    'title' : 'Kurztrip',
                    'fahrstil' : 'gemütlich (StVO)',
                    'routetype': 0,
                    'km' : '100',
                    'duration': '2',
                    'start_date' : Moment().format('YYYY-MM-DD'),
                    'start_time' : Moment().add(2, 'hours').format('HH') + ':00:00',
                };
                initFormData = {...initFormData, ...quicktripFormData};
            }
            
            if (setFormData({...formData, ...initFormData}))
                handleChange(initFormData.km);

            if (typeof params.tour_id !== 'undefined' && params.tour_id > 0) {
                const loadTour = async () => {
                    let appendData = {};
                    
                    if (params.action == 'copy') {
                        const DateTime =  Moment().add(1, 'days');
                        appendData = {
                            tour_id : null, 
                            start_date : DateTime.format('YYYY-MM-DD'), 
                            start_time: DateTime.format('HH') + ':00'
                        };
                    }
                
                    _loadTour(params.tour_id, appendData);
                    
                }
                setExtendedView(true);
                setExtendedSettingsVisible(true);
                loadTour();
            }
            
            _fetchUsers();
         }
        return () => {
            setImage(null);
            setGpx(null);
            setFormType('tour');
            setStep(0);
            setSubmitting(false);
            setFormData({});
            setErrors([]);
            setIsEdit(false);
            setExtendedView(false);
            setLastLocations(false);
        }
    }, [currentUser]);

    useEffect(() => {
        if (isEdit == true && Object.keys(formData).length <= 3 && loading == false) {
            setLoading(true);
        }

        if (Object.keys(formData).length > 3) {
            setLoading(false);
        }

        if (isEdit == false) {
            setLoading(false);
        }
    }, [isEdit, formData, loading])

   
    const getFormData = (key) => {
        if (_.has(formData, key)) {
            return formData[key];
        } else {
            return null;
        }
    };

    const _checkInput = function(fields, lastStep = false) {
        let errorsCounter = [];
        let errorFields = [];
        

        fields.map((key) => {
            
            if (key == 'start_location' && _.isEmpty(formData[key])) {
                errorFields.push(key)
                errorsCounter.push('Bitte wähle einen gültigen Startpunkt aus der Liste aus.');
            } else if (key == 'routetype' && formData[key] === null) {
                errorFields.push(key)
                errorsCounter.push('Pflichtfelder bitte ausfüllen.');
            } else if (
                key != 'routetype' 
                && key != 'start_location'
                && _.isEmpty(formData[key])
                ) {
                errorFields.push(key)
                errorsCounter.push('Pflichtfelder bitte ausfüllen.');
            }
        });

        if (errorsCounter.length > 0) {            
            setErrors(errorFields);
            alert(errorsCounter[0]);
            return false;
        } else {
            setErrors([]);
            if (lastStep == false) {
                setStep(step + 1);
            }

            return true;
        }
    };

    const submitForm = async() => {
        setLoading(true);

        if (!_.isEmpty(currentUser)) {
            const body = new FormData();
            let requestData = formData;
            requestData = {...requestData, tourtype : formtype};
            
            body.append('field', JSON.stringify(requestData));
            
            let sendHeaders = {
                'token': currentUser.token,
                'X-CSRF-TOKEN': currentUser.csrf.hash
            };

            if (image != null)  {
                body.append('file_image', image);                
            }

            if (gpx != null) {
                body.append('file_gpx', gpx);
            };

            await fetch(Config.host + '/api/touren/save/', {
                method: 'POST',
                headers: sendHeaders,
                body: body
            })
            .then((res) => res.json())
            .then((json) => {
                setLoading(false);
                toasty('Erfolgreich gespeichert');
                navigate('/touren');
            });
        };
    }
 
    useEffect(() => {
        if (formData.km > 0 && extendedView == false && formtype == 'quicktrip') {
            handleChange(formData.km);
        }
    }, [formData.routetype, formData.fahrstil])

    const handleChange = (v) => {
        if (v > 0) {
           
            let km = v;
            let calcVal = null;
            let routetypeValue = parseInt(formData.routetype);
            let fahrstil = formData.fahrstil;

            switch(routetypeValue) {
                case 0: /* Landstraße */
                    calcVal = 1.5;
                    break;
                
                case 10: /* Landstraße / Autobahn */
                    calcVal = 1.25;
                    break;
                
                case 20: /* Autobahn */
                    calcVal = 0.50;
                    break;
                
                case 30: /* Offraod */
                    calcVal = 5.0;
                    break;

                default: 
                    calcVal = 1.35;
                    break;
            }
            
            if (fahrstil.indexOf('zügig') !== -1) {
                calcVal *= .8;
            } else if (fahrstil.indexOf('normal') !== -1) {
                calcVal *= .9;
            } else if  (fahrstil.indexOf('schnell') !== -1) {
                calcVal *= .5;
            } else {
                calcVal *= 1;
            }

            let duration = km * calcVal;
        
            setFormData({...formData, km : km, duration : duration/60});
        }
    }


    let segmentControl = [];
    for (const [key, value] of Object.entries(fields.routetype)) {
        if (key != 1 && key != 50 && key != 40) {
            segmentControl.push({'value': key, 'label' : value});
        }
    };

    const TourFormHeader = () => {
        let title = '';
        
        if (params?.type == 'tour') {
            title = 'Tour';
        }

        if (params?.type == 'quicktrip') {
            title = 'Kurztrip';
        }

        if (params?.type == 'event') {
            title = 'Event';
        }

        if (params?.type == 'training') {
            title = 'Training';
        }

        if (isEdit) {
            if (formData?.title) {
                title += ': "' + formData.title +'"';
            }

            title += ' bearbeiten';
        } else {
            title += ' erstellen';
        }

        return (
            <Box bgColor={'white'} mt={5} p={5}><Heading fontSize={'xl'} textAlign={'center'}>{title}</Heading></Box>
        );
    }

    return (
        <>
        
            <Loader loading={loading} />

            <TourFormHeader />
            <Progress value={(50 * step)} backgroundColor={colors.coolGray['300']}/>

            <View style={{flex: 1, justifyContent: "space-around"}}>
                {step == 0 &&
                    <View alignItems={'center'}>
                        <Box mt={50} />
                            <FontAwesomeIcon size={'4x'} icon={'fa-solid fa-location-dot'} />
                            <Text>Startpunkt angeben</Text>
                        <Box mt={30} />
                        <View style={{width: '80%', minWidth: '20vw', maxWidth: '80vw'}}>
                           
                           <div style={{marginBottom: 15, zIndex: 99}}>
                                <GooglePlacesAutocomplete
                                    selectProps={{
                                        value: formData?.start_location,
                                        placeholder: 'Adresse eingeben...',
                                        onChange: (item) => {
                                            geocodeByPlaceId(item?.value?.place_id)
                                            .then(result => {
                                                const details = result[0];
                                                if (details !== null && details.geometry.location !== null) {
                                                    
                                                    setFormData({
                                                        ...formData, 
                                                        'start_location' : details.formatted_address, 
                                                        'start_location_latlng' : `${details.geometry.location.lat()};${details.geometry.location.lng()}`
                                                    })
                                                }
                                            });
                                        },
                                    }}
                                    
                                    apiOptions={{ language: 'de', region: 'de' }}
                                    apiKey={Config.googleApiToken}
                                />
                                {formData?.start_location && 
                                    <HStack space={2} mt={3} mb={3}>
                                        <Text fontWeight={'bold'}>Aktuell: </Text>
                                        <Text>{formData?.start_location}</Text>
                                    </HStack>
                                }
                                </div> 
                            
                            
                            {_.isArray(lastLocations) == false && lastLocations == false &&
                                <View marginBottom={5}>
                                    <Text color={'muted.400'} fontSize={'xs'} marginBottom={3}>Deine letzten Startpunkte: </Text>
                                    <Skeleton.Text my={2} lines={1} />
                                    <Skeleton.Text my={2} lines={1} />
                                </View>
                            } 

                            {_.isArray(lastLocations) && lastLocations.length > 0 &&
                                <View marginBottom={5}>
                                <Text color={'muted.400'} fontSize={'xs'} marginBottom={3}>Deine letzten Startpunkte: </Text>
                                {
                                    lastLocations.map((item, index) => {
                                        return (
                                        <>
                                            <Pressable key={index} onPress={() => {
                                                setFormData({
                                                    ...formData, 
                                                    'start_location' : item.start_location, 
                                                    'start_location_latlng' : item.start_location_latlng
                                                });
                                                setStep(1);
                                            }}>
                                                <Box padding={4} backgroundColor={'white'}>
                                                    <Text>{item.start_location}</Text>
                                                </Box>    
                                            </Pressable>
                                        
                                            {index < lastLocations.length-1 && <Divider />}
                                        </>
                                        );
                                    })
                                }
                                </View>
                            }

                            {formtype == 'quicktrip' && 
                                <>
                                    <View h={15} />
                                    <Pressable onPress={() =>  setExtendedView(!extendedView)}>
                                        <VStack>
                                            <HStack space={5} h={7} justifyContent={'space-between'} alignItems={'center'}>
                                                <Box>Erweiterter Modus</Box>
                                                <Switch size="lg" isChecked={extendedView} onToggle={() =>  setExtendedView(!extendedView)}/>
                                            </HStack>
                                            <Text>Aktivieren um detailierte Tourangaben hinzuzufügen</Text>
                                        </VStack>
                                        
                                    </Pressable>
                                    <View h={30} />
                                </>
                            }

                            <Button onPress={() => {
                                _checkInput(['start_location', 'start_location_latlng']);
                            }}>Weiter</Button>

                        </View>
                    </View>
                }
                
                {/** Schnell Modus */}
                {step == 1 && (formtype == 'quicktrip' && extendedView == false) &&
                    <>
                        
                        <View style={StylesConfig.styles.card.wrapperMt}>
                            <View style={StylesConfig.styles.card.header}>
                                <Text style={StylesConfig.styles.card.header_text}>Grundangaben</Text>
                            </View>

                            <View style={StylesConfig.styles.card.body}>
                                
                                <FloatingLabel
                                        controlId="floatingInput"
                                        label="Titel *"
                                        className="mb-3"
                                    >
                                    <input
                                        className='form-control'
                                        type={'text'} 
                                        value={getFormData('title')} 
                                        onChange={(e) => setFormData({...formData, title: e.target.value})} 
                                        />
                                </FloatingLabel>
                                
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Streckenart *"
                                    className="mb-3"
                                    >
                                    <Form.Select 
                                        value={formData?.routetype}
                                        onChange={e => setFormData({...formData, routetype: parseInt(e.target.value)})}
                                        >
                                        <option value={''}>Bitte auswählen</option>
                                        {segmentControl.map(item =>  {
                                            return <option value={parseInt(item.value)}>{item.label}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                                

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Fahrstil *"
                                    className="mb-3"
                                    >
                                    <Form.Select 
                                        value={formData?.fahrstil}
                                        onChange={e => setFormData({...formData, fahrstil: e.target.value})}
                                        >
                                        <option value={''}>Bitte auswählen</option>
                                        {Object.keys(fields.fahrstil).map(item =>  {
                                            return <option value={item}>{fields.fahrstil[item]}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                                
                                    
                                <Row style={{marginBottom: 15}}>
                                    <Col>
                                        <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                            <Text>Startdatum: </Text>
                                            <DatePicker
                                                style={{ width: '100%' }} 
                                                value={
                                                    ((formData.start_date?.length > 3) 
                                                    ? Moment(formData.start_date + ' ' + formData.start_time.substr(0,5)).toDate()
                                                    : new Date())
                                                } 
                                                onChange={(value) => {
                                                    setFormData({...formData, start_date : Moment(value).format('YYYY-MM-DD')});
                                                }} 
                                                format="dd.MM.yyyy" />
                                            
                                        </HStack>
                                    </Col>
                                    
                                    <Col>
                                        <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                            <Text>Startzeit: </Text>
                                            
                                            <DatePicker
                                                style={{ width: '100%' }} 
                                                value={
                                                    ((formData.start_date?.length > 3) 
                                                    ? Moment(formData.start_date + ' ' + formData.start_time.substr(0,5)).toDate()
                                                    : new Date())
                                                } 
                                                onChange={(value) => {
                                                    setFormData({...formData, start_time : Moment(value).format('HH:mm')});
                                                }} 
                                                format="HH:mm" />
                                        </HStack>
                                    </Col>
                                </Row>
                                
                                <View style={{height: 10}} />
                                
                                <HStack space={5} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Text fontWeight={'bold'}>Women only</Text>
                                        <Text fontSize={'xs'}>Nur für Frauen</Text>
                                    </Box>
                                    <Switch size="sm" isChecked={formData.extend.onlyGirls} onToggle={(state) => {
                                        setFormData({...formData, extend : {...formData.extend, onlyGirls: state}})
                                    }}/>
                                </HStack>
                                
                                <View h={25} />
                                
                                <HStack space={5} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Text fontWeight={'bold'}>Nur Freunde</Text>
                                        <Text fontSize={'xs'}>Nur deine Freunde können teilnehmen</Text>
                                    </Box>
                                    <Switch size="sm" isChecked={formData.extend.onlyFriends} onToggle={(state) => {
                                        setFormData({...formData, extend : {...formData.extend, onlyFriends: state}})
                                    }}/>
                                </HStack>
                                
                                <Divider my={15}/>
                                
                                <Center>
                                    <CircularPicker
                                        onChange={handleChange}
                                        min={0}
                                        valueFontSize="4rem"
                                        labelColor={colors.primary['600']}
                                    
                                        progressColorFrom={colors.primary['600']}
                                        label={
                                            'ca. ' + ((formData.duration > 0) ? DateHelper.toHoursAndMinutes(formData.duration, true) : 0) + ' Stunden'
                                            + ` • `
                                            + 'bis ' +(Moment(formData.start_date + ' ' + formData.start_time).add(formData.duration, 'hours').format('HH:mm')) + ' Uhr'
                                        }
                                        max={350}
                                        labelBottom
                                        appendToValue={' km'}
                                        dataIndex={50}
                                        >
                                            <FontAwesomeIcon color={colors.primary['600']} icon="fa-solid fa-circle-dot" />
                                        </CircularPicker>
                                        
                                    
                                        
                                    
                                </Center>

                                

                                <HStack space={5} mt={5} p={3}>
                                    <Flex flex={1}>
                                        <Button variant="subtle" colorScheme="muted" onPress={() => {
                                            setStep(step - 1);
                                        }}>Zurück</Button>
                                    </Flex>
                                    <Flex flex={1}>
                                        {submitting === false && 
                                            <Button onPress={() => {
                                                
                                                let checkRequieredFields = ["title", "start_date", "start_time", "routetype"];
                                                
                                                if (formtype == 'quicktrip') {
                                                    checkRequieredFields = [...checkRequieredFields, "fahrstil"];
                                                }

                                                if (_checkInput(checkRequieredFields, true)) {
                                                    setSubmitting(true);
                                                    submitForm();
                                                }
                                            }}>Speichern</Button>
                                        }

                                        {submitting &&
                                            <Button isLoading>Wird gespeichert</Button>
                                        }
                                    </Flex>
                                </HStack>
                            </View>
                        </View>

                        <View flex={1} />
                    </>
                }

                {/** Erweiterter Modus */}
                {step == 1 && ((formtype == 'quicktrip' && extendedView == true) || formtype != 'quicktrip') &&
                    <>
                        <View>
                            <View style={StylesConfig.styles.card.wrapperMt}>
                                <View style={StylesConfig.styles.card.header}>
                                    <Text style={StylesConfig.styles.card.header_text}>Pflichtangaben</Text>
                                </View>

                                <View style={StylesConfig.styles.card.body}>
                                
                                    <FloatingLabel
                                            controlId="floatingInput"
                                            label="Titel *"
                                            className="mb-3"
                                        >
                                        <input
                                            className='form-control'
                                            type={'text'} 
                                            value={getFormData('title')} 
                                            onChange={(e) => setFormData({...formData, title: e.target.value})} 
                                            />
                                    </FloatingLabel>
            
                                    <Row style={{marginBottom: 15}}>
                                        <Col>
                                            <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                                <Text>Startdatum: </Text>
                                                <DatePicker
                                                    style={{ width: '100%' }} 
                                                    value={
                                                        ((formData.start_date?.length > 3) 
                                                        ? Moment(formData.start_date + ' ' + formData.start_time.substr(0,5)).toDate()
                                                        : new Date())
                                                    } 
                                                    onChange={(value) => {
                                                        setFormData({...formData, start_date : Moment(value).format('YYYY-MM-DD')});
                                                    }} 
                                                    format="dd.MM.yyyy" />
                                            </HStack>
                                        </Col>
                                        
                                        <Col>
                                            <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                                <Text>Startzeit: </Text>
                                                <DatePicker
                                                    style={{ width: '100%' }} 
                                                    value={
                                                        ((formData.start_date?.length > 3) 
                                                        ? Moment(formData.start_date + ' ' + formData.start_time.substr(0,5)).toDate()
                                                        : new Date())
                                                    } 
                                                    onChange={(value) => {
                                                        setFormData({...formData, start_time : Moment(value).format('HH:mm')});
                                                    }} 
                                                    format="HH:mm" />
                                            </HStack>
                                        </Col>

                                        <Col>
                                            <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Dauer *"
                                                    className="mb-3"
                                                >
                                                <input
                                                    className='form-control'
                                                    type={'number'} 
                                                    value={formData?.duration || null} 
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                            value = value.replace(',', '.');
                                                        setFormData({...formData, duration: value});
                                                    }} 
                                                    />
                                            </FloatingLabel>
                                        </Col>

                                        
                                        {(formtype == 'tour' || formtype == 'quicktrip') && 
                                            <Col>
                                                <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Kilometer *"
                                                        className="mb-3"
                                                    >
                                                    <input
                                                        className='form-control'
                                                        type={'number'} 
                                                        value={formData?.km || null} 
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            value = value.replace(',', '.');
                                                            setFormData({...formData, km: value})
                                                        }} 
                                                        />
                                                </FloatingLabel>
                                                
                                            </Col>
                                        }
                                        
                                    </Row>
                                    
                                    {(formtype == 'tour' || formtype == 'quicktrip') && 
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Fahrstil *"
                                            className="mb-3"
                                            >
                                            <Form.Select 
                                                value={formData?.fahrstil}
                                                onChange={e => setFormData({...formData, fahrstil: e.target.value})}
                                                >
                                                <option value={''}>Bitte auswählen</option>
                                                {Object.keys(fields.fahrstil).map(item =>  {
                                                    return <option value={item}>{fields.fahrstil[item]}</option>
                                                })}
                                            </Form.Select>
                                        </FloatingLabel>
                                    }

                                    {formtype != 'quicktrip' && 
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Beschreibung"
                                            className="mb-3"
                                            >
                                            <textarea
                                                className='form-control'
                                                value={formData?.route}
                                                rows={3}
                                                style={{height: '100px'}}
                                                onChange={(e) => {
                                                    setFormData({...formData, route: e.target.value});
                                                }} 
                                            />
                                        </FloatingLabel>
                                    }

                                </View>
                                
                            </View>
                        </View>
                        
                        <HStack justifyContent={'space-between'} alignItems={'center'} style={{...StylesConfig.styles.card.header, marginTop: 30}}>
                            <Pressable onPress={() => setExtendedSettingsVisible(!extended_settings_visible)} flex={7}>
                                <View>
                                    <Text style={StylesConfig.styles.card.header_text}>Optionale Angaben</Text>
                                </View>
                            </Pressable>
                            <View flex={1}>
                                <Switch alignSelf={'flex-end'} size={'lg'} value={extended_settings_visible} onValueChange={(state) => setExtendedSettingsVisible(state)} />
                            </View>
                        </HStack>

                        <div style={{display: (extended_settings_visible) ? 'block' : 'none' }}>
                            
                            <View style={StylesConfig.styles.card.wrapper}>
                                <View style={StylesConfig.styles.card.body}>
                                        
                                    <Row>
                                        <Col>
                                            <Heading size={'xs'} color={colors.primary['700']} style={{textTransform: 'uppercase'}}>Einschränkungen</Heading>
                                                
                                            <Divider my={15}/>
                                            
                                            <HStack space={5} justifyContent={'space-between'} alignItems={'center'}>
                                                <Box>
                                                    <Text fontWeight={'bold'}>Only Girls</Text>
                                                    <Text fontSize={'xs'}>Nur für Frauen</Text>
                                                </Box>
                                                <Switch size="md" isChecked={formData.extend.onlyGirls} onToggle={(state) => {
                                                    setFormData({...formData, extend : {...formData.extend, onlyGirls: state}})
                                                }}/>
                                            </HStack>
                                            
                                            <View h={25} />
                                            
                                            <HStack space={5} justifyContent={'space-between'} alignItems={'center'}>
                                                <Box>
                                                    <Text fontWeight={'bold'}>Only Friends</Text>
                                                    <Text fontSize={'xs'}>Nur deine Freunde können es sehen und teilnehmen</Text>
                                                </Box>
                                                <Switch size="md" isChecked={formData.extend.onlyFriends} onToggle={(state) => {
                                                    setFormData({...formData, extend : {...formData.extend, onlyFriends: state}})
                                                }}/>
                                            </HStack>

                                            <View h={15} />

                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Max. Teilnehmer"
                                                className="mb-3"
                                                >
                                                <input
                                                    className='form-control'
                                                    type={'number'} 
                                                    value={getFormData('max_drivers')} 
                                                    onChange={(e) => setFormData({...formData, max_drivers: e.target.value})} 
                                                    />
                                            </FloatingLabel>
                                            
                                            
                                            {(formtype != 'event') && 
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Mindest Kubik"
                                                    className="mb-3"
                                                    >
                                                    <Form.Select 
                                                        value={formData?.min_ccm}
                                                        onChange={e => setFormData({...formData, min_ccm: e.target.value})}
                                                        >
                                                        <option value={''}>Bitte auswählen</option>
                                                        {Object.keys(fields.min_ccm).map(item =>  {
                                                            return <option value={item}>{fields.min_ccm[item]}</option>
                                                        })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            }
                                        </Col>
                                        
                                        <Col>
                                            <Heading size={'xs'} color={colors.primary['700']} style={{textTransform: 'uppercase'}}>Weitere Angaben</Heading>
                                                
                                            <Divider my={15}/>

                                            {(formtype != 'event') && 
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Kommunikations System"
                                                    className="mb-3"
                                                    >
                                                    <Form.Select 
                                                        value={formData?.communicationsystem}
                                                        onChange={e => setFormData({...formData, communicationsystem: e.target.value})}
                                                        >
                                                        <option value={''}>Bitte auswählen</option>
                                                        {Object.keys(fields.communicationsystem).map(item =>  {
                                                            return <option value={item}>{fields.communicationsystem[item]}</option>
                                                        })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            }
                                            

                                            {(formtype != 'event') && Object.keys(fields.users).length > 0 && 
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label={ formtype != 'training' ? 'Zweiter Guide' : 'Zweiter Trainer' }
                                                    className="mb-3"
                                                    >
                                                    <Form.Select 
                                                        value={formData?.sec_guide_id}
                                                        onChange={e => setFormData({...formData, sec_guide_id: e.target.value})}
                                                        >
                                                        <option value={''}>Bitte auswählen</option>
                                                        {Object.keys(fields.users).map(item =>  {
                                                            return <option value={item}>{fields.users[item]}</option>
                                                        })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            }

                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Teilnahmegebühr (in EUR)"
                                                className="mb-3"
                                                >
                                                <input
                                                    className='form-control'
                                                    type={'text'} 
                                                    value={getFormData('extend.price')} 
                                                    onChange={(e) => setFormData({...formData, extend: {...formData.extend, price:  e.target.value} })} 
                                                    />
                                            </FloatingLabel>


                                            {(formtype != 'event') && 
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label='Streckenart'
                                                    className="mb-3"
                                                    >
                                                    <Form.Select 
                                                        value={formData?.routetype}
                                                        onChange={e => setFormData({...formData, routetype: e.target.value})}
                                                        >
                                                        <option value={''}>Bitte auswählen</option>
                                                        {Object.keys(fields.routetype).map(item =>  {
                                                            return <option value={item}>{fields.routetype[item]}</option>
                                                        })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            }

                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Externer Link"
                                                className="mb-3"
                                                >
                                                <input
                                                    className='form-control'
                                                    type={'text'} 
                                                    value={getFormData('routelink')} 
                                                    onChange={(e) => setFormData({...formData, routelink: e.target.value })} 
                                                    />
                                            </FloatingLabel>
                                        </Col>

                                    </Row>
                                    
                                    <View h={30} />

                                    <Row>
                                        <Col>
                                            <Heading size={'xs'} color={colors.primary['700']} style={{textTransform: 'uppercase'}}>Vorschaubild</Heading>
                                            <Divider my={15}/>
                                            
                                            {(!image && !formData['image']) && 
                                                <Pressable onPress={() => imagePickerRef?.current.click()}>
                                                    <Box style={{width: '100%', height: 100 }} justifyContent={'center'} alignItems={'center'} borderWidth={1} borderColor={colors.muted['200']} bg={colors.muted['100']}>
                                                        <FontAwesomeIcon icon={'fa-regular fa-image'} size={'lg'}/>
                                                        <Text style={{marginTop: 10}}>Bild auswählen</Text>
                                                    </Box>
                                                </Pressable>
                                            }
                                            
                                            {(image && !formData['image']) && 
                                                <img id={'profileImage'} alt={'Profilbild'} style={{
                                                    width: 300,
                                                    height: 300,
                                                }} src={URL.createObjectURL(image)}/>
                                            }
                                            
                                            {(!image && formData['image']) && 
                                                <img id={'profileImage'} alt={'Profilbild'} style={{
                                                    width: 300,
                                                    height: 300,
                                                }} src={Config.uploadDir + 'routes/' + formData?.image}/>
                                            }
                                            
                                            {(image || formData['image']) &&
                                                <HStack space={5} style={{marginTop: 25}}>
                                                    
                                                    <View>
                                                        <Button onPress={() => {
                                                            setFormData({...formData, image : null});
                                                            setImage(null);
                                                        }} style={{backgroundColor: colors.danger['400']}}> Bild entfernen</Button>
                                                    </View>

                                                    <View>
                                                        <Button onPress={() => imagePickerRef?.current.click()} style={{backgroundColor: colors.primary['600']}}>Neues Bild</Button>
                                                    </View>
                                                        
                                                    
                                                </HStack>
                                            }

                                            <input type={'file'} ref={imagePickerRef} onChange={_handleImagePick} accept={"image/png, image/jpeg"} style={{display:'none'}}/>
                                        </Col>
                                        
                                        {(formtype == 'tour' || formtype == 'quicktrip') &&
                                            <Col>
                                                <Heading size={'xs'} color={colors.primary['700']} style={{textTransform: 'uppercase'}}>GPX Daten</Heading>
                                                <Divider my={15}/>

                                                    {(!gpx && !formData['gpx']) && 
                                                        <Pressable onPress={() => gpxPickerRef?.current.click()}>
                                                            <Box style={{width: '100%', height: 100 }} justifyContent={'center'} alignItems={'center'} borderWidth={1} borderColor={colors.muted['200']} bg={colors.muted['100']}>
                                                                <FontAwesomeIcon icon={'fa-regular fa-route'} size={'lg'}/>
                                                                <Text style={{marginTop: 10}}>GPX Daten auswählen</Text>
                                                            </Box>
                                                        </Pressable>
                                                    }

                                                    {(gpx || formData['gpx']) && 
                                                        <Box style={{width: '100%', height: 100 }} justifyContent={'center'} alignItems={'center'} borderWidth={1} borderColor={colors.muted['200']} bg={colors.muted['100']}>
                                                            <HStack>
                                                                <FontAwesomeIcon icon={'fa-regular fa-route'} size={'3x'}/>
                                                                <FontAwesomeIcon style={{marginLeft: 15}} color={colors.success['600']} icon={'fa-solid fa-check'} size={'lg'}/>
                                                            </HStack>
                                                        </Box>
                                                        
                                                    }
                                                
                                                
                                                {(gpx || formData['gpx']) && 
                                                    <HStack space={5} style={{marginTop: 25}}>    
                                                        <View>
                                                            <Button onPress={() => {
                                                                setFormData({...formData, gpx : null});
                                                                setGpx(null);
                                                            }} style={{backgroundColor: colors.danger['400']}}> GPX Daten entfernen</Button>
                                                        </View>

                                                        <View>
                                                            <Button onPress={() => gpxPickerRef?.current.click()} style={{backgroundColor: colors.primary['600']}}>Neue Daten auswählen</Button>
                                                        </View>
                                                        
                                                    </HStack>
                                                }

                                                <input type={'file'} ref={gpxPickerRef} onChange={_handleGpxPick} style={{display:'none'}}/>
                                            </Col>
                                        }
                                    </Row>

                                </View>
                            
                            </View>

                        </div>
                        
                        <HStack space={5} mt={5} p={3}>
                            <Flex flex={1}>
                                <Button variant="subtle" colorScheme="muted" onPress={() => {
                                    setStep(step - 1);
                                }}>Zurück</Button>
                            </Flex>
                            <Flex flex={1}>
                                {submitting === false && 
                                    <Button onPress={() => {
                                        
                                        let checkRequieredFields = ["title", "start_date", "start_time"];
                                        
                                        if (formtype == 'quicktrip') {
                                            checkRequieredFields = [...checkRequieredFields, "fahrstil", "duration",  "km"];
                                        }

                                        if (formtype == 'tour') {
                                            checkRequieredFields = [...checkRequieredFields, "fahrstil", "duration",  "km"];
                                        }

                                        if (formtype == 'training') {
                                            checkRequieredFields = [...checkRequieredFields, "duration"];
                                        }

                                        if (formtype == 'event') {
                                            checkRequieredFields = [...checkRequieredFields, "duration"];
                                        }
                                        
                                        if (_checkInput(checkRequieredFields, true)) {
                                            setSubmitting(true);
                                            submitForm();
                                        }
                                    }}>Speichern</Button>
                                }

                                {submitting &&
                                    <Button isLoading>Wird gespeichert</Button>
                                }
                            </Flex>
                        </HStack>
                    </>    
                }


            </View>
                
                
        </>
    );
};

export default TourForm;
