import React, {useEffect, useState} from 'react';
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import {Box, Badge, HStack, useTheme, View, FlatList, Button, VStack, Text, Spinner, Stack, Input, ScrollView, Pressable} from 'native-base';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

//Helpers
import { useSelector } from 'react-redux';
import { simpleFormData } from '~/Helper/FormHelper';
import Loader from '~/Components/Template/Loader';

//View Components
import TourItem from '~/Components/Touren/List/Item';
import { AdMobBanner } from '~/Components/AdMobs';

//Filter Elements
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { DateRangePicker } from 'rsuite';

const TourList = ({key, listViewtype}) => {
    Moment.locale('de');
    
    const currentUser = useSelector((state) => state.user);
    const {colors} = useTheme();
    const [items, setItems] = React.useState([]);
    const [baseItems, setBaseItems] = React.useState([]);
    const [query, setQuery] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const isFocused = true;
    const searchBar = React.useRef(null);
    const params =  useParams();

    let limit = 25;
    
    const [page, setPage] = React.useState(0);
    const [endReached, setEndReached] = React.useState(false);
    
    const defaultFilter = React.useRef({});

    const [filter, setFilter] = React.useState(null);
    const [filterData, setFilterData] = React.useState({});
    const [filterVisible, setFilterVisible] = React.useState(false);
    const gMapsPlaces = React.useRef();
    const now = new Date();
    const _fields = {
        'tourtype' : [
            'Alle', 'Tour', 'Kurztrip', 'Training', 'Event'
        ],
        'fahrstil' : [
            'Alle', 'gemütlich (StVO)', 'normal (StVO +10 km/h)', 'zügig (StVO +20 km/h)',  'schnell (kein StVO)'
        ],
        'umkreis' : [
            'Egal', 'bis 25 km', 'bis 50 km', 'bis 75 km', 'bis 100 km', 'bis 150 km', 'bis 200 km', 'bis 250 km', 'bis 300 km'
        ]
    };
  
    const [triggerLoad, setTriggerLoad] = React.useState(false);
    const [triggerReload, setTriggerReload] = React.useState(false);
    
    const [scrollPos, setScrollPos] = React.useState(0);

    /** *******************************************************************
     *  FUNCTIONS
     */
    React.useEffect(() => {
        if (scrollPos != 0 
            && scrollPos >= ((document.body.scrollHeight - document.documentElement.clientHeight) * 0.7)) 
        {
            _onEndReached();
        }
        
    }, [scrollPos])

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    /**
     * Handle fetch Scrollposition
    */
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPos(position);
    };

    React.useEffect(() => {
        if (!_.isEmpty(currentUser)) {
            defaultFilter.current = {
                'date_from' : Moment().format('YYYY-MM-DD'),
                'date_to': Moment().add(90, 'days').format('YYYY-MM-DD'),
                'umkreis': 'bis 150 km',
                'start_location' : 'Dein Standort',
                'start_location_latlng' : `${currentUser.data.lat};${currentUser.data.lng}`
            };
            
            setFilter(defaultFilter.current);
            setFilterData(defaultFilter.current);
            setPage(0);
            setItems([]);
            setBaseItems([]);
            setTriggerReload(true);
        }
    
    }, [currentUser]);

    /**
     * Load and append Items to List
    */
    React.useEffect(() => {
        if ((triggerLoad && !triggerReload) || (!triggerLoad && triggerReload) ) {
            
            _fetchTours(listViewtype);

            if (triggerLoad) {
                setTriggerLoad(false);
            }

            if (triggerReload) {
                setTriggerReload(false);
            }

        }

    }, [triggerLoad, triggerReload])
   
    
    /**
     * Keep data and load new tours
     */
    const _handleLoad = () => {
        setPage(page+1);
        
        if (triggerLoad) {
            _fetchTours(listViewtype);
        } else {
            setTriggerLoad(true);
        }
    }

    /**
     * Handle: Reload Data List
     * @param resetFilter   
     *          Set true if filter is relevant for request
     */
    const _handleReload = (resetFilter, extendData = {}) => {
        
        setItems([]);
        setBaseItems([]);
        setPage(0);
        setQuery('');
        
        if (resetFilter == true) {
            setFilter({...defaultFilter?.current});
        } else if (resetFilter == 'extend') {
            setFilter({...defaultFilter?.current, ...filter, ...extendData});
        } else {
            setFilter({...defaultFilter?.current, ...filter});
        }

        setFilterVisible(false);
        setEndReached(false);
        setRefreshing(true);
        
        setTriggerReload(true);
    
    }

    /**
     * Handle: Searchbar Input
    */
    const _handleSearch = (text) => {
        
        setQuery(text);
        if (text != '') {
            setEndReached(true);
        } else {
            setEndReached(false);
        }
        
        let formattedQuery = text.toLowerCase();
        let filteredData = _.filter(baseItems, tour => _contain(tour, formattedQuery));
        
        if (filteredData.length > 0) {
            filteredData = _.orderBy([...filteredData], ['sorter'], ['asc']);
        }
        
        setItems([...filteredData]);
    }

    const _contain = ({ title }, query) => {
        return (title.toLowerCase().includes(query)) ? true : false;
    }
    
    const _handleSelect = (selection) => {
        geocodeByPlaceId(selection?.value?.place_id)
        .then(result => {
            setFilterData({
                ...filterData, 
                'start_location_latlng' : `${result[0].geometry.location.lat()};${result[0].geometry.location.lng()}`,
                'start_location' : result[0].formatted_address
            })
        });
        
    }

    /**
     * Handle: Set Filter
     */
    const _handleSetFilter = () => {
        setFilterVisible(false);
        _handleReload('extend', filterData);
    }
    
    /**
     * Handle: Unset Filter
     * Hide filter and reload list based on given filters
     */
    const _handleUnsetFilter = () => {
        setFilterData({...filter});
        setFilterVisible(false);
    }

    const _useCurrentLocation = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                
                setFilterData({
                    ...filterData, 
                    'start_location_latlng' : `${position.coords.latitude};${position.coords.longitude}`,
                    'start_location' : 'Aktuelle Position'
                })
            }, function(){}, {enableHighAccuracy : true, timeout: 5000, maximumAge: 500});
        }
    };
 
    const _fetchTours = async (fetchedViewtype) => {
        
        if (filter !== null) {
            setEndReached(false);
            if (!_.isEmpty(currentUser)) {
                await fetch(Config.host + '/api/touren/collection/view/', {
                    method: 'POST',
                    headers: {
                        ...Config.defaultHeaders,
                        'token': currentUser.token,
                        'X-CSRF-TOKEN': currentUser.csrf.hash
                    },
                    body: simpleFormData({
                        'filter': JSON.stringify({
                            ...filter, 
                            "limit" : limit, 
                            "page": page
                        }),
                        'view' : fetchedViewtype
                    })
                })
                .then((res) => res.json())
                .then((json) => {
                    
                    if (json.length == 0) {
                        setEndReached(true);
                    } else {
                        let itemsToAdd = [];
                        json.map((item) => {
                            if (_.filter(baseItems, (_item) => _item.tour_id != item.tour_id)) {
                                itemsToAdd.push(item);
                            }
                        })
                        
                        let reorderedBaseItems = _.orderBy([...baseItems, ...itemsToAdd], ['sorter'], ['asc']);
                        let reorderedItems = _.orderBy([...items, ...itemsToAdd], ['sorter'], ['asc']);
                        
                        setBaseItems([...reorderedBaseItems]);
                        setItems([...reorderedItems]);
                        setQuery('');
                    
                    }
                            
                    setLoading(false);
                    setRefreshing(false);
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        }
    };
    

    
    /** *******************************************************************
     *  Components
     */
    const ActiveFilters = () => {
        let itemsAct = [];
        let i = 0;

        itemsAct.push(<Text style={{fontWeight: 'bold', lineHeight:25}}>Filter: </Text>);
        
        if (filter) {
            Object.keys(filter).map((item, index) => {
                if (filter[item] != null) {
                    let postedValue = null;
                    
                    if (item == 'start_location_latlng') {
                    
                    } else if (item == 'date_from' || item == 'date_to') {
                        postedValue = ((item == 'date_from') ? 'Vom: ' : 'Bis: ') + Moment(filter[item]).format('LL');
                    } else {
                        postedValue = filter[item];
                    }

                    if (postedValue !== null) {
                        itemsAct.push(<Badge key={listViewtype + '_' + index} containerStyle={{borderWidth: 0}} backgroundColor={colors.muted['200']}>{postedValue}</Badge>);
                    }
                }
            });
        }

        if (itemsAct.length > 0) {
            return <View height={45}><ScrollView horizontal={true} showsHorizontalScrollIndicator={false}><Pressable onPress={() => {
                setFilterVisible(true)
            }}><HStack m={3} space={1}>{itemsAct}</HStack></Pressable></ScrollView></View>;
        }
    };

    const Filter = () => (filterVisible) 
        ? <FilterBody />
        : <ActiveFilters />;
    
    const FilterBody = () => {
        
        return (
            <View p={3} rounded={'lg'} mt={3} bgColor={'#FFFFFF'} mb={3}>
                <Box mt={5} pb={2} w='100%'>
                    <DateRangePicker
                        size="lg"
                        placeholder="Datum von bis"
                        character={' bis '}
                        editable={false}
                        locale={{
                            sunday: 'So',
                            monday: 'Mo',
                            tuesday: 'Di',
                            wednesday: 'Mi',
                            thursday: 'Do',
                            friday: 'Fr',
                            saturday: 'Sa',
                            ok: 'Anwenden',
                            today: 'Heute',
                            yesterday: 'Gestern',
                            hours: 'Stunden',
                            minutes: 'Minuten',
                            seconds: 'Sekunden',
                            
                        }}          
                        format='dd.MM.yyyy'
                        
                        defaultValue={[((filterData?.date_from) ? new Date(filterData.date_from) : null), ((filterData?.date_to) ? new Date(filterData.date_to) : null)]}
                        onChange={item => {
                            if (item.length == 2) {
                                setFilterData({
                                    ...filterData, 
                                    date_from: Moment(item[0]).format('YYYY-MM-DD'), 
                                    date_to: Moment(item[1]).format('YYYY-MM-DD')
                                });
                            }
                        }}
                        ranges={[
                            {label: 'Heute', value: [Moment().toDate(), Moment().toDate()], placement: 'left'},
                            {label: 'Morgen', value: [Moment().add(1, 'day').toDate(), Moment().add(1, 'day').toDate()], placement: 'left'},
                            {label: '7-Tage', value: [Moment().toDate(), Moment().add(7, 'day').toDate()], placement: 'left'},
                            {label: '14-Tage', value: [Moment().toDate(), Moment().add(14, 'day').toDate()], placement: 'left'},
                            {label: '30-Tage', value: [Moment().toDate(), Moment().add(30, 'day').toDate()], placement: 'left'},
                        ]}
                        
                        /*ranges={[{
                            startDate: ((filterData?.date_from) ? new Date(filterData.date_from) : null),
                            endDate: ((filterData?.date_to) ? new Date(filterData.date_to) : null),
                            key : 'selection'
                        }]}*/ 
                    />
                </Box>
                
                <View style={{height: 20}}/>
                
                <VStack space={2} style={{marginBottom: 15}}>
                    <HStack space={2}>
                        <GooglePlacesAutocomplete
                            style={{flex:1}}
                            selectProps={{
                                value: filterData?.start_location,
                                placeholder: 'Adresse eingeben...',
                                onChange: _handleSelect,
                                styles : {
                                    container : () => ({
                                        flex: 1, 
                                        width: '100%',
                                    })
                                }
                            }}
                            
                            apiOptions={{ language: 'de', region: 'de' }}
                            apiKey={Config.googleApiToken} />

                        <Pressable onPress={() => {
                            _useCurrentLocation();
                        }}>
                            <Box rounded="md" bg={'muted.200'} p={3} justifyContent={'center'} alignItems={'center'}>
                                <FontAwesomeIcon icon="fa-solid fa-location-crosshairs" />
                            </Box>
                        </Pressable>
                    </HStack>
                    {filterData?.start_location && 
                        <Text fontSize={12}>Aktuelle Auswahl: <Text fontWeight={'bold'}>{filterData?.start_location}</Text></Text>
                    }
                </VStack>
                
                <FloatingLabel
                    controlId="floatingInput"
                    label="Umkreis"
                    className="mb-3"
                    >
                    <Form.Select 
                        value={filterData?.umkreis}
                        onChange={(e) =>  setFilterData({...filterData, umkreis: e.target.value})}
                        >
                        {_fields.umkreis.map((item, index) => {
                            return <option value={item}>{item}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>
                
                <FloatingLabel
                    controlId="floatingInput"
                    label="Tour-Art"
                    className="mb-3"
                    >
                    <Form.Select 
                        value={filterData?.tourtype}
                        onChange={(e) =>  setFilterData({...filterData, tourtype: e.target.value})}
                        >
                        {_fields.tourtype.map((item, index) => {
                            return <option value={item}>{item}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>
                
                <FloatingLabel
                    controlId="floatingInput"
                    label="Fahrstil"
                    className="mb-3"
                    >
                    <Form.Select 
                        value={filterData?.fahrstil}
                        onChange={(e) =>  setFilterData({...filterData, fahrstil: e.target.value})}
                        >
                        {_fields.fahrstil.map((item, index) => {
                            return <option value={item}>{item}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>

                <HStack space={3}>
                    <Button variant={'ghost'} flex="1" onPress={_handleUnsetFilter}>
                        Abbrechen
                    </Button>
                    <Button flex="1" onPress={_handleSetFilter}>
                        Filter anwenden
                    </Button>

                </HStack>
            </View>
        );

    }
   
    /**
     * List Elements
    */
    const _onEndReached = () => {
        if (!endReached && query == '') {
            _handleLoad();
        }
    }
    const _renderFooter = () => {
        return (
            <>
                {(!endReached) 
                    ? (<>
                        {scrollPos > 0 && <Spinner m={15} mb={250} size={'lg'} />}
                        
                    </>) 
                    : (<>
                        <Box _text={{textAlign:'center', textTransform: 'uppercase', color: colors.muted['400']}} style={{margin: 15, marginTop: 10, padding: 15, marginBottom: 51}}>Keine weiteren Einträge</Box>
                    </>
                    )
                }
                
            </>);
    }
    
    
    return (
        <div>

            <Loader loading={loading} />
            
            <View>
                {!filterVisible && 
                    <Stack p={5} pb={0} pl={2}>
                        <Input 
                            w={'100%'}
                            ref={searchBar}
                            autoCapitalize='none'
                            clearButtonMode='always'
                            autoCorrect={false}
                            value={query}  
                            onChangeText={_handleSearch} 
                            placeholder={'Suche ...'} 
                        />
                    </Stack>
                }
                <Filter />
            </View>
            
            <FlatList 
                data={items}
                initialNumToRender={15}
                maxToRenderPerBatch={15}
                keyboardDismissMode={'on-drag'}
                getItemLayout={(data, index) => ({
                    length : 220, offset : 220 * (index+1), index
                })}
                ListFooterComponent={_renderFooter}
                /*onEndReached = {({ distanceFromEnd }) => {
                    _onEndReached();
                }}
                onEndReachedThreshold={0.3}*/
                keyExtractor={(item, index) => 'tour_' + listViewtype + '_' + item.tour_id} 
                renderItem={({item, index}) => {
                    return <>
                        {(index == 0 || index%5 == 0) && <AdMobBanner size={'LARGE_BANNER'} position={'touren_1'} />}
                        <TourItem key={'tour_' + listViewtype + '_' + item.tour_id} viewtype={listViewtype} distance={item?.distance} tour_id={item.tour_id} />
                    </>;
                }}
            />
            
            
        
        </div>
      
    );
  

};


export default TourList;