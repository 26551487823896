import React, {useRef} from 'react';
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import {Box, Badge, HStack, Flex, FlatList, Input, Stack, useTheme, View, Button, VStack, Text, Spinner, InputGroup, ScrollView, Pressable} from 'native-base';
import _ from 'lodash';

//Helpers
import { useSelector } from 'react-redux';
import Loader from '~/Components/Template/Loader';
import UserItem from '~/Components/Users/List/Item';
import {simpleFormData} from '~/Helper/FormHelper';
import {wait} from '~/Helper/Base';

import useBreakpoint from 'bootstrap-5-breakpoint-react-hook';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

//Filter Elements
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';


const UsersList = ({...props}) => {
    const currentUser = useSelector((state) => state.user);
    const {colors} = useTheme();
    const [items, setItems] = React.useState([]);
    const [baseItems, setBaseItems] = React.useState([]);
    const [query, setQuery] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const searchBar = React.useRef(null);
    const [columns, setColumns] = React.useState(1);
    const breakpoint = useBreakpoint();
    const [scrollPos, setScrollPos] = React.useState(0);

    let limit = 100;
    const [page, setPage] = React.useState(0);
    const [endReached, setEndReached] = React.useState(false);
    
    const defaultFilter = React.useRef({});

    const [filter, setFilter] = React.useState(null);
    const [filterData, setFilterData] = React.useState({});
    const [filterVisible, setFilterVisible] = React.useState(false);
    const gMapsPlaces = React.useRef();
    const now = new Date();
    
    const _fields = {
        'gender' : [
            'Alle', 'männlich', 'weiblich', 'divers'
        ],
        'relationship' : [
            'Alle', 'Ledig', 'In Beziehung', 'Verheiratet',  'Es ist kompliziert'
        ],
        'umkreis' : [
            'Egal', 'bis 5 km', 'bis 10 km', 'bis 25 km', 'bis 50 km', 'bis 75 km', 'bis 100 km', 'bis 150 km', 'bis 200 km', 'bis 250 km', 'bis 300 km'
        ]
    };

    const [triggerLoad, setTriggerLoad] = React.useState(false);
    const [triggerReload, setTriggerReload] = React.useState(false);

    /** *******************************************************************
     *  EFFECTS
     */
    
    React.useEffect(() => {
        if (scrollPos != 0 
            && scrollPos >= ((document.body.scrollHeight - document.documentElement.clientHeight) * 0.7)) 
        {
            _onEndReached();
        }
        
    }, [scrollPos])

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    /**
     * Handle fetch Scrollposition
    */
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPos(position);
    };

    React.useEffect(() => {
        setColumns((breakpoint < 400) ? 1 : 3);
    }, [])

    React.useEffect(() => {
            
        if (!_.isEmpty(currentUser)) {
            defaultFilter.current = {
                'umkreis': 'bis 50 km',
                'start_location' : 'Dein Standort',
                'start_location_latlng' : `${currentUser.data.lat};${currentUser.data.lng}`
            };

            setFilter(defaultFilter.current);
            setFilterData(defaultFilter.current);
            setTriggerReload(true);
        }
    
    }, [currentUser]);

    
    /**
     * Load and append Items to List
    */
    React.useEffect(() => {
        if (((triggerLoad && !triggerReload) || (!triggerLoad && triggerReload)) ) {
            if (triggerLoad) {
                setTriggerLoad(false);
            }

            if (triggerReload) {
                setTriggerReload(false);
            }

            _fetchList(props.viewtype);            
        }
    }, [triggerLoad, triggerReload])


    /** *******************************************************************
     *  FUNCTIONS
     */
    
    /**
     * Keep data and load new tours
     */
    const _handleLoad = () => {
        setPage(page+1);
        setTriggerLoad(true);
    }

    /**
     * Handle: Reload Data List
     * @param resetFilter   
     *          Set true if filter is relevant for request
     */
    const _handleReload = (resetFilter, extendData = {}) => {

        setItems([]);
        setPage(0);
        setQuery('');
        
        if (resetFilter == true) {
            setFilter({...defaultFilter?.current});
        } else if (resetFilter == 'extend') {
            setFilter({...defaultFilter?.current, ...filter, ...extendData});
        } else {
            setFilter({...defaultFilter?.current, ...filter});
        }

        setFilterVisible(false);
        setEndReached(false);
        setRefreshing(true);
        
        setTriggerReload(true);
    
    }

    /**
     * Handle: Searchbar Input
    */
    const _handleSearch = (text) => {
        setQuery(text);
        setEndReached(true);

        let formattedQuery = text.toLowerCase();
        let filteredData = _.filter(baseItems, user => _contain(user, formattedQuery));
        
        setItems([...filteredData]);
    }

    const _contain = ({bikes, city, country, email, name, lastname, fullname, outputname}, query) => {
        if (
            JSON.stringify(bikes).toLowerCase().includes(query)
            || city.toLowerCase().includes(query)
            || country.toLowerCase().includes(query)
            || email.toLowerCase().includes(query)
            || name.toLowerCase().includes(query)
            || lastname.toLowerCase().includes(query)
            || fullname.toLowerCase().includes(query)
            || outputname.toLowerCase().includes(query)
        ) {
            return true
        }

        return false
    }
    
    
    /**
     * Handle: Set Filter
     */
    const _handleSetFilter = () => {
        setFilterVisible(false);
        _handleReload('extend', filterData);
    }
    
    /**
     * Handle: Unset Filter
     * Hide filter and reload list based on given filters
     */
    const _handleUnsetFilter = () => {
        setFilterData({...filter});
        setFilterVisible(false);
    }

    const _useCurrentLocation = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                
                setFilterData({
                    ...filterData, 
                    'start_location_latlng' : `${position.coords.latitude};${position.coords.longitude}`,
                    'start_location' : 'Aktuelle Position'
                })
            }, function(){}, {enableHighAccuracy : true, timeout: 5000, maximumAge: 500});
        }
    };


    const _fetchList = async () => {
        
        if (filter !== null) {
            if (!_.isEmpty(currentUser)) {
                fetch(Config.host + '/api/user/collection/', {
                    method: 'POST',
                    headers: {
                        ...Config.defaultHeaders,
                        'token': currentUser.token,
                        'X-CSRF-TOKEN': currentUser.csrf.hash
                    },
                    body: simpleFormData({
                        'filter': JSON.stringify({
                            ...filter,
                            "limit" : limit, 
                            "page": page
                        })
                    })
                })
                .then((res) => res.json())
                .then((json) => {
                    if (json.length == 0) {
                        setEndReached(true);
                    } else {
                        let itemsToAdd = [];

                        json.map((item) => {
                            if (_.filter(baseItems, _item => _item.id != item.id)) {
                                itemsToAdd.push(item);
                            }
                            return;
                        })
                        
                        setBaseItems([...baseItems, ...itemsToAdd]);
                        setItems([...items, ...itemsToAdd]);
                        setQuery('');
                    }
                            
                    setLoading(false);
                    setRefreshing(false);
                })
                .catch((error) => {
                    console.error(error);
                });;
            };
        }
    };
  
    /** *******************************************************************
     *  Components
     */

    const ActiveFilters = () => {
        let itemsAct = [];
        let i = 0;

        itemsAct.push(<Text style={{fontWeight: 'bold', lineHeight:25}}>Filter: </Text>);
        
        if (filter) {
            Object.keys(filter).map((item, index) => {
                if (filter[item] != null) {
                    let postedValue = null;
                    
                    if (item == 'start_location_latlng') {
                    
                    } else {
                        postedValue = filter[item];
                    }

                    if (postedValue !== null) {
                        itemsAct.push(<Badge key={props.viewtype + '_' + index} containerStyle={{borderWidth: 0}} backgroundColor={colors.muted['200']}>{postedValue}</Badge>);
                    }
                }
                return;
            });
        }

        if (itemsAct.length > 0) {
            return <View height={45}><ScrollView horizontal={true} showsHorizontalScrollIndicator={false}><Pressable onPress={() => {
                setFilterVisible(true)
            }}><HStack m={3} space={1}>{itemsAct}</HStack></Pressable></ScrollView></View>;
        }
    };

    const Filter = () => (filterVisible) 
        ? <FilterBody />
        : <ActiveFilters />;
    
    const _handleSelect = (selection) => {
        geocodeByPlaceId(selection?.value?.place_id)
        .then(result => {
            setFilterData({
                ...filterData, 
                'start_location_latlng' : `${result[0].geometry.location.lat()};${result[0].geometry.location.lng()}`,
                'start_location' : result[0].formatted_address
            })
        });
        
    }

    const FilterBody = () => {

        return (
            <View m={3} rounded={'lg'} mt={3} p={3} bgColor={'#FFFFFF'} mb={3}>
                {filter != null && 
                    <>
                        <VStack space={2} style={{marginBottom: 15}}  zIndex={999}>
                            <HStack space={2}>
                                <GooglePlacesAutocomplete
                                    style={{flex:1}}
                                    selectProps={{
                                        value: filterData?.start_location,
                                        placeholder: 'Adresse eingeben...',
                                        onChange: _handleSelect,
                                        styles : {
                                            container : () => ({
                                                flex: 1, 
                                                width: '100%',
                                            })
                                        }
                                    }}
                                    
                                    apiOptions={{ language: 'de', region: 'de' }}
                                    apiKey={Config.googleApiToken} />

                                <Pressable onPress={() => {
                                    _useCurrentLocation();
                                }}>
                                    <Box rounded="md" bg={'muted.200'} p={3} justifyContent={'center'} alignItems={'center'}>
                                        <FontAwesomeIcon icon="fa-solid fa-location-crosshairs" />
                                    </Box>
                                </Pressable>
                            </HStack>
                            {filterData?.start_location && 
                                <Text fontSize={12}>Aktuelle Auswahl: <Text fontWeight={'bold'}>{filterData?.start_location}</Text></Text>
                            }
                            
                        </VStack>
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Umkreis wählen"
                            className="mb-3"
                            >
                            <Form.Select 
                                value={filterData?.umkreis}
                                onChange={(e) =>  setFilterData({...filterData, umkreis: e.target.value})}
                                >
                                {_fields.umkreis.map((item, index) => {
                                    return <option value={item}>{item}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Geschlecht"
                            className="mb-3"
                            >
                            <Form.Select 
                                value={filterData?.gender}
                                onChange={(e) =>  setFilterData({...filterData, gender: e.target.value})}
                                >
                                {_fields.gender.map((item, index) => {
                                    return <option value={item}>{item}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>
                        
                        {/*<FloatingLabel
                            controlId="floatingInput"
                            label="Beziehungssta"
                            className="mb-3"
                            >
                            <Form.Select 
                                value={filterData?.relationship}
                                onChange={(e) =>  setFilterData({...filterData, relationship: e.target.value})}
                                >
                                {_fields.relationship.map((item, index) => {
                                    return <option value={item}>{item}</option>
                                })}
                            </Form.Select>
                            </FloatingLabel>*/}

                    </>    
                }

                <HStack justifyContent={'flex-end'} space={3}>
                    <Button variant={'ghost'}  onPress={_handleUnsetFilter}>
                        Abbrechen
                    </Button>
                    <Button  onPress={_handleSetFilter}>
                        Filter anwenden
                    </Button>

                </HStack>
            </View>
        );

    }

    /**
     * List Elements
    */
    const _onEndReached = () => {
        if (!endReached && query == '') {
            _handleLoad();
        }
    }
    const _renderFooter = () => {
        return (<>
                {(!endReached) 
                    ? (<>
                        {scrollPos > 0 && <Spinner m={15} mb={150} size={'lg'} />}
                    </>) 
                    : (<>
                        <Box _text={{textAlign:'center', textTransform: 'uppercase', color: colors.muted['400']}} style={{margin: 15, marginTop: 10, padding: 15, marginBottom: 150}}>Keine weiteren Einträge</Box>
                    </>
                    )
                }
                
            </>);
    }
    
    return (
        <>
            <Loader loading={loading} />
            
                <View>
                    {!filterVisible && 
                        <Stack p={5} pb={0} pl={2}>
                            <InputGroup w={'100%'}>
                                <Input 
                                    w={'100%'}
                                    inputMode={'search'}
                                    ref={searchBar}
                                    autoCapitalize='none'
                                    clearButtonMode='always'
                                    autoCorrect={false}
                                    value={query}
                                    onChangeText={_handleSearch} 
                                    placeholder={'Suche ...'} 
                                />
                                
                            </InputGroup>
                        </Stack>
                    }
                    <Filter />
                </View>
            
            {columns === 3 &&
                <FlatList 
                    key={'_'}
                    data={items}
                    numColumns={3}
                    columnWrapperStyle={{
                        
                    }}
                    initialNumToRender={limit}
                    maxToRenderPerBatch={10}
                    keyboardDismissMode={'on-drag'}
                    getItemLayout={(data, index) => ({
                        length : 150, offset : 150 * index, index
                    })}
                    contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}}
                    ListFooterComponent={_renderFooter}
                    /*onEndReached = {_onEndReached}
                    onEndReachedThreshold={0.3}*/
                    keyExtractor={(item) => 'u' + item.id} 
                    renderItem={({item}) => <View w={'33.333333%'}><UserItem key={'u' + item.id} user={item} /></View>}
                />
            }
            
            {columns === 1 &&
                <FlatList 
                    key={'#'}
                    data={items}
                    
                    initialNumToRender={limit}
                    maxToRenderPerBatch={10}
                    keyboardDismissMode={'on-drag'}
                    getItemLayout={(data, index) => ({
                        length : 150, offset : 150 * index, index
                    })}
                    contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}}
                    ListFooterComponent={_renderFooter}
                    /*onEndReached = {_onEndReached}
                    onEndReachedThreshold={0.3}*/
                    keyExtractor={(item) => 'u' + item.id} 
                    renderItem={({item}) => <UserItem key={'u' + item.id} user={item} />}
                />
            }
            
        </>
        
        );
    

    };


export default UsersList;