import {useState, useEffect} from 'react';
import { HStack, Button, Spinner, Heading} from 'native-base';
import Config from '~/Config/BaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {toasty} from '~/Helper/Base';

const UserInteractionBar = ({user}) => {
    
    const currentUser = useSelector((state) => state.user);

    const [hasFriendship, setHasFriendship] = useState(-1);
    const [isRequester, setIsRequester] = useState(null);
    const SweetAlert = withReactContent(Swal);

    const _hasFriendship = async (leftId) => {
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/friends/has/' + currentUser.data.id + '/', {
                method: 'POST',
                headers: {
                    ...Config.defaultHeaders,
                    'token': currentUser.token, 
                    'X-CSRF-TOKEN': currentUser.csrf.hash 
                },
                body : simpleFormData({'receiver_id' : leftId})
            })
            .then((res) => res.json())
            .then((json) => {
                if (json == false) {
                    setHasFriendship(0);
                } else if (json == 1) {
                    setHasFriendship(1);
                } else if (json == 2) {
                    setHasFriendship(2);
                }
                _fetchIsRequester(leftId);
            });
        };
    }

    const _fetchIsRequester = async (leftId) => {
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/friends/isReceiver/' + currentUser.data.id + '/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({'receiver_id' : leftId})
            })
            .then((res) => res.json())
            .then((json) => {
                if (json == 1) {
                    setIsRequester(true);
                } else {
                    setIsRequester(false);
                }
            });
        };
    }

    const _addFriend = async () => {
        setHasFriendship(-1);
        
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/friends/add/' + currentUser.data.id + '/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({'receiver_id' : user.id})
            })
        };

        _hasFriendship(user.id);
        setIsRequester(true);
    }

    const _removeFriend = async () => {
        SweetAlert.fire({
            'text' : `Möchtest du ${user.fullname} wirklich als Freund entfernen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, entfernen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                if (!_.isEmpty(currentUser)) {
                    fetch(Config.host + '/api/user/action/friends/delete/' + currentUser.data.id + '/', {
                        method: 'POST',
                        headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                        body : simpleFormData({'receiver_id' : user.id})
                    }).then((response) => {
                        _hasFriendship(user.id);
                        toasty('Eintrag gelöscht', 'success');
                    });
                } else {
                    toasty('Eintrag konnte nicht gelöscht werden', 'error');
                }
            }
        });

    }

    const _approveFriend = async () => {
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/friends/approve/' + currentUser.data.id + '/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'receiver_id' : user.id})
            })
            .then(() => {
                _hasFriendship(user.id);
            })
        };
    }

    useEffect(() => {
        _hasFriendship(user.id);
    }, [hasFriendship]);

    return (
        <HStack space={3} alignItems={'flex-start'} flexDirection={'row'} flexWrap={'wrap'}>
            
            {(hasFriendship === -1) &&
                
                <HStack w={'100%'} justifyContent={'center'} alignItems={'center'}>
                    <Spinner accessibilityLabel="Loading posts" />
                    <Heading ml={3} color="primary.500" fontSize="md">
                         laden ...
                    </Heading>
                </HStack>
                
            }

            {(hasFriendship === 0) &&
                
                <Button style={{flexGrow: 1}} onPress={() => {_addFriend()}} leftIcon={
                    <FontAwesomeIcon color={'white'} size={'lg'} icon={'fa-solid fa-user-plus'} />
                } 
                borderRadius={0} 
                size="sm">Als Freund hinzufügen</Button>
                
            }
            
            {(hasFriendship === 2 && isRequester == true) && 
                <>
                    <Button style={{flexGrow: 2}} isLoading isLoadingText={'Anfrage ausstehend'} borderRadius={0} size="sm" />
                    
                    <Button style={{flexGrow: 2}} onPress={() => { _removeFriend()}} colorScheme="danger" leftIcon={
                        <FontAwesomeIcon color={'white'} size={'lg'} icon={'fa-solid fa-times'} />
                    } 
                    borderRadius={0} 
                    size="sm">Anfrage abbrechen</Button>
                
                </>
            }

            {(hasFriendship === 2 && isRequester == false) && 
                <>
                    <Button style={{flexGrow: 2}} onPress={() => { _approveFriend()}} colorScheme="success" leftIcon={
                        <FontAwesomeIcon color={'white'} size={'lg'} icon={'fa-solid fa-check'} />
                    } 
                    borderRadius={0} 
                    size="sm">Anfrage bestätigen</Button>
                    
                    <Button style={{flexGrow: 2}} onPress={() => { _removeFriend()}} colorScheme="danger" leftIcon={
                        <FontAwesomeIcon color={'white'} size={'lg'} icon={'fa-solid fa-times'} />
                    } 
                    borderRadius={0} 
                    size="sm">Anfrage ablehnen</Button>
                
                </>
            }   

            {(hasFriendship === 1) &&
                <>
                <Button style={{flexGrow: 1}} onPress={() => { _removeFriend()}} bg="blueGray.400" leftIcon={
                    <FontAwesomeIcon color={'white'} size={'lg'} icon={'fa-solid fa-check'} />
                } 
                borderRadius={0} 
                size="sm">Befreundet</Button>
                </>
            }
            
            
        </HStack>
    );

};


export default UserInteractionBar;