import React from 'react';
import { View, Box, HStack, Image, Text, ScrollView, Heading, Button, Badge, useTheme, VStack, Pressable} from "native-base";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '~/Components/Template/Loader';
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import _ from 'lodash';
import Moment from 'moment';
import 'moment/min/moment-with-locales'
import { useNavigate } from "react-router-dom";

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const GarageFormScreen = ({bike, pos, handleClose}) => {
    const currentUser = useSelector((state) => state.user);
    const [loading, setLoading] = React.useState(false);
    const { colors } = useTheme();
    const [formData, setFormData] = React.useState([]);
    const [image, setImage] = React.useState(null);
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    const imagePickerRef = React.useRef();

    let baujahre = []

    for (var i = parseInt(Moment().subtract(60, 'years').format('YYYY')); i <= parseInt(Moment().add(1, 'year').format('YYYY')); i++ ) {
        baujahre.push(i.toString());
    }
    baujahre = _.reverse(baujahre);

    const [fields, setFields] = React.useState({
        'type' : {
            'Allrounder' : 'Allrounder', 
            'Chopper' : 'Chopper', 
            'Cruiser' : 'Cruiser', 
            'Supersportler' : 'Supersportler',
            'Tourer' : 'Tourer',
            'Naked' : 'Naked',
            'Sport-Tourer': 'Sport-Tourer',
            'Enduro': 'Enduro',
            'Pocket Bike': 'Pocket Bike',
            'Roller': 'Roller'
        }
    });


    const _onUnMount = async () => {
        if (!loading) setLoading(true);
        
        const body = new FormData();
        body.append('formData', JSON.stringify(formData));

        if (image != null)  {
            body.append('file_image', image);
        }
        
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/set/bikes/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: body
            })
            .then((res) => res.json())
            .then((json) => {
                handleClose(true);
                setLoading(false);
            });
        };
    
    };

    React.useEffect(() => {
        Moment.locale('de');
        
        if (currentUser) {
            if (bike && (pos === 0 || pos > 0)) {
                setFormData({
                    "bike" : bike,
                    "pos" : pos
                });
            } else {
                setFormData({
                    bike : {},
                    pos : null
                });
            }
        }
    }, [currentUser]);
    
    const updateFormData = function(field, value) {
        let _data = {...formData};
        _data.bike[field] = value;
        setFormData(_data);
    };
    
    const _handleImagePick = async(event) => {
        if (event?.target?.files?.length > 0) {
            updateFormData('image', null);
            setImage(event.target.files[0]);
        }
    }


    return (<>
            <View style={{
                flex: 1,
                justifyContent: "space-around"
            }}>
                <View style={{...StylesConfig.styles.card.wrapper}}>
                    <View style={StylesConfig.styles.card.header}>
                        <HStack justifyContent={'space-between'} alignItems={'center'}>
                            <Text style={StylesConfig.styles.card.header_text}>Bild</Text>
                        </HStack>
                        
                    </View>

                    <View style={StylesConfig.styles.card.body}  alignItems={'center'}>
                        
                        <VStack>
                            {image && !formData?.bike?.image && 
                                <Badge bgColor={'success.400'}>VORSCHAU</Badge>
                            }

                            {image && !formData?.bike?.image && 
                                <img id={'profileImage'} alt={'Profilbild1'} style={{
                                    width: 300,
                                    height: 300,
                                    borderRadius: 600
                                }} src={URL.createObjectURL(image)}/>
                            }

                            {!image && formData?.bike?.image && 
                                <img id={'profileImage'} alt={'Profilbild2'} style={{
                                    width: 300,
                                    height: 300,
                                    borderRadius: 600
                                }} src={Config.uploadDir + 'bikes/' + formData.bike.image}/>
                            }

                        
                    
                            <HStack justifyContent={'center'} mb={(image || formData.bike?.image) ? 0 : 5} mt={(image || formData.bike?.image) ? -6 : 0} space={3}>
                                
                                <Pressable borderRadius={'full'} bgColor={colors.muted['500']} p={3} onPress={() => imagePickerRef?.current.click()}>
                                    <FontAwesomeIcon size={25} color={'white'} icon="fa-solid fa-image" />
                                </Pressable>
                                
                                {(image || formData.bike?.image) &&
                                    <Pressable borderRadius={'full'} onPress={() => {
                                        SweetAlert.fire({
                                            'text' : `Bild löschen?`,
                                            showCancelButton: true,
                                            customClass : {
                                                confirmButton: 'btn btn-danger'
                                            },
                                            confirmButtonText: 'Ja, löschen',
                                            cancelButtonText: 'Abbrechen'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                updateFormData('image', null);
                                                setImage(null);
                                            }
                                        })
                                    }} bgColor={colors.danger['500']} p={3}><FontAwesomeIcon size={25} color={'white'} icon={'fa-solid fa-times'}/></Pressable>
                                }
                            </HStack>

                            {image == null && formData.bike?.image == null &&
                                <VStack>
                                    <Heading textAlign={'center'} fontSize={'lg'} color={colors.muted['400']}>Kein Bild ausgewählt</Heading>
                                    <Text textAlign={'center'}>Drücke auf das Bildsymbol</Text>
                                </VStack>
                            }
                            <input type={'file'} ref={imagePickerRef} onChange={_handleImagePick} accept={"image/png, image/jpeg"} style={{display:'none'}}/>
                        </VStack>
                        
                    </View>
                </View>

                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Fahrzeugdaten</Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                    
                        <FloatingLabel
                                controlId="floatingInput"
                                label="Hersteller"
                                className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.bike?.manufacturer} 
                                onChange={(e) => updateFormData('manufacturer', e.target.value)} 
                                />
                        </FloatingLabel>

                        <FloatingLabel
                                controlId="floatingInput"
                                label="Modell"
                                className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.bike?.series} 
                                onChange={(e) => updateFormData('series', e.target.value)} 
                                />
                        </FloatingLabel>
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Bauart"
                            className="mb-3"
                            >
                            <Form.Select 
                                value={formData.bike?.type}
                                onChange={e => updateFormData('type', e.target.value)}
                                >
                                {Object.keys(fields.type).map(item =>  {
                                    return <option value={item}>{fields.type[item]}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>

                        <FloatingLabel
                                controlId="floatingInput"
                                label="Farbe"
                                className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.bike?.color} 
                                onChange={(e) => updateFormData('color', e.target.value)} 
                                />
                        </FloatingLabel>

                        <FloatingLabel
                                controlId="floatingInput"
                                label="Hubraum"
                                className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.bike?.displacement} 
                                onChange={(e) => updateFormData('displacement', e.target.value)} 
                                />
                        </FloatingLabel>

                        <FloatingLabel
                                controlId="floatingInput"
                                label="Kennzeichen"
                                className="mb-3"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.bike?.kennzeichen} 
                                onChange={(e) => updateFormData('kennzeichen', e.target.value)} 
                                />
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Bauart"
                            className="mb-3"
                            >
                            <Form.Select 
                                value={formData.bike?.yearof}
                                onChange={e => updateFormData('yearof', e.target.value)}
                                >
                                {baujahre.map((item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>

                    

                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack justifyContent={'center'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>  
                </View>

            </View>
        </>
    );
    
};

export default GarageFormScreen;