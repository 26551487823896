import React from "react";
import {View, Text, Spinner, Image, VStack, Input, HStack, Box, Button, Center, Heading, useTheme, Pressable} from 'native-base';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import history from '~/Services/History';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ReactSession } from 'react-client-session';

export default function Welcome(props) {

    const {colors} = useTheme();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);
    
    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
            
                <Text p={5} fontSize={'lg'} textAlign={'center'} color='white'>
                    Du bist:
                </Text>
                
                <HStack space={5}>

                    <Pressable flex={1} onPress={() => {
                        ReactSession.set('register_formdata', {...ReactSession.get('register_formdata'), gender: 'm'});
                        navigate('/auth/register/birthday');
                    }}>
                        <View rounded={'3xl'} p={4} backgroundColor={colors.coolGray['100']} justifyContent={'center'} alignItems={'center'}>
                        <VStack justifyContent={'center'} alignItems={'center'} space={2}>
                                <FontAwesomeIcon color={colors.blue['500']} size={'4x'} icon={'fa-solid fa-mars'} />
                                <Text fontSize={11}>männlich</Text>
                            </VStack>
                        </View>
                    </Pressable>

                    <Pressable flex={1} onPress={() => {
                        ReactSession.set('register_formdata', {...ReactSession.get('register_formdata'), gender: 'w'});
                        navigate('/auth/register/birthday');
                    }}>
                        <View rounded={'3xl'} p={4} backgroundColor={colors.coolGray['100']} justifyContent={'center'} alignItems={'center'}>
                        <VStack justifyContent={'center'} alignItems={'center'} space={2}>
                                <FontAwesomeIcon color={colors.pink['500']} size={'4x'} icon={'fa-solid fa-venus'} />
                                <Text fontSize={11}>weiblich</Text>
                            </VStack>
                        </View>
                    </Pressable>

                    <Pressable flex={1} onPress={() => {
                        ReactSession.set('register_formdata', {...ReactSession.get('register_formdata'), gender: 't'});
                        navigate('/auth/register/birthday');
                    }}>
                        <View rounded={'3xl'} p={4} backgroundColor={colors.coolGray['100']} justifyContent={'center'} alignItems={'center'}>
                            <VStack justifyContent={'center'} alignItems={'center'} space={2}>
                                <FontAwesomeIcon color={colors.fuchsia['600']} size={'4x'} icon={'fa-solid fa-transgender'} />
                                <Text fontSize={11}>divers</Text>
                            </VStack>
                        </View>
                    </Pressable>

                </HStack>
            

        </VStack>;
}
