import { Progress, Text, VStack, useTheme} from "native-base";

export const getCommunicationTxt = (commId) => {

    let systems = ["Keins","Sena","Cardo","Sonstiges","Chain-App","WhatsApp-Call","Walkie-Talkie"];
    
    if (commId > 0) {
        return systems[commId];
    } else {
        return 'Keins';
    }
};

export const ProgressBar = ({title, value, max}) => {
    const { colors } = useTheme();

    let headline = title;
    let percentage = (value * 100) / max;
    let color = colors.success['500'];

    if (percentage < 66) {
        color = colors.warning['500'];
    }

    if (percentage < 33) {
        color = colors.danger['400'];
    }


    if (headline != '' && max > 0) {
        return (
            <VStack space={1}>
                <Text fontSize={12} textTransform={'uppercase'}>{headline} ({value}/{max})</Text>
                <Progress _filledTrack={{bg: color}} value={percentage} />
            </VStack>
        );
    }

}

const Analytics = ({data}) => {

    return (
        <VStack space={8}>
            <VStack space={1}>
                <Text fontSize={12} textTransform={'uppercase'}>Kommunikations-System</Text>
                <Text fontSize={16}>{getCommunicationTxt(parseInt(data.communication_system))}</Text>
            </VStack>
            <ProgressBar title={'Geraden'} value={parseInt(data.straight_safety) + parseInt(data.straight_speed)} max={16} />
            <ProgressBar title={'Kurven'} value={parseInt(data.curve_safety) + parseInt(data.curve_speed)} max={16} />
            <ProgressBar title={'Spitzkehren'} value={parseInt(data.sharp_bend_safety) + parseInt(data.sharp_bend_speed)} max={16} />
            <ProgressBar title={'Verantwortung '} value={parseInt(data.charge_safety) + parseInt(data.charge_speed)} max={16} />
        </VStack>
    );

};


export default Analytics;