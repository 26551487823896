import { useEffect, useState} from "react";
import {Badge, HStack, Text, VStack, Pressable} from "native-base";
import {ProgressBar} from '~/Components/Users/Detail/Analytics';
import Config from '~/Config/BaseConfig';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PhoneCall = ({user}) => {
    const currentUser = useSelector((state) => state.user);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [fetched, setFetched] = useState(false);
    
    const fetchNumber = async() => {
        if (!_.isEmpty(currentUser) && fetched == false) {
            setFetched(true);

            await fetch(Config.host + '/api/user/keyvalue/' + user +'/number/', {
                headers: { 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash}
            })
            .then((res) => res.json())
            .then((json) => {
                if (json?.number && json?.number != '+49' && json?.number?.length > 8) {
                    setPhoneNumber(json.number);
                }
            })
        }
    }

    useEffect(() => {
        if (currentUser) {
            fetchNumber();
        }
    }, [currentUser])

    return (
        <>
        {phoneNumber && 
            <Pressable onPress={() => {
                window.open(`tel:/${phoneNumber}`)
            }}>
                <Badge rounded={'full'} variant={'solid'} colorScheme={'success'}>
                    <HStack justifyContent={'center'} alignItems={'center'} space={1}>
                        <FontAwesomeIcon size={8} color={'white'} icon={'fa-solid fa-phone'} />
                        <Text fontSize={8} color={'white'}>ANRUFEN</Text>
                    </HStack>
                </Badge>
            </Pressable>
        }
        </>
    );
    
}



const TourAnalyser = ({data}) => {
    return (
        <VStack>
        {data.map((item, index) => {
            let straights = item?.straights || 0;
            let curves = item?.curves || 0;
            let sharpBends = item?.sharpBends || 0;
            let charges = item?.charges || 0;
            let username = item?.username || 'NULL';
            let score = item?.score || 0;
            let communication_system = item?.communication_system || 'Keins';
            let user_id = item?.user_id || 0;

            return (
                <VStack key={index} space={1} mb={10}>
                    <HStack justifyContent={'space-between'} alignItems={'center'}>
                        <Text fontWeight={'bold'}>{index+1}. {username} // Score: {score}</Text>
                        <PhoneCall user={user_id} />
                    </HStack>
                    
                    <Text>Intercom: <Text fontWeight={'bold'}>{communication_system}</Text></Text>
                    <ProgressBar title={'Geraden'} value={straights} max={16} />
                    <ProgressBar title={'Kurven'} value={curves} max={16} />
                    <ProgressBar title={'Spitzkehren'} value={sharpBends} max={16} />
                    <ProgressBar title={'Verantwortung '} value={charges} max={16} />
                </VStack>
            );
        })}
        </VStack>
    );
};

export default TourAnalyser;