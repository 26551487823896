import React from 'react';

import { View, Box, HStack, VStack, Avatar, Image, Text, ScrollView, Heading, Button, Badge, useTheme, Skeleton, Pressable, Slider, Divider, Menu} from "native-base";
import Loader from '~/Components/Template/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from "lodash";
import StylesConfig from '~/Config/Styles';
import { ReactSession } from 'react-client-session';
import {toasty} from '~/Helper/Base';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import Moment from 'moment';
import 'moment/min/moment-with-locales'

import { isBrowser, isMobile } from 'react-device-detect';
import {convertBikes, BikeImage, BikeName, BikeDetails} from '~/Helper/GarageHelper';

import Modal from 'react-bootstrap/Modal';

import GarageForm from '~/Views/Profile/Garage/Form';
import {wait} from '~/Helper/Base';

const Garage = () => {
    
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);
    const { colors } = useTheme();
    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);
    const [formData, setFormData] = React.useState({});

    const [modalBike, setModalBike] = React.useState(null);
    const [modalPos, setModalPos] = React.useState(null);
    const [modelVisible, setModalVisible] = React.useState(false);

    const handleModalOpen = (visible, bike = null, pos = null) => {
        setLoading(true);
        setModalPos(pos);
        setModalBike(bike);

        wait(500).then(() => {
            setModalVisible(visible);
            setLoading(false);
        })
    }

    const handelModalClose = (withRefresh = false) => {        
        setModalPos(null);
        setModalBike(null);
        setModalVisible(false);
        if (withRefresh) {
            handleRefresh();
        }
    };

    const handleRefresh = () => {
        if (!loading) setLoading(true);
        _onMount();
    };

    React.useEffect(() => {
        Moment.locale('de');
    }, [])
    
    React.useEffect(() => {
        if (currentUser && loading) {
            _onMount();
        }
    }, [currentUser])

    const _onMount = async () => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/get/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'includes': 'userdata'})
            })
            .then((res) => res.json())
            .then((json) => {
            
                json.userdata.bikes = convertBikes(json?.userdata?.bikes);
                
                setFormData(json);
                setLoading(false);
            });
        };
    };

    const _onDelete = async (index) => {
        SweetAlert.fire({
            'text' : `Möchtest du den Eintrag wirklich löschen?`,
            showCancelButton: true,
            customClass : {
                confirmButton: 'btn btn-danger'
            },
            confirmButtonText: 'Ja, löschen',
            cancelButtonText: 'Abbrechen'
        })
        .then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                if (!_.isEmpty(currentUser)) {
                    fetch(Config.host + '/api/user/action/delete/bikes/' + currentUser.data.id +'/', {
                        method: 'POST',
                        headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                        body : simpleFormData({'bike_pos': index})
                    }).then((response) => {
                        handleRefresh();
                        toasty('Eintrag gelöscht', 'success');
                    });
                } else {
                    toasty('Eintrag konnte nicht gelöscht werden', 'error');
                }
            }
        });

    };

    const GarageItem = ({item, idx}) => {
        const [visibleSheet, setVisibileSheet] = React.useState(false);
        

        return (<div key={idx}>
                <HStack justifyContent={'space-between'} key={idx} space={3} bgColor={'white'}>
                    
                    <HStack space={3} justifyContent={'flex-start'} >
                        <BikeImage bike={item} />
                        
                        <VStack>
                            <BikeName bike={item} />
                            <BikeDetails bike={item} />
                        </VStack>
                    </HStack>

                    <Dropdown style={{zIndex: 10}}>
                        <Dropdown.Toggle style={{borderWidth: 0, backgroundColor: colors.muted['400']}}>
                            Aktion
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleModalOpen(true, item, idx)}>
                                <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Badge w={35} h={35} rounded={'full'}>
                                        <FontAwesomeIcon icon={'fa-solid fa-pencil'} />
                                    </Badge>
                                    <Text>Bearbeiten</Text>
                                </HStack>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => _onDelete(idx)}>
                                <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Badge w={35} h={35} rounded={'full'}>
                                        <FontAwesomeIcon icon={'fa-solid fa-times'} />
                                    </Badge>
                                    <Text>Löschen</Text>
                                </HStack>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </HStack>
            </div>
        )

    }

    if (!formData) {
        return (<><Loader loading={loading} /></>);
    } else {
        return (
            <>
                <Loader loading={loading} />
               <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <HStack justifyContent={'space-between'} alignItems={'center'}>
                            <Text style={StylesConfig.styles.card.header_text}>Deine Garage</Text>
                            <Button onPress={() => handleModalOpen(true)} size={'sm'}>Fahrzeug hinzufügen</Button>
                        </HStack>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <VStack space={5}>
                        {_.isArray(formData?.userdata?.bikes) && 

                            formData.userdata.bikes.map((item, index) => {
                                return <GarageItem key={index} idx={index} item={item} />
                            })

                        }
                        {formData?.userdata?.bikes == false && 

                            <Box style={{height:'100%'}}>
                                <Text style={{padding: 15, paddingTop:5, textAlign: 'center'}}>Keine Einträge vorhanden</Text>
                            </Box>

                        }
                        </VStack>
                    </View>

                    <div style={{marginTop: 25, backgroundColor: 'white'}}>
                        <Pressable style={{padding: 15}} onPress={() => window.open('https://a.partner-versicherung.de/click.php?partner_id=134570&ad_id=771&deep=motorradversicherung')}>
                            <Image resizeMode='contain' width={468} height={60} alt={'Motorrad Versicherung'} source={{uri: 'https://a.partner-versicherung.de/view.php?partner_id=134570&ad_id=771'}} />
                        </Pressable>
                    </div>
                    
                </View>
                <Modal show={modelVisible} onHide={handelModalClose}  animation={false}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <GarageForm bike={modalBike} handleClose={handelModalClose} pos={modalPos} />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
};

export default Garage;