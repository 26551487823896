import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({

    name : "user",

    initialState: null,

    reducers : {
        setUser(state, action) {
            return {...action.payload};
        },
        unsetUser(state, action) {
            return {};
        }
    }

});

export const {setUser, unsetUser} = userSlice.actions;
export default userSlice.reducer