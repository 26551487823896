// Import React and Component
import {useState, useEffect, useRef} from 'react';
import { View, HStack, Box, AspectRatio, Progress, useTheme, VStack, Text, Stack, Heading, Pressable, Image, Skeleton, Center} from 'native-base';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from '~/Config/BaseConfig';
import _ from 'lodash';
import {checkImageURL, wait} from '~/Helper/Base';

import { useNavigate, Link } from "react-router-dom";
import { simpleFormData } from '~/Helper/FormHelper';
import TourHelper from '~/Helper/TourHelper';
import { useSelector } from 'react-redux';

import TourItem from '~/Components/Touren/List/Item';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import YouTube from 'react-youtube';
import uuid from 'react-uuid';

const PostContent = ({post, poll, images, reloadFunc}) => {
    
    const currentUser = useSelector((state) => state.user);
    const { colors } = useTheme();
    const [postData, setPostData] = useState(null);
    const [pollData, setPollData] = useState(null);
    const [postImages, setPostImages] = useState([]);
    const [postTour, setPostTour] = useState(null);
    const [votedUsers, setVotedUsers] = useState([]);
    const [playing, setPlaying] = useState({});
    const navigate = useNavigate();

    
    const [initialIndex, setInitialIndex] = useState(0);
    const galleryId = uuid();

    const _loadTourInfo = async (tour_id) => {
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/touren/get/' + tour_id + '/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body: simpleFormData({'includes' : 'extended'})
            })
            .then((res) => res.json())
            .then((json) => {
                if (json?.status == '401') {
                    setPostTour(false);
                } else {
                    setPostTour(json);
                }
            }).catch(() => {
                setPostTour(false);
            });
        };
    };

    const _vote = async (optionNumber) => {
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/forum/vote/' + currentUser.data.id + '/' + postData.post_id + '/' + (optionNumber+1) +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash }
            })
            .then(() => {
                let _data = votedUsers;
                _data.push(parseInt(currentUser.data.id));
                setVotedUsers(_data);
                wait(500).then(() => {
                    reloadFunc();
                })
            });
        };
    }

    useEffect(() => {
        
        if (typeof post == 'object') {
            
            if (typeof post.extend == 'string') {
                post.extend = JSON.parse(post.extend);    
            }
            
            /** try again*/
            if (typeof post.extend == 'string') {
                post.extend = JSON.parse(post.extend);    
            }

            setPostData(post);
        }

        if (typeof post.extend?.linkdata != 'undefined') {
            
            if (post.extend?.linkdata?.type == 'tour') {
                _loadTourInfo(post.extend?.linkdata.tour_id);
            }

        }
        

        if (typeof poll == 'object') {
            setPollData(poll);

            let _votedUsersData = [];

            poll.map((item) => {
                if (typeof item.users == 'object') {
                    item.users.forEach((value) => {
                        if (!_votedUsersData.includes(parseInt(value))) {
                            _votedUsersData.push(parseInt(value));
                        }
                    });
                }
            });
            setVotedUsers(_votedUsersData);
        }
        
        if (typeof images == 'object' || typeof images == 'string') {
            setPostImages(images);
        }
    

    }, []);
    
    const getContent = () => {
        return postData.post;
    }

    const turncate = (text, maxlen = 10) => {
        if (_.isString(text)) {
            return text.length > maxlen ? text.substring(0, maxlen-3) + "..." : text;
        }
        return text;
    }

    const _postContent = () => {
        
        return (<div style={{paddingLeft: 10, marginTop: 10, marginBottom: 10}} >
            {'title' in postData 
            &&  postData.title?.length > 0 
            && 
                <Heading fontSize={'xl'} mb={3}>{postData.title}</Heading>
            }

            {postData.post?.length > 0 &&
                <div>{convertHtmlToReact(postData.post, {
                    transform : function(node) {
                        const attr = node?.attribs;
                        let youtubeId = null;
                        
                        /**  YOUTUBE VIDEO */
                        if (attr?.href && attr?.href.indexOf('youtu.be') !== -1) {
                            youtubeId = attr?.href.replace('https://youtu.be/', '');
                        }

                        if (attr?.href && attr?.href.indexOf('youtube.com') !== -1) {
                            const url = new URL(attr?.href);
                            let vId = url.searchParams.get('v');
                            if (vId) {
                                youtubeId = vId;
                            }
                        }
                        
                        if (youtubeId !== null) {
                            
                            return <Center>
                                <YouTube
                                    initialPlayerParams={{
                                        showClosedCaptions : false
                                    }} 
                                    width={'100%'}
                                    play={false}
                                    videoId={youtubeId}
                                    />
                            </Center>
                        }



                        if (node?.name == 'a') {
                            return <Link target={'_blank'} to={attr?.href}>{turncate(attr?.href, 50)} </Link>;
                        }
                    }
                })}</div>
            }
            </div>
        );
    };


    const _pollContent = () => {
        return (<>
            {pollData.length > 0 &&
                pollData.map((item, index) => {
                    
                    return (<View key={`${postData.post_id}_${index}`} mt={5}>
                        
                        {votedUsers.indexOf(parseInt(currentUser?.data?.id)) != -1 && /* IN LISTE */
                            <VStack mb={1}>
                                <Progress size={'xl'} colorScheme="primary" value={item.percent} />
                                <HStack justifyContent={'space-between'} alignItems={'center'}>
                                    <Text>{item.value}</Text>
                                    <Text>{item.user_count} {(item.user_count == 1) ? 'Stimme' : 'Stimmen'}</Text>
                                </HStack>
                                
                            </VStack>
                        }
                        
                        {votedUsers.indexOf(parseInt(currentUser?.data?.id)) == -1 && /* NICHT IN LISTE */
                            <HStack mb={1} justifyContent={'space-between'} alignItems={'center'}>
                                <Text maxW={'80%'} fontSize={'md'}>{item.value}</Text>
                                <Pressable onPress={() => {
									_vote(index);
								}}>
                                    <HStack space={3} alignItems={'center'}>
                                        <Text>Abstimmen</Text>
                                        <Box backgroundColor={'success.400'} p={3} rounded={'full'}>
                                            <FontAwesomeIcon size={'md'} color={'white'} icon="fa-solid fa-check" />
                                        </Box>
                                    </HStack>
                                </Pressable>
                            </HStack>
                        }

                    </View>);
                      
                })
            }
            </>
        );
    };


    const _imageContent = () => {
        
        let images = [];
        let counter = 0;
        let previewImages = [];

        if (typeof postImages == 'object' && postImages.length > 0) {
            _.forEach(postImages, (path) => {
                if(checkImageURL(Config.uploadDir + path)) {
                    
                    let i = counter;
                    images.push({
                        id: counter,
                        url: Config.uploadDir + path
                    });
                    
                    if (counter < 3) {
                        previewImages.push(
                            <div className='pointer' data-fancybox={galleryId} data-src={ Config.uploadDir + path} 
                                style={{maxWidth: postImages.length == 1 ? '100%' : '45%'}}> 
                                <img src={ Config.uploadDir + path} className={'img-fluid'} />
                            </div>
                        );
                    }

                    if (counter == 3) {
                        previewImages.push(
                            <div className='pointer' data-fancybox={galleryId} data-src={ Config.uploadDir + path} style={{maxWidth: '45%', position: 'relative'}}> 
                                <img src={ Config.uploadDir + path} className={'img-fluid'} />
                                
                                {postImages.length - 4 > 0 &&

                                    <div className='pointer' style={{backgroundColor: '#00000095', position: 'absolute', width: '100%', height: '100%', top: '0', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Heading fontSize={60} color={'white'}>+{postImages.length - 4}</Heading> 
                                    </div>
                                }
                            </div>
                        );
                    }

                    counter++;
                }
            });
        }
        
        if (images.length > 0) {
            return (
                <>
                    <div style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', flex:1, position: 'relative', gap: '30px'}}>{previewImages}</div>

                    <div style={{display:'none'}}>
                        {images.map((item) => {
                            return <img src={item.url} data-fancybox={galleryId} />
                        })}
                    </div>
                </>
            );
        } else {
            return (<></>);
        }
    }

    
    const _tourInfo = () => {
            
            return (
                <>
                    {postTour === false && 
                        <View my={5} shadow={6}>
                            <Box rounded={'lg'} justifyContent={'center'} alignItems={'center'}  p={5}  w={'100%'} bgColor={'#FFFFFF'}>
                                <Text mt={3} color={colors.muted['300']} fontSize={'sm'}>Inhalt kann nicht angezeigt werden</Text>
                            </Box>    
                        </View>
                    }

                    {postTour && 
                        
                        <TourItem tour_id={postTour.extended.tour_id} />
                        
                    }
                </>
            );
    }
    
    return (
        <div>
         {currentUser?.data?.id > 0 && postData && postTour !== false && _postContent()}
         {currentUser?.data?.id > 0 && pollData && _pollContent()}
         {currentUser?.data?.id > 0 && postImages.length > 0 && _imageContent()}
         {currentUser?.data?.id > 0  && postTour !== null && _tourInfo()}
        </div>
    );

};

export default PostContent;