import React from 'react';
import ActionBar from '~/Components/Touren/List/ActionBar';

import { Link, Outlet, useLocation  } from "react-router-dom";
import _ from 'lodash';

const Touren = ({...props}) => {
    
    const location = useLocation();
    const [active, setActive] = React.useState('all');
    
    React.useEffect(() => {
        if (location.pathname.indexOf('own') != -1) {
            setActive('own');
        } else if (location.pathname.indexOf('scheduled') != -1) {
            setActive('scheduled');
        } else {
            setActive('all');
        }

    }, [location])

    return (
        <>
            <div className="page-nav-pills nav nav-pills nav-justified mb-0 d-flex">
                <Link className={"nav-link " + (active == 'all' ? 'active' : '')} to="/touren/all">Alle</Link>
                <Link className={"nav-link " + (active == 'own' ? 'active' : '')} to="/touren/own">Meine</Link>
                <Link className={"nav-link " + (active == 'scheduled' ? 'active' : '')} to="/touren/scheduled">Geplant</Link>
            </div>

            <Outlet />
            {props.children}
            <ActionBar />
        </>
    );

    
}

export default Touren;