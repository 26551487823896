import { createSlice } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';

const _initialState = () => {
    let reponse = {
        drawer_visible : null
    };

    if (isMobile) {
        reponse.drawer_visible = false;
    } else {
        reponse.drawer_visible = true;
    }

    return reponse;
}

const settingsSlice = createSlice({

    name : "settings",

    initialState: _initialState(),

    reducers : {
        setSettings(state, action) {
            return {...action.payload};
        },
        unsetSettings(state, action) {
            return {};
        }
    }

});

export const {setSettings, unsetSettings} = settingsSlice.actions;
export default settingsSlice.reducer