import React from 'react';
import { Box, Avatar, Text, View, Heading, HStack, VStack, Image, Skeleton, AspectRatio, Pressable, Spinner, ScrollView, Badge, Flex, useTheme} from 'native-base';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '~/Components/Template/Loader';
import UserHelper from '~/Helper/UserHelper';
import DateHelper from '~/Helper/DateHelper';
import UserPill from '~/Components/Users/UserPill';
import FormatedView from '~/Components/Template/Elements/FormatedView';
import UserInteractionBar from '~/Components/Users/Detail/UserInteractionBar';
import TourList from '~/Components/Users/Detail/TourList';
import UserLabels from '~/Components/Users/Detail/UserLabels';
import Bikes from '~/Components/Users/Detail/Bikes';
import { hasBikes } from '~/Helper/GarageHelper';
import { simpleFormData } from '~/Helper/FormHelper';
import Fullsize from '~/Components/Template/Elements/Fullsize';

//import ImageViewer from 'react-native-image-zoom-viewer';

import StylesConfig from '~/Config/Styles';
import Config from '~/Config/BaseConfig';
import Analytics from '~/Components/Users/Detail/Analytics';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {Helmet} from "react-helmet";

const ChunkedFriends = ({friends}) => {
    const {colors} = useTheme();
    const chunked = _.chunk(friends, 25);
    const hasMoreThanChunked = (chunked.length > 1);
    let userpills = [];
    
    const [showAll, setShowAll] = React.useState();

    if (!showAll) {
        chunked[0].map((item, index) => {
            userpills.push(<Box key={index} style={{ marginBottom: 15 }}><UserPill user={item.user} /></Box>);
        });
    } else {
        friends.map((item, index) => {
            userpills.push(<Box key={index} style={{ marginBottom: 15 }}><UserPill user={item.user} /></Box>);
        });
    }

    if (hasMoreThanChunked && !showAll) {
        userpills.push(
            <Pressable onPress={() => setShowAll(true)}>
                <Flex style={{marginBottom: 15}} direction='row' align='center' alignSelf='flex-start' borderRadius="full" bg={colors.muted['300']}>
                    <Text style={{padding: 6, paddingLeft: 15, paddingRight: 15}}>Alle Anzeigen</Text>
                </Flex>
            </Pressable>
        );
    }

    return userpills;
};


const UserDetail = (props) => {
    const params =  useParams();
    
    const [user, setUser] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const currentUser = useSelector((state) => state.user);
    const [staticMap, setStaticMap] = React.useState('');
    const [imageModal, setImageModalVisible] = React.useState(false);
    
    React.useEffect(() => {
        if (currentUser && params?.user_id) {
            _fetchUser();
        }
    }, [currentUser, params]);
    
    React.useEffect(() => {
        if (user.userdata?.lat && user.userdata?.lng) {
            _staticMap(user.userdata.lat, user.userdata.lng);
        }
    }, [user])

    const _fetchUser = async () => {
      if (params.user_id) {
          if (!_.isEmpty(currentUser)) {
          fetch(Config.host + '/api/user/get/' + params.user_id + '/', {
            method: 'POST',
            headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
            body: simpleFormData({'includes':'userdata,approvedFriends,statistics,analytics'})
          })
          .then((res) => res.json())
            .then((json) => {
              setUser(json);
              setLoading(false);
            });
        };
      }
    };
  
    const _staticMap = async (lat, lng) => {
      if (_.isEmpty(lat) || _.isEmpty(lng)) {
          return false;
      }
  
      (!loading && setLoading(true))
  
      if (!_.isEmpty(currentUser)) {
          await fetch(Config.host + '/api/map/pointer/' + lat + '/' + lng +'/', {
              headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash }
          })
          .then((res) => res.json())
          .then((json) => {
              setStaticMap(json.static);
              setLoading(false);
          });
      }
  
    };
  
    
  
    if (_.isEmpty(user.userdata)) {
        return <Fullsize><Spinner mt={10} size="lg" /></Fullsize>;
    } else {
        let driverLicenseData = null;
    
        if (typeof user.userdata?.driverlicense !== 'undefined') {
            let driverLicenseData = JSON.parse(user.userdata.driverlicense);
        } 
  
        return (
    
            <ScrollView scrollIndicatorInsets={{ right: 1 }}>
                <Helmet><title>{user.userdata.outputname} auf Meet4Ride</title></Helmet>
                <Loader loading={loading} />

                <a data-fancybox href={UserHelper.getUserimage(user.userdata)}>
                    <Box bg={StylesConfig.color.base} style={{ height: 125 }} alignItems={'center'}>
                        <Avatar zIndex={2} mt={21} position={'absolute'} borderWidth={3} borderColor={StylesConfig.color.middleGray} size={200} source={{ uri: UserHelper.getUserimage(user.userdata) }} />
                    </Box>
                </a>
    
                <Box style={{ marginTop: 125, alignItems: 'center' }}>
                    <Heading textAlign={'center'} mb={3} px={5} fontWeight={'bold'} fontSize={'3xl'} color={StylesConfig.color.base}>{user.userdata.outputname}</Heading>
        
                    <HStack alignItems={'center'} justifyContent={'center'} mb={5} style={{ marginTop: 5, flexWrap: 'wrap', flex:1, paddingHorizontal: 15}}>
                        {UserHelper.buildDetails(user.userdata, { flag: { size: 16 }, text: { fontSize: 18 } })}
                    </HStack>
                </Box>
    
                {user.userdata.intro &&
                    <Text fontSize={'xl'} p={5} textAlign={'center'}>{user.userdata.intro}</Text>
                }


                <Row style={{paddingLeft: 30, paddingRight: 30}}>
                    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2}}>
                        <Masonry columnsCount={2} gutter={'30px'}>
                            {user.userdata.id != currentUser?.data?.id && user.userdata.id > 0 &&
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Interaktion</Text>
                                        </View>
                        
                                        <View style={StylesConfig.styles.card.body}>
                                            <UserInteractionBar user={user.userdata} />
                                        </View>
                                    </View>
                                </Col>
                            }
                        
                        
                            {UserHelper.getContactDetails(user.userdata).length > 0 && user.userdata.id > 0 && 
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Kontakt</Text>
                                        </View>
                        
                                        <View style={{...StylesConfig.styles.card.body, paddingRight: 10}}>
                                            <HStack justifyContent={'center'} alignItems={'center'} flexDirection={'row'} flexWrap={'wrap'}>
                                                {UserHelper.getContactDetails(user.userdata)}
                                            </HStack>
                                        </View>
                                    </View>
                                </Col>
                            }

                            {user.approvedFriends?.length > 0 && user.userdata.id > 0 &&
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Freunde</Text>
                                        </View>
                        
                                        <View style={StylesConfig.styles.card.body}>
                                            <HStack alignItems={'center'} flexDirection={'row'} flexWrap={'wrap'}>
                                                <ChunkedFriends friends={user.approvedFriends} />
                                            </HStack>
                                        </View>
                                    </View>
                                </Col>
                            }

                            {staticMap != '' && user.userdata.id > 0 && 
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Info</Text>
                                        </View>
                        
                                        <View style={StylesConfig.styles.card.body}>
                                            <Row>
                                                <Col md={6} xs={12}>
                                                    <AspectRatio ratio={{base: 1/1}}>
                                                        <Image alt="Alternate Text" resizeMode="cover" size={'full'} source={{uri : Config.host + staticMap}} />
                                                    </AspectRatio>
                                                </Col>

                                                <Col md={6} xs={12}>
                                                    <VStack space={3}>
                                                        {user?.userdata?.birthday && 
                                                            <FormatedView title={'Geburtstag: '} value={DateHelper.formatDate(user.userdata.birthday, 'LL')} />
                                                        }
                                                        
                                                        {user?.userdata?.zip && 
                                                            <FormatedView title={'PLZ / Ort: '} value={`${user.userdata.zip} / ${user.userdata.city}`} />
                                                        }
                                    
                                                        {user?.userdata?.relationship && 
                                                            <FormatedView title={'Beziehungsstatus: '} value={user?.userdata?.relationship} />
                                                        }
                                                        
                                                        
                                                        
                                                        {driverLicenseData !== null && !_.isEmpty(driverLicenseData.since) && 
                                                            <FormatedView title={'Führerschein seit '} value={DateHelper.formatDate(driverLicenseData.since, 'MMM YYYY')} />
                                                        }
                                    
                                                        
                                                        {driverLicenseData !== null && !_.isEmpty(driverLicenseData.class) && 
                                                            <FormatedView title={'Führerschein-Klasse: '} value={
                                                                <Box p={2} borderRadius={'full'} bg={'success.500'}>{driverLicenseData.class}</Box>
                                                            } />
                                                        }
                                    
                                                    </VStack>
                                                </Col>
                                            </Row>
                                            
                                        </View>
                                    </View>
                                </Col>
                            }

                            {(user.statistics?.data?.sum > 0 || user.userdata.usertype > 0) && user.userdata.id > 0 && 
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Auszeichnungen</Text>
                                        </View>
                            
                                        <View style={{...StylesConfig.styles.card.body, paddingBottom:8}}>
                                            <UserLabels 
                                                statistics={user.statistics.data}
                                                usertype={user.userdata.usertype} 
                                                />
                                        </View>
                                    </View>
                                </Col>
                            }

                            {hasBikes(user?.userdata?.bikes) && 
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Garage</Text>
                                        </View>
                            
                                        <View style={StylesConfig.styles.card.body}>
                                            <Bikes bikes={user?.userdata?.bikes} />
                                        </View>
                                    </View>
                                </Col>
                            }

                            {(parseInt(currentUser?.data?.usertype) == 2 || parseInt(currentUser?.data?.usertype) == 1) && 
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Fahrerprofil</Text>
                                        </View>
                            
                                        <View style={StylesConfig.styles.card.body}>
                                            <View style={{paddingHorizontal: 5}}>
                                                <Analytics data={user.analytics} />
                                            </View>
                                        </View>
                                    </View>
                                </Col>
                            }

                            {Object.keys(user.statistics.touren).length > 0 && 
                                <Col xs={12}>
                                    <View style={StylesConfig.styles.card.wrapper}>
                                        <View style={StylesConfig.styles.card.header}>
                                            <Text style={StylesConfig.styles.card.header_text}>Touren</Text>
                                        </View>
                            
                                        <View style={StylesConfig.styles.card.body}>
                                            <TourList list={user.statistics.touren} />
                                        </View>
                                    </View>
                                </Col>
                            }

                        </Masonry>
                    </ResponsiveMasonry>
                </Row>
            </ScrollView>
            
        );
    }
  };
  
  export default React.memo(UserDetail);