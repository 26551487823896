import React from "react";
import {View, Text, Spinner, Image, VStack, Input, HStack, Box, Button, Heading} from 'native-base';
import {Helmet} from "react-helmet";
import { useNavigate, redirect } from "react-router-dom";
import Loader from '~/Components/Template/Loader';
import Config from '~/Config/BaseConfig';
import _ from 'lodash';
import { simpleFormData } from "~/Helper/FormHelper";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function Forgotten() {
    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const navigate = useNavigate();
    
    const SweetAlert = withReactContent(Swal);

    const _resetPassword = async () => {
        
        if (!email) {
            SweetAlert.fire('Bitte gib eine E-Mail Adresse an');
            return;
        }    
        
        setLoading(true);

        await fetch(Config.host + '/api/common/forgottenpass/', {
            method: 'POST',
            headers: {...Config.defaultHeaders},
            body : simpleFormData({'email' : email})
        }).then((res) => {
            SweetAlert.fire({
                title: 'Kennwort zurücksetzen',
                text: 'Wenn deine E-Mail vorhanden ist, erhältst du in den nächsten Minuten eine E-Mail (auch SPAM Ordner kontrollieren) zum zurücksetzen des Kennworts.'
            });
            redirect('/auth/login')
            setLoading(false);
        });
    };

    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
            <Loader loading={loading} />
            <Image
                source={require('~/Assets/layout/logo@3x.png')}
                h={50}
                w={100}
                resizeMode={'contain'}
                style={{margin: 30}}
            />
            
            <VStack space={3} w={['5/6', '2/4', '2/4', '1/4']}>
                <Heading color={'white'} textAlign={'center'}>Kennwort vergessen?</Heading>

                <Text mb={5} fontSize={'md'} textAlign={'center'} color='white'>
                    Kein Problem! Gibt einfach deine E-Mail Adresse ein und überprüfe innerhalb 
                    der nächsten paar Minuten dein E-Mail Postfach, zum zurücksetzen des Kennworts.
                </Text>

                <View className={'ui input'}>
                    <Input onChangeText={(text) => setEmail(text)} type="text" placeholder="E-Mail" />
                </View>

                <HStack space={3} alignItems={'center'}>
                    <Button flex={1} backgroundColor={'muted.50:alpha.20'} onPress={() => navigate('/auth/login')}>Zurück</Button>
                    <Button flex={1} backgroundColor={'success.500'} onPress={_resetPassword}>Kennwort zurücksetzen</Button>
                </HStack>
                
                
            </VStack>
            

            <Helmet>
                <title>Kennwort vergessen</title>
            </Helmet>
        </VStack>;
}
