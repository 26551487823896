import React from 'react';
import { HStack, View, Text, Pressable, ZStack, Badge, useTheme, Image} from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from "react-router-dom";
import Config from '~/Config/BaseConfig'
import { useSelector, useDispatch } from 'react-redux';
import { setSettings } from '~/Services/SettingsSlice';
import { ReactSession } from 'react-client-session';

import _ from 'lodash';
import { simpleFormData } from '~/Helper/FormHelper';
import { Drawer } from 'rsuite';
import Notifications from '~/Components/Notifications';

const Topbar = () => {
    const navigate = useNavigate();
    const {colors} = useTheme();

    const currentUser = useSelector((state) => state.user) || JSON.parse(ReactSession.get('user'));
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    const [notifyCount, setNotifyCount] = React.useState(0);
    const [messageCount, setMessageCount] = React.useState(100);

    const [notificationOpen, setNotificationOpen] = React.useState(false);

    const _getNotificationCount = async () => {
        if (!_.isEmpty(currentUser)) {
            
            await fetch(Config.host + '/api/notifications/count/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash},
                body: simpleFormData({filter : JSON.stringify({
                        user_id : currentUser.data.id,
                        viewed : '0'
                    })
                })
            })
            .then((res) => res.json())
            .then((json) => {
                setNotifyCount(json.count);
            });
        } else {
            setNotifyCount(0);
        };
    }
    
    React.useEffect(() => {
        _getNotificationCount();
        
        const interval = setInterval(() => {
            _getNotificationCount();
        }, 1000*10);

        return () => clearInterval(interval);

    }, []);
    

    return (<View backgroundColor={'white'}>
        <HStack justifyContent={'space-between'} p={5}>
            <View>
                <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                    <Pressable onPress={() => {
                        dispatch(setSettings({...settings, drawer_visible: !settings?.drawer_visible}))
                        }}>
                        <FontAwesomeIcon icon="fa-solid fa-bars" size={'xl'} style={{marginLeft: 15}}/>
                    </Pressable>
                    
                    <Link to={'/dashboard'}>
                        <Image alt={'Logo'} resizeMode={'contain'} style={{height: 23, top: 4, display: !settings?.drawer_visible ? 'block' : 'none'}} width={150} source={require('~/Assets/layout/logo_color_large.png')} />
                    </Link>
                
                </HStack>
            </View>
            <View>

                <HStack>
                    <Pressable onPress={() => setNotificationOpen(true)}>
                        <>
                            {notifyCount == 0 && 
                                <FontAwesomeIcon icon="fa-regular fa-bell" size={'xl'} color={'#666'} style={{marginRight:25}}/>
                            }

                            {notifyCount > 0 && 
                                <div>
                                    <FontAwesomeIcon icon="fa-solid fa-bell" size={'xl'} color={'#ffc107'} style={{marginRight:25}}/>
                                    <Badge 
                                        colorScheme="danger" 
                                        rounded="full" 
                                        style={{zIndex:1, position:'absolute', right: 10, top: -5}}
                                        variant="solid" 
                                        alignSelf="flex-end"
                                        _text={{fontSize: 8}}
                                        >
                                        {notifyCount}
                                    </Badge>
                                </div>
                            }
                        </>
                    </Pressable>
                    
                    <Pressable onPress={() => {
                        navigate('/profile');
                    }}>
                        <FontAwesomeIcon icon="fa-solid fa-user-circle" color={colors.primary['600']} size={'xl'}/>
                    </Pressable>
                </HStack>

            </View>
        </HStack>

        <Drawer open={notificationOpen} onClose={() => setNotificationOpen(false)}>
            <Drawer.Body>
                <Notifications setNotificationOpen={setNotificationOpen} />
            </Drawer.Body>
        </Drawer>
    </View>);
};

export default Topbar;