import Moment from 'moment';
import 'moment/locale/de';

class DateHelper {
    
    static formatDate = (datetime, format = null) => {
        let dateObj = new Moment(datetime);
        
        return (format === null) ? dateObj.format('LLL') : dateObj.format(format);
    };
    
    static humanize = (datetime) => {
        let dateObj = new Moment(datetime);
        
        return dateObj.fromNow();
    };

    static toHoursAndMinutes = (totalMinutes, isHours = false) => {
        if (isHours) {
            totalMinutes = 60 * totalMinutes;
        }
        return Moment().startOf('day').add(totalMinutes, 'minutes').format('H:mm')
    }

}



export default DateHelper;