import React from 'react';

import { HStack, Avatar, Text, Box, Skeleton, Pressable, VStack, View, Center} from 'native-base';
import UserHelper from '~/Helper/UserHelper';

const UserListItem = ({...props}) => {
    const [user, setUser] = React.useState(props.user);
    
    if (user.match === false) {
    
        return <Skeleton>
            <HStack space={5}>
                <Box alignItems={'center'}>
                    <Skeleton.Avatar
                        alignSelf="center" 
                        size="lg">
                    </Skeleton.Avatar>
                </Box>

                <Box justifyContent={'center'}>
                    <Skeleton.Text lines={2} />
                </Box>
            </HStack>
        </Skeleton>;
    
    } else {

        return (
            <a data-fancybox={'userDetail_' + user.id} data-group={false} data-type={'iframe'} className={'no-deco'} href={'/members/detail/' + user.id}  style={{padding: 10, width:'100%'}}>
                <View shadow={2} backgroundColor={'white'} p={5}>
                    <Center>
                        <VStack space={5} overflow={'hidden'}>
                            <Box alignItems={'center'}>
                                <Avatar
                                    alt={'UserImage'} 
                                    alignSelf="center" 
                                    source={{uri:UserHelper.getUserimage(user)}} 
                                    size="150"
                                />
                            </Box>

                            <Box justifyContent={'center'}>
                                <Center>
                                    <Text fontSize="lg" fontWeight={'bold'}>{UserHelper.buildUsername(user)}</Text>
                                    <HStack alignItems={'center'} flexDirection={'row'} flexWrap='nowrap'>
                                        {UserHelper.buildDetails(user)}
                                    </HStack>
                                </Center>
                            </Box>
                        </VStack>
                    </Center>
                </View>
                
            </a>
        );
    
    }
};

export default UserListItem;