import {View, Text, Box} from 'native-base';
import StylesConfig from '~/Config/Styles';

const Description = (props) => {
    let description = props.tour.route || null;

    if (description !== null && description.length > 0) {
        return (
            <View style={StylesConfig.styles.card.wrapperMt}>
                <View style={StylesConfig.styles.card.header}>
                    <Text style={StylesConfig.styles.card.header_text}>Beschreibung</Text>
                </View>
                
                <View style={StylesConfig.styles.card.body}>
                    <Box>{description}</Box>
                </View>
            </View>
        );
    }   

};

export default Description;