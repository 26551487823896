import { Pressable, Box, Image, Text, View, HStack, VStack} from 'native-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTheme } from "native-base";
import { useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import _ from "lodash";
import { setUser } from '~/Services/UserSlice';
import { ReactSession } from 'react-client-session';
import StylesConfig from '~/Config/Styles';

const Subnav = ({items}) => {
    const { colors } = useTheme();
    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);
    

    return (
        <View style={StylesConfig.styles.card.wrapperMt}>
            <View style={StylesConfig.styles.card.header}>
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <Text style={StylesConfig.styles.card.header_text}>Menü</Text>
                </HStack>
                
            </View>

            <View style={{...StylesConfig.styles.card.body, padding: 10, margin:0}} alignItems={'center'}>
                <VStack space={3} w={'100%'}>
                    {items.map((_item, index) => {

                        if (_item.divider == true) {
                            return;
                        }

                        let onPressEvent = null;

                        if (_item?.internal) {
                            onPressEvent = () => { navigate(_item.internal) };
                        }

                        if (_item?.external) {
                            onPressEvent = () => { window.open(_item.external) };
                        }

                        if (_item?.showSOSLink) {
                            onPressEvent = () => {
                                if (currentUser?.data?.emergency_uid) {
                                    window.open(Config.host + '/sos/find/' + currentUser?.data?.emergency_uid);
                                }
                            };
                        }

                        if (_item?.logout) {
                            onPressEvent = () => {
                                SweetAlert.fire({
                                    title: 'Abmelden',
                                    text: 'Möchtest du dich wirklich abmelden?',

                                })

                                SweetAlert.fire({
                                    title: 'Abmelden',
                                    text : 'Möchtest du dich wirklich abmelden?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Ja, abmelden',
                                    cancelButtonText: 'Abbrechen'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(setUser({}));
                                        ReactSession.set('user', {});
                                        navigate('/auth/login');
                                    }
                                });

                            };
                        }
                        
                        if (!_item?.icon) {
                            _item.icon = 'fa-solid fa-arrow-up-right-from-square';
                        }

                        return <Pressable key={index} onPress={onPressEvent}>
                                <Box justifyContent={'flex-start'} p={2}>
                                    <HStack space={5} justifyContent={'flex-start'} alignItems={'flex-start'}>
                                        {_item.icon && 
                                            <FontAwesomeIcon style={{marginTop: 5}} color={(_item.iconColor ? _item.iconColor : 'black')} size={'lg'} icon={_item.icon} />
                                        }
                                        <VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
                                            <Text fontSize={16} fontFamily={'Quicksand'} style={{..._item.titleStyle, fontWeight: 'bold'}}>{_item.title}</Text>
                                            <Text fontSize={11} style={{..._item.textStyle}}>{_item.text}</Text>
                                        </VStack>
                                    </HStack>                            

                                    
                                    
                                </Box>
                            </Pressable>

                    })}
                </VStack>
            </View>
        </View>
        
    );


};

export default Subnav;