/*
import { Platform } from 'react-native';
import { BannerAd, BannerAdSize, TestIds } from 'react-native-google-mobile-ads';
import Constants from 'expo-constants';
*/
import Config from '~/Config/BaseConfig';
import {Adsense} from '@ctrl/react-adsense';

//const isRunningInExpoGo = Constants.appOwnership === 'expo'

const admob_commercials = {
    'post_list_item' : {
        slotId: '9161729757',
        format: 'fluid'
    },
    'touren_list_item': {
        slotId: '1770324897',
        format: 'fluid'
    }
} 


export const AdMobBanner = ({position, bannersize}) => {
    return (<></>);
    
    return <Adsense
        client={Config.googleAdSenseToken}
        slot={'2491738992'}
        style={{display: 'block'}}
        format='fluid'
    />
    
    
    /*let detectedPlatform = (Platform.OS == 'android')  ? 'android' : 'ios';
    let response = <></>;
    
    let bannerSize = (typeof bannersize == 'undefined') ? 'ANCHORED_ADAPTIVE_BANNER' : bannersize.toUpperCase();
    
    if ( __DEV__ && isRunningInExpoGo)
        return response;

    if (
        detectedPlatform in admob_commercials
        && position in admob_commercials[detectedPlatform]
        ) {
            
            let adMobBannerId = __DEV__ ? TestIds.BANNER : admob_commercials[detectedPlatform][position];
            
            response = <BannerAd
                unitId={adMobBannerId}
                size={BannerAdSize[bannerSize]}
            />;

    }

    return response;
*/
}
