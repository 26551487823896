import {HStack, View, Text, ScrollView} from 'native-base';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';
import { setSettings } from '~/Services/SettingsSlice';
import Config from '~/Config/BaseConfig'
import { simpleFormData } from '~/Helper/FormHelper';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { Outlet } from "react-router-dom";
import Sidebar from '~/Components/Template/Elements/Navigation';
import Topbar from '~/Components/Template/Elements/Topbar';
import { Container, Offcanvas, Row, Col } from 'react-bootstrap';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { AdMobBanner } from '~/Components/AdMobs';

const BaseTemplate = ({children}) => {
    const CurrentUser = useSelector((state) => state.user);
    const settings = useSelector((state) => state.settings);
    const adMobsActive = false;
    const [drawerVisible, setDrawerVisible] = React.useState(false);

    let CookieUser = ReactSession.get('user');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleClose = () => (
        dispatch(setSettings({...settings, drawer_visible: !settings.drawer_visible}))
    );

    React.useEffect(() => {
        setDrawerVisible(settings.drawer_visible);
    }, [settings])

    const _validateAuth = async () => {
        if (!CurrentUser) {
            if (CookieUser) {
                CookieUser = JSON.parse(CookieUser);
                if (CookieUser?.token) {
                    dispatch(setUser({...CookieUser}));
                    
                    fetch(Config.host + '/api/auth/check/', {
                        method: 'POST',
                        headers: {...Config.defaultHeaders},
                        body: simpleFormData({'token': CookieUser.token})
                    })
                    .then((response) => {
                        if (!response.ok) {
                            navigate('/auth/login');
                        }
                    })
                } else {
                    navigate('/auth/login');
                }
            } else {
                navigate('/auth/login');
            }
            
        } else {
            fetch(Config.host + '/api/auth/check/', {
                method: 'POST',
                headers: {...Config.defaultHeaders},
                body: simpleFormData({'token': CurrentUser.token})
            })
            .then((response) => {
                if (!response.ok) {
                    navigate('/auth/login');
                }
            })
        }
    }

    React.useEffect(() => {
        _validateAuth();
    });

    const _sideBarWith = 250;

    return (<div style={{width: '100%', height: '100%'}}>
        <HStack>
            <MobileView>
                <Offcanvas show={drawerVisible} onHide={handleClose}>
                    <Offcanvas.Header closeButton />
                    <Offcanvas.Body>
                        <Sidebar />
                    </Offcanvas.Body>
                    
                </Offcanvas>
            </MobileView>

            <BrowserView>
                {drawerVisible && 
                    <div style={{width: _sideBarWith, height: '100vh', position:'fixed', backgroundColor: 'white'}}>
                        <Sidebar />
                    </div>
                }
            </BrowserView>

            <div style={{flex: 1, marginLeft: (drawerVisible && isBrowser) ? _sideBarWith : 0}}>
                
                <Topbar />
                {adMobsActive && 
                    <Container fluid>
                        <Row>
                            <Col xs={{span:12}} md={{span: 5, offset:2}}><Outlet /></Col>
                            <Col className={'d-none d-md-block'} md={2}>
                                <AdMobBanner />
                            </Col>

                        </Row>
                        
                    </Container>
                }

                {!adMobsActive && 
                    <Container fluid>
                        <Row>
                            <Col xs={{span:12}} md={{span: 6, offset:3}}><Outlet /></Col>
                        </Row>
                        
                    </Container>
                }

                
            </div>
        </HStack>
    </div>);


}

export default BaseTemplate;