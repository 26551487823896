import * as React from 'react';
import Config from '~/Config/BaseConfig';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Divider, Text, Box, Pressable, useTheme} from 'native-base';
import {isValidHttpUrl, checkImageURL} from '~/Helper/Base';

export default class UserHelper {

    static getContactDetails = (user) => {
        let items = [];
        const baseButtonStyle = {
            padding: 10,
            justifyContent: 'center',
            alignItems : 'center',
            borderRadius: 100,
            flexDirection: 'row',
            margin: 5,
        };
        
        if (!_.isEmpty(user.internationalNumber)) {
            items.push(
                <Pressable style={{...baseButtonStyle, backgroundColor: '#3696c2'}} key={`user_social_call_${user.id}`} onPress={() => {
                    window.open(`tel:/${user.internationalNumber}`);
                }}>
                    <FontAwesomeIcon size={'lg'} color={'white'} icon={'fa-solid fa-phone'} />
                </Pressable>
            );
        }

        if (!_.isEmpty(user.internationalNumber)) {
            items.push(
                <Pressable style={{...baseButtonStyle, backgroundColor: '#798188'}} key={`user_social_sms_${user.id}`} onPress={() => {
                    window.open(`sms:/${user.internationalNumber}`)
                }}>
                    <FontAwesomeIcon size={'lg'} color={'white'} icon={'fa-solid fa-sms'} />
                </Pressable>
            );
        }
        
        if (!_.isEmpty(user.internationalNumber)) {
            items.push(
                <Pressable style={{...baseButtonStyle, backgroundColor: '#73bd24'}} key={`user_social_whatsapp_${user.id}`} onPress={() => {
                    window.open(`https://wa.me/${user.internationalNumber}`)
                }}>
                    <FontAwesomeIcon size={'lg'} color={'white'} icon={'fa-brands fa-whatsapp'} />
                </Pressable>
            );
        }

        if (isValidHttpUrl(user.facebook)) {
            items.push(
                <Pressable style={{...baseButtonStyle, backgroundColor: '#3a5794'}} key={`user_social_facebook_${user.id}`} onPress={() => {
                    window.open(user.facebook);
                }}>
                    <FontAwesomeIcon size={'lg'} color={'white'} icon={'fa-brands fa-facebook'} />
                </Pressable>
            );
        }
        
        if (!_.isEmpty(user.instagram)) {
            items.push(
                <Pressable style={{...baseButtonStyle, backgroundColor: '#cf2460'}}  key={`user_social_instagram_${user.id}`} onPress={() => {
                    window.open( `https://www.instagram.com/${user.instagram}`);
                }}>
                    <FontAwesomeIcon size={'lg'} color={'white'} icon={'fa-brands fa-instagram'} />
                </Pressable>
            );
        }
        
        if (!_.isEmpty(user.telegram)) {
            items.push(
                <Pressable style={{...baseButtonStyle, backgroundColor: '#2f92c0'}}  key={`user_social_telegram_${user.id}`} onPress={() => {
                    let telegramLink = user.telegram.replace('@', '');
                    window.open( `https://telegram.me/${telegramLink}`);
                }}>
                    <FontAwesomeIcon size={'lg'} color={'white'} icon={'fa-brands fa-telegram'} />
                </Pressable>
            );
        }
        
        return items;
    }

    static buildDetails = (user, styles) => {
        let detailsArray = [];
        let i = 0;
        const {colors} = useTheme();
        let options = _.merge({
            gendericon : {
                size : 'lg'
            },
            text : {
                fontSize: 14,
                lineHeight: 20
            },
            flag : {
                size: 16,
                type : 'flat'
            }
        }, styles);
       

        /** Country Flag */
        if (!_.isEmpty(user.country) && user.country.length == 2) {
            //detailsArray.push(<Flag key={`user_detail_flat_${user.id}`} size={options.flag.size} type={options.flag.type} code={user.country} />);
        }

        /** Gender Icon */
        if (!_.isEmpty(user.gender)) {
            if (detailsArray.length > 0) 
                detailsArray.push(<Divider key={user.id+(i++)} orientation="vertical" style={{height: 20} } mx={2} />);

            detailsArray.push( <FontAwesomeIcon key={`user_detail_gender_${user.id}`} color={
                (user.gender == 'm') 
                    ? colors.primary['500'] 
                    : '#d63384' 
                } 
                icon={UserHelper.getGenderIcon(user)} size={options.gendericon.size} /> );
        }

        if (!_.isEmpty(user.birthday)) {
            if (detailsArray.length > 0) 
                detailsArray.push(<Divider key={user.id+(i++)} orientation="vertical" style={{height: 20} } mx={2} />);

            detailsArray.push(<Text key={`user_detail_age_${user.id}`} style={options.text}>{parseInt(UserHelper.getAge(user))} Jahre</Text>);
        }
        /*
        if (!_.isEmpty(user.relationship) && user.relationship != 'Keine Angabe') {
            if (detailsArray.length > 0) 
                detailsArray.push(<Divider key={user.id+(i++)} orientation="vertical" style={{height: 20} } mx={2} />);

            detailsArray.push(<Text key={`user_detail_relationsship_${user.id}`} style={options.text}>{user.relationship}</Text>);
        }*/

        if (!_.isEmpty(user.city)) {
            if (detailsArray.length > 0) 
                detailsArray.push(<Divider key={user.id+(i++)} orientation="vertical" style={{height: 20} } mx={2} />);

            detailsArray.push(<Text key={`user_detail_city_${user.id}`} style={options.text}>
                {user.city}
                {(user.region) ? ` (${user.region})` : ''}
                </Text>);
        }

        

        return detailsArray;
    }

    static getUserFlag = (user) => {
        if (user.country?.length == 2) {
            //return <Flag type={'flat'} code={user.country} size={16} />
        }
        return false;
    };

    static buildUsername = (user) => {
        let name = '';
        try {
            
            if (user.username?.length > 0) {
                name += ' ' + user.username;    
            } else {
                
                name = [user.name, user.lastname];
                name = name.join(' ');
            }
    
        } catch (error) {
            console.log('buildUsername');
            console.log('Error: ', error.message);
        }
    
        return _.trim(name);
    }

    static getGenderIcon = (user) => {
        if (user.gender == 'm') {
            return 'fa-regular fa-mars';
        }

        if (user.gender == 'w') {
            return 'fa-regular fa-venus';
        }

        return 'fa-regular fa-transgender';
    }

    static getAge = (user) => {
        if (user.birthday) {
            let birthday = new Moment(user.birthday);
            let now = new Moment();
            let diff = now.diff(birthday, 'years', true);
            return diff;
        }

        return false;
    }

    static isOnline = (user) => {
        let defaultLang = 'de';
        if (typeof user.settings == 'string') {
            let settings = JSON.parse(user.settings);
            if (typeof settings !== 'undefined' && typeof settings.language == 'string') {
                defaultLang = settings.language.toLowerCase();
            }
        }
        
        const now = new Moment();
        let dateObj = new Moment(user.last_login);  
        now.locale(defaultLang);
        dateObj.locale(defaultLang);

        let diff = now.diff(dateObj, 'minutes', true);
        
        if (diff <= 10) {
            return true;
        } else {
            return false;
        }
    }

    static getUserImagePath = (filename) => {
        let imagepath = '';
        try {
            if (filename == '' || filename == null || filename == 'undefined' || filename.indexOf('user_placeholder.jpg') != -1) {
                imagepath = Config.host + Config.userImagePlaceholder;
            } else {
                if (filename.indexOf('writable/uploads') != -1) {
                    imagepath = Config.host + filename;
                } 
                
            }
            
        } catch (error) {
        }
        
        if (imagepath == '' || imagepath == null || checkImageURL(imagepath) == false) {
            imagepath = Config.host + Config.userImagePlaceholder;
        }
        return imagepath;
    }

    static getUserimage = (user) => {
        return this.getUserImagePath(user.image);
    };
}