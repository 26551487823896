import React from "react";
import {View, Text, Spinner, Image, VStack, Input, HStack, Box, Button} from 'native-base';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import Loader from '~/Components/Template/Loader';
import Config from '~/Config/BaseConfig';
import _ from 'lodash';
import { simpleFormData } from "~/Helper/FormHelper";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';
import { ReactSession } from 'react-client-session';
import {initializePurchases} from '~/Helper/PurchaseHelper'

export default function Login() {
    
    const [userEmail, setUserEmail] = React.useState(null);
    const [userPassword, setUserPassword] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const SweetAlert = withReactContent(Swal);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const _submitForm = () => {
        if (!userEmail) {
            SweetAlert.fire('Bitte gibt eine E-Mail Adresse an');
            return;
        }

        if (!userPassword) {
            SweetAlert.fire('Bitte Passwort angeben');
            return;
        }
        
        setLoading(true);

        fetch(Config.host + '/api/auth/csrf/')
        .then((res) => res.json())
        .then((responseJSON) => {      
            if (responseJSON.status === 'success') {
                fetch(Config.host + '/api/auth/login/', {
                    method: 'POST',
                    body: simpleFormData({email: userEmail, password: userPassword}),
                    headers: {...Config.defaultHeaders},
                })
                .then((res) => res.json())
                .then((responseAuth) => {
                    setLoading(false);    
                
                    if (responseAuth.status === 'success') {
                        ReactSession.set('user', JSON.stringify(responseAuth.data));
                        dispatch(setUser(responseAuth.data));
                        initializePurchases();
                        navigate('/dashboard');
                    } else {
                        SweetAlert.fire('E-Mail oder Kennwort fehlerhaft');
                    }
                })       
            }
        })
        
    };


    return <VStack justifyContent={'center'} alignItems='center' w={'100vw'} h={'100vh'} backgroundColor={'#0f7dae'}>
            <Loader loading={loading} />
            <Image
                source={require('~/Assets/layout/logo@3x.png')}
                h={50}
                w={100}
                resizeMode={'contain'}
                style={{margin: 30}}
            />
            
            <VStack space={3} w={['3/4', '2/4', '2/4', '1/4']}>

                <View className={'ui input'}>
                    <Input onChangeText={(text) => setUserEmail(text)} type="text" placeholder="E-Mail" />
                </View>

                <HStack space={3} w={'100%'}>
                    <View flex={10} className={'ui input'}>
                        <Input secureTextEntry={secureTextEntry} type="text" onChangeText={(text) => setUserPassword(text)} placeholder="Kennwort" />
                    </View>
                    
                    <Button flex={1} onPress={() => setSecureTextEntry(!secureTextEntry)}  backgroundColor={'white'} size={'sm'}>
                        <FontAwesomeIcon color="#000000" size={'sm'} icon={secureTextEntry ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}   />
                    </Button>
                </HStack>

                <Button backgroundColor={'success.500'} onPress={_submitForm}>Anmelden</Button>
                
                <Button _text={{color: 'white'}} p={0} variant="unstyled" onPress={() => navigate('/auth/forgotten')}>Kennwort vergessen?</Button>
                <Button _text={{color: 'white'}} p={0} variant="unstyled" onPress={() =>  navigate('/auth/register/welcome')}>Neu hier? Jetzt registrieren</Button>

            </VStack>
            

            <Helmet>
                <title>Login</title>
                <meta name="description" content="Anmeldung bei Meet4Ride" />
            </Helmet>
    </VStack>;
}
