// Import React and Component
import * as React from 'react';
import { HStack, ScrollView, Box, Image, Flex, AspectRatio, Pressable, useTheme, Heading, VStack, View, Text, Spinner, useClipboard, Tooltip } from 'native-base';

import StylesConfig from '~/Config/Styles';
import Config from '~/Config/BaseConfig';
import Loader from '~/Components/Template/Loader';
import { simpleFormData } from '~/Helper/FormHelper';
import TourHelper from '~/Helper/TourHelper.js';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserPill from '~/Components/Users/UserPill';
import Description from '~/Components/Touren/Detail/Description';
import ActionBar from '~/Components/Touren/Detail/ActionBar';
import TourComments from '~/Components/Comments/CommentBox';
import TourUsers from '~/Components/Touren/Detail/TourUsers';
import FormatedView from '~/Components/Touren/Detail/FormatedView';
import DateHelper from '~/Helper/DateHelper';
import { useNavigate, useParams } from "react-router-dom";
import {fileExists, toasty} from '~/Helper/Base';
import DashedLine from '~/Components/Template/Elements/DashedLine';
import { GoogleMap, useJsApiLoader, Polyline, KmlLayer, TrafficLayer  } from '@react-google-maps/api';
//import * as Clipboard from 'expo-clipboard';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import _ from 'lodash';
import TourAnalyser from '~/Components/Touren/Detail/TourAnalyser';
import { Col, Row } from 'react-bootstrap';
import {Helmet} from "react-helmet";

const Detail = (props) => {
    const currentUser = useSelector((state) => state.user);
    const [tour, setTour] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [parentComment, setParentComment] = React.useState({});
    const [reloadComments, setReloadComments] = React.useState(true);
    const [layoutCords, setLayoutCords] = React.useState({});
    const [imageModal, setImageModalVisible] = React.useState(false);
    const { colors } = useTheme();
    const navigate = useNavigate();
    const params =  useParams();
    const {value, onCopy} = useClipboard();

    const [gpx, setGPX] = React.useState(null);
    const [routelink, setRoutelink] = React.useState(null);

    const [mapModal, setMapModalVisible] = React.useState(false);
    const [tracks, setTracks] = React.useState(null);
    const [mapSwitcher, setMapSwitcher] = React.useState(0);
    const [map, setMap] = React.useState(null)
    const mapStyles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Config.googleApiToken
    });

    const [initialCenter, setInitialCenter]  = React.useState(null);

    React.useEffect(() => {
        Moment.locale('de');
        
        if (currentUser) {
            setLoading(true);
            _fetchTour();
        }   
        
        return () => {
            _unsetData();
        }
    }, [currentUser, params]);
  
    const _fetchTour = async () => {
        setLoading(true);
        if (!_.isEmpty(currentUser)) {
        
        fetch(Config.host + '/api/touren/get/' + params.tour_id + '/', {
            method: 'POST',
            headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
            body: simpleFormData({'includes' : 'extended,guides,tourUsersExtended,tourAnalyser'})
        })
            .then((res) => res.json())
            .then((json) => {

                if (json?.status == '401') {
                    alert('Inhalt konnte nicht geladen werden');
                    navigate('/touren/all');
                }
                
                setTour(json);
                setLoading(false);
                        
                if (json?.extended?.gpx) {
                    fileExists(Config.uploadDir + 'gpx/' + json.extended.gpx)
                        .then(res => {
                            setTracks([]);
                            setGPX(Config.uploadDir + 'gpx/' + json.extended.gpx)
                            _fetchRoute(json.extended.gpx);
                        });
                } else {
                    setMapSwitcher(2);
                }

                if (json?.extended?.routelink) {
                    fileExists(json.extended.routelink)
                    .then(res => {
                        setRoutelink(_routLinkDefinition(json.extended.routelink));
                    });
                }

            })
            .catch(() => {
                alert('Inhalt konnte nicht geladen werden');
                navigate('/touren/all');
            });
        };
    };

    const _unsetData = async() => {
        setTour({});
        setLoading(true);
        setParentComment({});
        setLoading(true);
        setReloadComments(true);
        setImageModalVisible(false);
        setGPX(null);
        setRoutelink(null);
        setMapSwitcher(0);
        setTracks(null);
    }

    const _fetchRoute = async (file) => {
        if (!_.isEmpty(currentUser)) {
        fetch(Config.host + '/api/gpx/get/', {
            method: 'POST',
            headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
            body: simpleFormData({'file': file,'includes' : 'track'})
        })
        .then((res) => res.json())
            .then((json) => {
                let jsonString = JSON.stringify(json.track);
                let tracks = json.track;
                
                //jsonString = jsonString.replace(/lat/g, 'latitude');
                //jsonString = jsonString.replace(/lng/g, 'longitude');
                
                setTracks(JSON.parse(jsonString));
                setMapSwitcher(1);

                if (tracks.length > 0) {
                    setInitialCenter({
                        lat : tracks[Math.floor((tracks.length - 1) / 2)].lat,
                        lng: tracks[Math.floor((tracks.length - 1) / 2)].lng
                    })
                }

            });
        };

    }

    const _priceCondition = function(tour) {
        
        if (tour.extend == null) {
        return false;
        }

        if (typeof tour.extend == 'string') {
        tour.extend = JSON.parse(tour.extend);
        }
        
        if (tour.extend == null) {
            return false;
        }

        if (isNaN(+tour.extend.price)) {
            return false;
        } else {
            tour.extend.price = parseFloat(tour.extend.price);
        }

        if (tour.extend?.price > 0) {
            return tour.extend.price + ' €';
        }

        if (tour.extend.price == '0') {
            return 'Kostenlos';
        }

        
        return false;
    };
    
    const _routLinkDefinition = (link) => {
        
        let responseObj = {
            'title': 'Website aufrufen',
            'description' : null,
            'link': link
        };

        if (link.indexOf('calimoto') != -1) {
            responseObj.title = 'Calimoto';
            responseObj.description = 'Route auf Calimoto anschauen';
        } else if (link.indexOf('kurviger') != -1) {
            responseObj.title = 'Kurviger';
            responseObj.description = 'Route auf Kurviger.de anschauen';
        } else {
            responseObj.description = link;
        }

        return responseObj;
    };

    const followLink = (url, type = null) => {
        if (url) {
            if (type == 'download') {
                
                let fileLoc = url;
                    fileLoc = fileLoc.replace(Config.host, '');
                    fileLoc = fileLoc.split('/');

                return window.open(Config.host + '/common/download/force/gpx/' + encodeURI(fileLoc[fileLoc.length-1]));
            }

            return window.open(url);
        }
    };

    const copyToClipboard = async (string) => {
        onCopy(string);
        toasty('In die Zwischenablage kopiert');
    };
    
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


  if (loading == true) {

    return (<ScrollView><View style={{ flex: 1 }}><Loader loading={loading} /></View></ScrollView>);

  } else {

    return (
      <View style={{ flex: 1 }}>
        
          <ScrollView>
          <Helmet><title>{tour.extended.title} • {Moment(tour.extended.start_date).format('LLL')}</title></Helmet>
            <Loader loading={loading} />
            <View style={{ flex: 1, padding: 0, marginBottom: 80 }}>
                
              <View style={StylesConfig.styles.card.wrapperMt}>
                <View style={StylesConfig.styles.card.header}><Text style={StylesConfig.styles.card.header_text}>Tour Details</Text></View>
                <View style={StylesConfig.styles.card.body}>
                  
                    <Row>
                        <Col md={6}>
                            <AspectRatio flex={1} justifyContent='center' alignContent={'center'} ratio={{ base: 1 / 1 }} height={{ base: 'auto' }}>
                                <View>
                                    {mapSwitcher == 0 && // Erstmal Ladebalken anzeigen
                                        <VStack flex={1} alignItems={'center'} justifyContent={'center'} bgColor={'muted.200'} style={{width:'100%', height:'100%'}}>
                                            <Spinner size="large" color={colors.info['500']} />
                                            <Text>Vorschau wird geladen ...</Text>
                                        </VStack>
                                    }

                                    {mapSwitcher == 1 && initialCenter &&
                                        
                                        <GoogleMap
                                            mapContainerStyle={{width: '100%', height: '100%'}}
                                            center={initialCenter}
                                            zoom={10}
                                            showsTraffic={true}
                                            onLoad={(map) => setMap(map)}
                                            onUnmount={onUnmount}
                                        >
                                            <Polyline options={{
                                                strokeWeight: 6,
                                                strokeColor: "#FF000080"
                                            }}  path={tracks} />

                                            <TrafficLayer />

                                        </GoogleMap>
                                        
                                    }

                                    {mapSwitcher == 1 && !initialCenter &&
                                        <img src={tour.extended.image} className={'img-fluid'} />
                                    }

                                    

                                    {mapSwitcher == 2  &&
                                        <Pressable onPress={() => setImageModalVisible(true)}>
                                            <img src={tour.extended.image} className={'img-fluid'} />
                                        </Pressable>
                                    }

                                </View>
                            </AspectRatio>

                            {(routelink || gpx) && 
                                
                                <VStack mt={5}>

                                    {gpx && 
                                        <Pressable 
                                            onPress={() => followLink(gpx, 'download')} 
                                            onLongPress={() => copyToClipboard(gpx)}
                                            style={{flex:1, marginVertical: 5}}
                                            >
                                            <HStack justifyContent={'flex-start'} alignItems={'center'} bgColor={'muted.100'}>
                                                <Box marginRight={2.5} alignItems={'center'} justifyContent={'center'} w={49} h={49} bgColor={'info.500'}>
                                                    <FontAwesomeIcon size={'2xl'} color={'white'} icon={'fa-solid fa-arrow-down-to-line'} />
                                                </Box>
                                                <Box>
                                                    <Heading fontSize={'md'}>GPX Daten herunterladen</Heading>
                                                    <Text fontSize={10}>Lade dir die Tour einfach für dein Navi herunter</Text>
                                                </Box>
                                            </HStack>
                                        </Pressable>
                                    }

                                    {routelink && 

                                        <Pressable 
                                            onPress={() => followLink(routelink.link)} 
                                            style={{flex:1, marginVertical: 5}}
                                            >
                                            <HStack justifyContent={'flex-start'} alignItems={'center'} bgColor={'muted.100'}>
                                                <Box marginRight={2.5} alignItems={'center'} justifyContent={'center'} w={49} h={49} bgColor={'info.500'}>
                                                    <FontAwesomeIcon size={'2xl'} color={'white'} icon={'fa-solid fa-link'} />
                                                </Box>
                                                <Box>
                                                    <Heading fontSize={'md'}>{routelink.title}</Heading>
                                                    {routelink.description && 
                                                        <Text fontSize={10} >{routelink.description}</Text>
                                                    }
                                                </Box>
                                            </HStack>
                                        </Pressable>
                                    }

                                </VStack>

                            }
                        </Col>
                        
                        <Col md={6} className={'mt-5 mt-md-0'}>
                            <View style={{ flex: 1, flexDirection: 'row', marginBottom: 10, marginRight: 15 }}>
                                
                                {TourHelper.getSpeedIndicator(tour.extended)}

                                <View style={{ padding: 15, paddingRight: 40, width: '100%' }}>

                                {TourHelper.getFormattedTourStatus(tour.extended)}

                                <Heading style={{
                                    fontSize: 25,
                                    fontWeight: 'bold',
                                    color: colors.primary['600'],
                                }}>{tour.extended.title}</Heading>

                                {TourHelper.getLabelsBar(tour.extended.extend, {
                                    top: 0, right: 0,position:'relative', marginTop: 15, transform: [{scale: 1.0}]
                                })}

                                <DashedLine dashGap={5} dashColor={StylesConfig.color.lightGray} dashStyle={{ marginTop: 15, marginBottom: 15 }} />

                                <Box _text={{
                                    fontSize: 'md'
                                }}>{TourHelper.getDetailBar(tour.extended)}</Box>

                                </View>
                        
                            </View>

                            <View style={{ marginTop: 25, ...styles.detail_list_container }}>
                                <Text style={styles.detail_list_item_small}>Treffpunkt:</Text>
                                <HStack space={3} justifyContent={'space-between'} alignItems={'center'}>

                                    <Text numberOfLines={2} isTruncated style={{...styles.detail_list_item_text}}>
                                        {tour.extended.start_location}
                                    </Text>

                                    <HStack space={4} justifyContent={'flex-end'} alignItems={'center'}>
                                        <Tooltip label={'In die Zwischenablage kopieren'} placement={'top'} openDelay={250}>
                                            <Pressable onPress={() => copyToClipboard(tour.extended.start_location)} style={{maxWidth: '80%'}}>
                                                <FontAwesomeIcon size={'2x'} icon={'fa-duotone fa-clipboard'} />
                                            </Pressable>
                                        </Tooltip>

                                        <Tooltip label={'Auf Google Maps anzeigen'} placement={'top'} openDelay={250}>
                                            <Pressable 
                                                ml={19} 
                                                onPress={() => { (tour.extended.lat && TourHelper.getMapsLink(tour.extended.lat, tour.extended.lng, tour.extended.start_location)) }}
                                                >
                                                <FontAwesomeIcon size={'2x'} color={colors.primary['600']} icon="fa-duotone fa-map-location-dot" />
                                            </Pressable>
                                        </Tooltip>
                                    </HStack>
                                </HStack>
                            </View>

                            <View style={styles.detail_list_container}>
                                <Text style={styles.detail_list_item_small}>Wettervorhersage:</Text>
                                
                                <Flex flexDirection='row'>
                                
                                <Pressable flex={1} alignItems={'center'} flexDirection={'row'} onPress={() => { (tour.extended.lat && TourHelper.getWetherLink(tour.extended.lat, tour.extended.lng, 'ten-days')) }}>
                                    <FontAwesomeIcon icon="fa-solid fa-square-up-right" style={styles.list_item_external_icon} />
                                    <Text style={styles.detail_list_item_text}>10 Tage</Text>
                                </Pressable>

                                <Pressable flex={1} alignItems={'center'} flexDirection={'row'} onPress={() => { (tour.extended.lat && TourHelper.getWeatherLink(tour.extended.lat, tour.extended.lng, 'hours')) }}>
                                    <FontAwesomeIcon icon="fa-solid fa-square-up-right" style={styles.list_item_external_icon} />
                                    <Text style={styles.detail_list_item_text}>Stündlich</Text>
                                </Pressable>
                                
                                </Flex>
                            </View>
                            

                            {tour.extended.duration > 0 &&
                                <FormatedView title={'Datum:'} value={DateHelper.formatDate(tour.extended.start_date, 'DD.MM.YYYY')} />
                            }

                            {tour.extended.duration > 0 &&
                                <FormatedView title={'Uhrzeit:'} value={DateHelper.formatDate(tour.extended.start_date + ' ' + tour.extended.start_time, 'HH:mm')} />
                            }

                            {tour.extended.duration > 0 &&
                                <FormatedView title={'Dauer:'} value={ DateHelper.toHoursAndMinutes(tour.extended.duration, true) + ( (tour.extended.duration == 1) ? ' Stunde' : ' Stunden' )} />
                            }

                            {tour.extended.km > 0 && (tour.extended.tourtype == 'tour' || tour.extended.tourtype == 'quicktrip') &&
                                <FormatedView title={'Streckenlänge:'} value={`${tour.extended.km} km`} />
                            }

                            {(tour.extended.tourtype == 'tour' || tour.extended.tourtype == 'quicktrip') &&
                                <FormatedView title={'Fahrstil:'} value={tour.extended.fahrstil} />
                            }

                            {(tour.extended.tourtype == 'training' || tour.extended.tourtype == 'event') && _priceCondition(tour.extended) !== false &&  
                                <FormatedView title={'Teilnahmegebühr:'} value={_priceCondition(tour.extended)} />
                            }

                            {(tour.extended.tourtype == 'tour' || tour.extended.tourtype == 'quicktrip') && _priceCondition(tour.extended) !== false &&
                                <FormatedView title={'Teilnahmegebühr:'} value={_priceCondition(tour.extended)} />
                            }

                            {(tour.extended.tourtype == 'tour' || tour.extended.tourtype == 'quicktrip' || tour.extended.tourtype == 'training') &&
                                <FormatedView title={(tour.extended.tourtype == 'training') ? 'Gelände:' : 'Streckentyp:'} value={tour.extended.routetype} />
                            }

                            <View style={styles.detail_list_container}>
                                <Text style={styles.detail_list_item_small}>
                                {tour.extended.tourtype == 'training' && 'Trainer'}
                                {(tour.extended.tourtype == 'tour' || tour.extended.tourtype == 'quicktrip') && 'Guide'}
                                {tour.extended.tourtype == 'event' && 'Organisator'}
                                </Text>
                                <Flex flexDirection='row'>

                                <UserPill user={tour.guides.guide} />
                                {parseInt(tour.extended.sec_guide_id) >= 1 && tour.guides.sec_guide !== null && <UserPill user={tour.guides.sec_guide} />}

                                </Flex>
                            </View>
                            
                            {(tour.extended.tourtype != 'event') && tour.extended.intercom != 'Keins' &&
                                <FormatedView title={'Bevorzugte Intercom:'} value={tour.extended.intercom} />
                            }
                        </Col>
                    </Row>

                
                

                  

                </View>
              </View>
                
              <View onLayout={(event) => { setLayoutCords({ ...layoutCords, description: event.nativeEvent.layout.y }) }}></View>
               
              {tour.extended.route && 
                <Description tour={tour.extended} />
              }
             

              <View style={StylesConfig.styles.card.wrapperMt}>
                  <View style={StylesConfig.styles.card.header}>
                      <Text style={StylesConfig.styles.card.header_text}>Teilnehmerliste</Text>
                  </View>
                  
                  <View style={StylesConfig.styles.card.body}>
                    {(!_.isEmpty(tour.tourUsersExtended['yes']) || !_.isEmpty(tour.tourUsersExtended['maybe'])) && 
                      <TourUsers tourUsers={tour.tourUsersExtended} />
                    }
                    {(_.isEmpty(tour.tourUsersExtended['yes']) && _.isEmpty(tour.tourUsersExtended['maybe'])) && 
                      <Text style={{fontStyle: 'italic'}}>Bisher keine Teilnehmer</Text>
                    }
                  </View>
              </View>
              
              

              <View onLayout={(event) => { setLayoutCords({ ...layoutCords, comments: event.nativeEvent.layout.y }) }}></View>
              
              <View style={StylesConfig.styles.card.wrapperMt}>
                <View style={StylesConfig.styles.card.header}>
                  <Text style={StylesConfig.styles.card.header_text}>Kommentare</Text>
                </View>

                <View style={StylesConfig.styles.card.body}>

                <TourComments
                    handler={{
                      setParentComment: setParentComment,
                      setReloadComments: setReloadComments
                    }}
                    handlerProps={{
                      reloadComments: reloadComments
                    }}
                    master_id={tour.extended.tour_id}
                    type={'tour'}
                    key={'tourCommentsBox'}
                  />

                </View>
              </View>
            
            {tour?.tourAnalyser?.length > 0 &&
                <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={{...StylesConfig.styles.card.header_text, fontWeight: 'bold'}}>GUIDE INFO</Text>
                    </View>
                    
                    <View style={StylesConfig.styles.card.body}>
                        <TourAnalyser data={tour?.tourAnalyser} />
                    </View>
                </View>
              }

            </View>
            <View style={{ flex: 1 }} />

          </ScrollView>
                      
          <ActionBar
            key="TourenDetailActionBar"
            tour={tour.extended}
            tourFull={tour}
            tourUsers={tour.tourUsersExtended}
            handler={{
              setParentComment: setParentComment,
              setReloadComments: setReloadComments,
              refreshTour : _fetchTour,
              setLoading : setLoading
            }}
            handlerProps={{
              parentComment: parentComment,
              reloadComments: reloadComments
            }} />

        
      </View>
    );

  }
};

const styles = {
  detail_list_container: {
    padding: 10,
    marginBottom: 15

  },
  detail_list_item_small: {
    color: StylesConfig.color.middleGray,
    textTransform: 'uppercase',
    marginBottom: 5
  },
  detail_list_item_text: {
    fontSize: 16
  },
  list_item_address_icon: {
    color: StylesConfig.color.danger,
    marginRight: 5
  },
  list_item_default_icon: {
    marginRight: 5
  },
  list_item_external_icon: {
    color: StylesConfig.color.base,
    marginRight: 5
  }
};

export default Detail;