export default {
    host : 'https://m4r.devserver01.de',
    uploadDir : 'https://m4r.devserver01.de' + '/writable/uploads/',
    customFonts : {
        'Quicksand': require('~/Assets/fonts/Quicksand.ttf'),
        'Quicksand-700': require('~/Assets/fonts/Quicksand-700.ttf'),
    },

    /* User */
    userImagePlaceholder : '/common/assets/images/user_placeholder.jpg',

    /* GOOGLE API */
    googleApiToken : 'AIzaSyAMm1Dh4PMvptPSq5NCbgy8_OVA8jHeSVk',
    googleAdSenseToken : 'ca-pub-4491695772246119',

    expo : {
        accessToken : '_Y-Wl-7ZpCO4IdYKy76O0Ex8fPmXW49jXMzHFNDw',
        experianceId : '@milchshake/m4r'
    },
    
    usercentrics_id : 'LqDy-Ujb5',

    defaultContentType : 'application/x-www-form-urlencoded;charset=UTF-8',
    defaultHeaders : { 'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8' },
    defaultHeadersJson : { 'Content-Type' : 'application/json' },

    openAiApiToken: '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', 
    
    qonversion : {
        projectKey: 'oC440K_9BCoe3J3LedUd9NtypjMCCqfh',
    }

};