import React from 'react';

import { View, Box, HStack, VStack, Text, useTheme, Pressable, Switch, Button, Heading} from "native-base";
import Loader from '~/Components/Template/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from "lodash";
import UserHelper from "~/Helper/UserHelper";
import StylesConfig from '~/Config/Styles';
import { ReactSession } from 'react-client-session';
import {toasty} from '~/Helper/Base';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';

import { isBrowser, isMobile } from 'react-device-detect';
import { unsetUser } from '~/Services/UserSlice';

const Settings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.user);
    const SweetAlert = withReactContent(Swal);
    const { colors } = useTheme();
    const [loading, setLoading] = React.useState(true);
    const [formData, setFormData] = React.useState({});

    const formDefaults = {
        'settings' : {
            'darkmode' : false,
            'language' : 'DE'
        },
        'notificationsettings' : {
            'tour_stvo': false,
            'tour_stvo10': false,
            'tour_stvo20': false,
            'tour_stvo30': false,
            'tour_training': false,
            'tour_event': false,
            'tour_notify': false,
            'tour_comment': false,
            'tour_attend': false,
            'forum': false,
            'forum_comment': false
        }
    };

    React.useEffect(() => {
        if (currentUser && loading) {
            _onMount();
        }
    }, [currentUser])

    const _onMount = async () => {
        if (!loading) setLoading(true);

        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/get/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'includes': 'userdata'})
            })
            .then((res) => res.json())
            .then((json) => {

                let importJson = {};
                
                if ('notificationsettings' in json.userdata) {
                    json.userdata.notificationsettings = JSON.parse(json.userdata.notificationsettings);
                    Object.keys(json.userdata.notificationsettings).forEach((key) => {
                        if (json.userdata.notificationsettings[key] == 'true') {
                            json.userdata.notificationsettings[key] = true;
                        }
                    })

                    importJson = {notificationsettings : json.userdata.notificationsettings};
                }

                if ('settings' in json.userdata) {
                    json.userdata.settings = JSON.parse(json.userdata.settings);
                    Object.keys(json.userdata.settings).forEach((key) => {
                        if (json.userdata.settings[key] == 'true') {
                            json.userdata.settings[key] = true;
                        }
                    })
                    importJson = {...importJson, settings : json.userdata.settings};
                }

                setFormData({...formDefaults, ...importJson});
                setLoading(false);
            });

        };
    };

    const _onUnMount = async () => {
        if (!loading) setLoading(true);
        
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/set/userdata/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'key': 'notificationsettings', 'value_json' : JSON.stringify(formData.notificationsettings)})
            }).then(() => {
                fetch(Config.host + '/api/user/action/set/userdata/' + currentUser.data.id +'/', {
                    method: 'POST',
                    headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                    body : simpleFormData({'key': 'settings', 'value_json' : JSON.stringify(formData.settings)})
                }).then(() => {
                    toasty('Daten gespeichert', 'success');
                    setLoading(false);
                });
            });
        };
            
    };

    const iconStyle = {
        'tour_stvo' : {color: StylesConfig.color.success, icon :'fa-regular fa-tachometer-slow'},
        'tour_stvo10' : {color: StylesConfig.color.warning, icon :'fa-regular fa-tachometer-average'},
        'tour_stvo20' : {color: StylesConfig.color.danger, icon :'fa-regular fa-tachometer-fast'},
        'tour_stvo30' : {color: colors.danger['700'], icon :'fa-regular fa-tachometer-fastest'},
        'tour_training' : {color: StylesConfig.color.base, icon :'far fa-graduation-cap'},
        'tour_event' : {color: StylesConfig.color.warning, icon :'fab fa-angellist'},
    };

    const updateFormData = function(section, field, value) {
        let _data = {...formData};
        
        if (!section in _data) {
            _data[section] = {};
        }

        _data[section][field] = value;
        setFormData(_data);
    };

    if (!formData) {
        return (<><Loader loading={loading} /></>);
    } else {
        return (
            <>
                <Loader loading={loading} />
                <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <VStack justifyContent={'space-between'} space={3}>
                            <Text style={{...StylesConfig.styles.card.header_text}}>Allgemeine Einstellungen</Text>
                        </VStack>
                        
                    </View>

                    <View style={{...StylesConfig.styles.card.body}}>
                        <Pressable onPress={() => updateFormData('settings', 'darkmode', !formData.settings.darkmode)}>
                            <HStack maxW={'100%'} space={5} justifyContent={'flex-start'}>
                                <Switch size="sm" isChecked={formData?.settings?.darkmode} onToggle={() => updateFormData('settings', 'darkmode', !formData.settings.darkmode)}/>
                                <VStack maxW={'80%'}>
                                    <Heading size={'sm'}>Dark-Mode Darstellung (Beta)</Heading>
                                    <Text>Nachtmodus</Text>    
                                </VStack>
                            </HStack>
                        </Pressable>
                    </View>
                    
                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15, zIndex: 2}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
                
                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <VStack justifyContent={'space-between'} space={3}>
                            <Text style={{...StylesConfig.styles.card.header_text}}>Benachrichtigungen</Text>
                        </VStack>
                    </View>

                    <View style={{...StylesConfig.styles.card.body}}>
                        <Heading size={'md'} color={StylesConfig.color.base}>Bereich: Tour-Arten</Heading>
                        
                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'tour_stvo', !formData.notificationsettings.tour_stvo)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_stvo} onToggle={() => updateFormData('notificationsettings', 'tour_stvo', !formData.notificationsettings.tour_stvo)}/>
                            
                                <Box justifyContent={'center'} alignItems={'center'} backgroundColor={iconStyle['tour_stvo'].color} style={{width: 35, height: 35}}>
                                    <FontAwesomeIcon color={'white'} icon={iconStyle['tour_stvo'].icon}/>
                                </Box>
                                
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über  
                                        <Text fontWeight={'bold'}> gemütliche Touren</Text>
                                    </Text>
                                    <Text fontSize={'xs'}>Touren mit: StVo konform</Text>        
                                </VStack>
                            
                            </HStack>
                        </Pressable>

                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'tour_stvo10', !formData.notificationsettings.tour_stvo10)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_stvo10} onToggle={() => updateFormData('notificationsettings', 'tour_stvo10', !formData.notificationsettings.tour_stvo10)}/>
                            
                                <Box justifyContent={'center'} alignItems={'center'} backgroundColor={iconStyle['tour_stvo10'].color} style={{width: 35, height: 35}}>
                                    <FontAwesomeIcon color={'white'} icon={iconStyle['tour_stvo10'].icon}/>
                                </Box>
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über 
                                        <Text fontWeight={'bold'}> normale Touren </Text>
                                    </Text>        
                                    <Text fontSize={'xs'}>Touren mit: normal (StVO +10 km/h)</Text>
                                </VStack>
                                
                            </HStack>
                        </Pressable>

                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'tour_stvo20', !formData.notificationsettings.tour_stvo20)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_stvo20} onToggle={() => updateFormData('notificationsettings', 'tour_stvo20', !formData.notificationsettings.tour_stvo20)}/>
                            
                                <Box justifyContent={'center'} alignItems={'center'} backgroundColor={iconStyle['tour_stvo20'].color} style={{width: 35, height: 35}}>
                                    <FontAwesomeIcon color={'white'} icon={iconStyle['tour_stvo20'].icon}/>
                                </Box>
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über 
                                        <Text fontWeight={'bold'}> zügige Touren </Text>
                                    </Text>        
                                    <Text fontSize={'xs'}>Touren mit: zügig (StVO +20 km/h)</Text>
                                </VStack>
                            
                            </HStack>
                        </Pressable>

                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'tour_stvo30', !formData.notificationsettings.tour_stvo30)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_stvo30} onToggle={() => updateFormData('notificationsettings', 'tour_stvo30', !formData.notificationsettings.tour_stvo30)}/>
                            
                                <Box justifyContent={'center'} alignItems={'center'} backgroundColor={iconStyle['tour_stvo30'].color} style={{width: 35, height: 35}}>
                                    <FontAwesomeIcon color={'white'} icon={iconStyle['tour_stvo30'].icon}/>
                                </Box>
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über 
                                        <Text fontWeight={'bold'}> schnelle Touren </Text>
                                    </Text>        
                                    <Text fontSize={'xs'}>Touren mit: schnell (keine StVO)</Text>
                                </VStack>
                                
                            </HStack>
                        </Pressable>

                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'tour_training', !formData.notificationsettings.tour_training)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_training} onToggle={() => updateFormData('notificationsettings', 'tour_training', !formData.notificationsettings.tour_training)}/>
                                
                                <Box justifyContent={'center'} alignItems={'center'} backgroundColor={iconStyle['tour_training'].color} style={{width: 35, height: 35}}>
                                    <FontAwesomeIcon color={'white'} icon={iconStyle['tour_training'].icon}/>
                                </Box>
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über 
                                        <Text fontWeight={'bold'}> neue Trainings</Text>
                                    </Text>        
                                </VStack>
                                
                            </HStack>
                        </Pressable>

                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'tour_event', !formData.notificationsettings.tour_event)}>
                            
                            <HStack space={5} justifyContent={'flex-start'}>
                            
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_event} onToggle={() => updateFormData('notificationsettings', 'tour_event', !formData.notificationsettings.tour_event)}/>
                                
                                <Box justifyContent={'center'} alignItems={'center'} backgroundColor={iconStyle['tour_event'].color} style={{width: 35, height: 35}}>
                                    <FontAwesomeIcon color={'white'} icon={iconStyle['tour_event'].icon}/>
                                </Box>
                                
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über 
                                        <Text fontWeight={'bold'}> neue Events</Text>
                                    </Text>        
                                </VStack>
                            </HStack>
                        
                        </Pressable>

                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'tour_notify', !formData.notificationsettings.tour_notify)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_notify} onToggle={() => updateFormData('notificationsettings', 'tour_notify', !formData.notificationsettings.tour_notify)}/>
                                <VStack>
                                    <Text>Erinnerung
                                        <Text fontWeight={'bold'}> zwei Stunden vor Beginn </Text>
                                        einer Tour
                                    </Text>        
                                </VStack>
                                
                            </HStack>
                        </Pressable>

                        <Pressable mt={5} mb={5} onPress={() => updateFormData('notificationsettings', 'tour_comment', !formData.notificationsettings.tour_comment)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_comment} onToggle={() => updateFormData('notificationsettings', 'tour_comment', !formData.notificationsettings.tour_comment)}/>

                                <VStack>
                                    <Text>Werde benachrichtigt, sobald jemand einen Kommentar in einer deiner teilnehmenden Touren hinterlassen hat.
                                    </Text>        
                                </VStack>
                            </HStack>
                        </Pressable>

                        <Heading mt={5} size={'md'} color={StylesConfig.color.base}>Bereich: Tour-Guide</Heading>

                        <Pressable mt={5} mb={5} onPress={() => updateFormData('notificationsettings', 'tour_attend', !formData.notificationsettings.tour_attend)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.tour_attend} onToggle={() => updateFormData('notificationsettings', 'tour_attend', !formData.notificationsettings.tour_attend)}/>
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über
                                        <Text fontWeight={'bold'}> neue Fahrer zu- & absagen</Text>
                                    </Text>         
                                </VStack>
                            </HStack>
                        </Pressable>

                        <Heading mt={5} size={'md'} color={StylesConfig.color.base}>Bereich: Forum/Beiträge</Heading>
                        
                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'forum', !formData.notificationsettings.forum)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.forum} onToggle={() => updateFormData('notificationsettings', 'forum', !formData.notificationsettings.forum)}/>
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über
                                        <Text fontWeight={'bold'}> neue Beiträge</Text>
                                    </Text>         
                                </VStack>
                            </HStack>
                        </Pressable>

                        <Pressable mt={5} onPress={() => updateFormData('notificationsettings', 'forum_comment', !formData.notificationsettings.forum_comment)}>
                            <HStack space={5} justifyContent={'flex-start'}>
                                <Switch size="lg" isChecked={formData?.notificationsettings?.forum_comment} onToggle={() => updateFormData('notificationsettings', 'forum_comment', !formData.notificationsettings.forum_comment)}/>
                                <VStack>
                                    <Text>Erhalte Benachrichtigungen über
                                        <Text fontWeight={'bold'}> neue Kommentare von Beiträgen</Text>
                                    </Text>         
                                </VStack>
                            </HStack>
                        </Pressable>


                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15, zIndex: 2}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
            </>
        );
    }
};

export default Settings;