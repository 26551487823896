import * as React from 'react';
import { HStack, Pressable, Center, Text, Badge, useTheme, VStack, View, Divider} from 'native-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import uuid from 'react-uuid';
import Draggable from 'react-draggable';
import useBreakpoint from 'bootstrap-5-breakpoint-react-hook';

const ActionBarItem = ({item}) => {
    
    const _activeFilterCount = (context) => {
    
        let counter = 0;
    
        if ('filter' in context && typeof context.filter == 'object') {
            Object.keys(context.filter).map((item) => {
                if (['start_location_latlng', 'limit', 'page', 'active'].includes(item) == false) {
                    if (context.filter[item] != null) {
                        counter++;
                    }
                }
            });
        }

        return counter;  
    };

    return (
        <Pressable alignItems='center' opacity={0.8} py="3" flex={1} onPress={item.onPress}>
            <Center>
                <FontAwesomeIcon size={"2xl"} color='white' icon={item.icon.icon} />
                <Text color="white" style={{textTransform: 'uppercase'}} fontSize="12">{item.name.toUpperCase()}</Text>

                {item.name == 'Filter' && _activeFilterCount(item.context) > 0 &&
                    <Badge bg={'coolGray.300:alpha.80'} alignSelf={'flex-end'} p={0} w={15} h={15} position={'absolute'} rounded={'full'} _text={{fontSize: 10}} top={-10} right={-10}>
                        {_activeFilterCount(item.context)}
                    </Badge>
                }
            </Center>
        </Pressable>
    );
}

const ActionBarHelper = (props) => {
    const {colors} = useTheme();
    const breakpoint = useBreakpoint();

    if (breakpoint < 400) {
        
        return (
            <div style={{ left:0, bottom: 0, width: '100vw', position: 'fixed', backgroundColor: colors.primary['600']}} >
                <HStack justifyContent={'space-between'} alignSelf='center'>
                    
                    {props.items.map((item, index) => {
                        if (_.isEmpty(item)) 
                            return;
    
                        if (item.menu)
                            return (item.menu);
                        
                        return <ActionBarItem key={uuid()} item={item}/>     
                    })}
                </HStack>
            </div>
        );

    } else {
        
        return (
            <Draggable axis="y">
                <div style={{ right:0, width: 80, top: '10%', position: 'fixed', backgroundColor: colors.primary['600']}} >
                    <VStack>
                        <div style={{cursor: 'grab'}}>
                            <VStack backgroundColor={colors.primary['700']} p={2} style={{paddingLeft: 15, paddingRight: 15}}>
                                <Divider style={{marginBottom: 1}}/>
                                <Divider style={{marginBottom: 1}}/>
                                <Divider style={{marginBottom: 1}}/>
                            </VStack>
                        </div>

                        <VStack justifyContent={'space-between'} alignSelf='center'>
                            
                            {props.items.map((item, index) => {
                                if (_.isEmpty(item)) 
                                    return;
            
                                if (item.menu)
                                    return (item.menu);
                                
                                return <ActionBarItem key={uuid()} item={item}/>     
                            })}
                        </VStack>
                    </VStack>
                </div>
            </Draggable>
        );

    }

    
  
  };

  export default ActionBarHelper;