import React from 'react';

import { View, Box, HStack, VStack, Text, useTheme, Pressable, Switch, Button} from "native-base";
import Loader from '~/Components/Template/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from "lodash";
import UserHelper from "~/Helper/UserHelper";
import StylesConfig from '~/Config/Styles';
import { ReactSession } from 'react-client-session';
import {toasty} from '~/Helper/Base';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';

import { isBrowser, isMobile } from 'react-device-detect';
import { unsetUser } from '~/Services/UserSlice';

const Personal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.user);
    const SweetAlert = withReactContent(Swal);
    const regExUrl = new RegExp("^(http|https)://", "i");
    const { colors } = useTheme();
    const [loading, setLoading] = React.useState(true);
    const [formData, setFormData] = React.useState({});

    const [changePassword, setChangePassword] = React.useState(false);
    const [passwordVisibility, setPasswordVisibility] = React.useState(false);
    const [passwordStrength, setPasswordStrength] = React.useState({
        'chars8' : false,
        'oneupper' : false,
        'onelower' : false,
        'onedigit' : false,
        'onespecial' : false,
    });

    React.useEffect(() => {
        if (currentUser && loading) {
            _onMount();
        }
    }, [currentUser])

    React.useEffect(() => {
        if (!changePassword) {
            setFormData({...formData, pin: null});
        }
    }, [changePassword])

    const _onMount = async () => {
        if (!loading) setLoading(true);

        if (!_.isEmpty(currentUser)){
            fetch(Config.host + '/api/user/get/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'includes': 'userdata'})
            })
            .then((res) => res.json())
            .then((json) => {
                setFormData({email: json.userdata.email, pin: null});
                setLoading(false);
            });
        };
    };

    const _onUnMount = async (data) => {
        if (!loading) setLoading(true);
       
        if (!_.isEmpty(currentUser)){
            let jsonFormData = formData;

            if (!changePassword && 'pin' in jsonFormData) {
                delete jsonFormData['pin'];
            }
            
            delete jsonFormData['email'];

            if (!_.isEmpty(jsonFormData)) {
                fetch(Config.host + '/api/user/action/set/userdata/' + currentUser.data.id +'/', {
                    method: 'POST',
                    headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                    body : simpleFormData({'multi': JSON.stringify(jsonFormData)})
                }).then(() => {
                    toasty('Daten gespeichert', 'success');
                    
                    ReactSession.set('user', null);
                    dispatch(unsetUser());
                    navigate('/auth/login');
                });
                
                
            }
        };
    };


    const checkPasswordStrength = (text) => {

        const chars8 = new RegExp('(?=.{8,})');
        const oneupper = new RegExp('(?=.*[A-Z])');
        const onelower = new RegExp('(?=.*[a-z])');
        const onedigit = new RegExp('(?=.*[0-9])');
        const onespecial = new RegExp('([^A-Za-z0-9])');

        setPasswordStrength({
            'chars8' : chars8.test(text),
            'oneupper' : oneupper.test(text),
            'onelower' : onelower.test(text),
            'onedigit' : onedigit.test(text),
            'onespecial' : onespecial.test(text),
        });

        setFormData({...formData, pin: text});
    }

    

    if (!formData) {
        return (<><Loader loading={loading} /></>);
    } else {
        return (
            <>
                <Loader loading={loading} />
                <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <HStack justifyContent={'space-between'} alignItems={'center'}>
                            <Text style={StylesConfig.styles.card.header_text}>Anmeldedaten</Text>
                        </HStack>
                        
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <Box>Bitte beachte, das du nach ändern des Kennworts automatisch ausgeloggt wirst.
                            Du musst dich dann aus Sicherheitsgründen neu anmelden. 
                        </Box>

                        <View h={25}/>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="E-Mail"
                            >
                            <input
                                className='form-control'
                                type={'text'}
                                readOnly
                                disabled 
                                value={formData?.email} 
                                onChange={(e) => {
                                    setFormData({...formData, email: e.target.value});
                                }} 
                                />
                        </FloatingLabel>
                        <small className="mb-3">Zum ändern der E-Mail Adresse bitte den Support kontaktieren</small>

                        <View h={25}/>

                        <Pressable onPress={() => setChangePassword(!changePassword)}>
                            <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                <Switch size="sm" isChecked={changePassword} onToggle={() => setChangePassword(!changePassword)}/>
                                <Box>Kennwort ändern? </Box>
                            </HStack>
                        </Pressable>
                        
                        <View h={15}/>
                        
                        {changePassword && 
                            <>
                             <HStack>
                             
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Neues Kennwort"
                                    className="mb-3"
                                    style={{flex: 11}}
                                    >
                                        <input
                                            className='form-control'
                                            type={passwordVisibility ? 'text' : 'password'} 
                                            value={formData.pin} 
                                            onChange={(e) => checkPasswordStrength(e.target.value)} 
                                            />
                                    
                                    
                                </FloatingLabel>
                                
                                <Button onPress={() => setPasswordVisibility(!passwordVisibility)}  backgroundColor={'white'} size={'xs'}>
                                    <FontAwesomeIcon size={'sm'} color="#000000" icon={ passwordVisibility ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}   />
                                </Button>

                            </HStack>
                            {/*
                            
                            */}
                            
                            
                                <VStack mt={2}>
                                    <Text fontWeight={'bold'}>Empfehlung: </Text>
                                    <HStack space={2} mb={1}>
                                        
                                        <FontAwesomeIcon 
                                            color={(passwordStrength.chars8) ? colors.success['500'] : colors.danger['500']} 
                                            icon={(passwordStrength.chars8) ? 'fa-solid fa-check' : 'fa-solid fa-times'} />

                                        <Text style={{color : (passwordStrength.chars8) ? colors.success['500'] : colors.danger['500'] }}>
                                            Mindestens 8 Zeichen.
                                        </Text>
                                    </HStack>
                                    
                                    <HStack space={2} mb={1}>
                                        <FontAwesomeIcon 
                                            color={(passwordStrength.oneupper) ? colors.success['500'] : colors.danger['500']} 
                                            icon={(passwordStrength.oneupper) ? 'fa-solid fa-check' : 'fa-solid fa-times'} />
                                        
                                        <Text style={{color : (passwordStrength.oneupper) ? colors.success['500'] : colors.danger['500'] }}>
                                            Mindestens ein Großbuchstabe.
                                        </Text>
                                    </HStack>
                                    
                                    <HStack space={2} mb={1}>
                                        <FontAwesomeIcon 
                                            color={(passwordStrength.onelower) ? colors.success['500'] : colors.danger['500']} 
                                            icon={(passwordStrength.onelower) ? 'fa-solid fa-check' : 'fa-solid fa-times'} />
                                        
                                        <Text style={{color : (passwordStrength.onelower) ? colors.success['500'] : colors.danger['500'] }}>
                                            Mindestens ein Kleinbuchstabe.
                                        </Text>
                                    </HStack>
                                    
                                    <HStack space={2} mb={1}>
                                        <FontAwesomeIcon 
                                            color={(passwordStrength.onedigit) ? colors.success['500'] : colors.danger['500']} 
                                            icon={(passwordStrength.onedigit) ? 'fa-solid fa-check' : 'fa-solid fa-times'} />
                                        
                                        <Text style={{color : (passwordStrength.onedigit) ? colors.success['500'] : colors.danger['500'] }}>
                                            Mindestens eine Zahl.
                                        </Text>
                                    </HStack>
                                    
                                    <HStack space={2}>
                                        <FontAwesomeIcon 
                                            color={(passwordStrength.onespecial) ? colors.success['500'] : colors.danger['500']} 
                                            icon={(passwordStrength.onespecial) ? 'fa-solid fa-check' : 'fa-solid fa-times'} />
                                        
                                        <Text style={{color : (passwordStrength.onespecial) ? colors.success['500'] : colors.danger['500'] }}>
                                            Mindestens ein Sonderzeichen.
                                        </Text>
                                    </HStack>

                                </VStack>
                            </>
                        }
                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15, zIndex: 2}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
            </>
        );
    }
};

export default Personal;