import { useTheme } from "native-base";
import { useDispatch, useSelector} from "react-redux";
import { useNavigate, redirect } from "react-router-dom";
import { Stack, Box, HStack, VStack, Heading, Text, Avatar, Image, Divider, Pressable } from "native-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import _ from "lodash";
import UserHelper from "~/Helper/UserHelper";
import { setUser } from '~/Services/UserSlice';
import {subnavItems} from '~/Components/Profile/Subnav.conf.js';
import { ReactSession } from 'react-client-session';
import { Col, Row } from "react-bootstrap";
import DashedLine from "~/Components/Template/Elements/DashedLine";

/*
if (currentUser?.data?.emergency_uid) {
    window.open(Config.host + '/sos/find/' + currentUser?.data?.emergency_uid);
}

SweetAlert.fire({
    'title': 'Abmelden',
    'text' : 'Möchtest du dich wirklich abmelden?',
    showCancelButton: true,
    customClass : {
        confirmButton: 'btn btn-danger'
    },
    confirmButtonText: 'Abmelden',
    cancelButtonText: 'Abbrechen'
})
.then((result) => {
    if (result.isConfirmed) {
        dispatch(setUser({}));
        navigate('/auth/login');
    }
});

*/

const Profile = () => {
    const { colors } = useTheme();
    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const SweetAlert = withReactContent(Swal);

    return (

        <Row p={2}>
            
            {!_.isEmpty(currentUser) &&
                <Pressable 
                    style={{flex:1, flexBasis: '100%'}} 
                    onPress={() => {
                        navigate('/members/detail/' + currentUser.data.id)
                    }}
                    >
                    <Box shadow={1} style={{margin: '1%'}} borderWidth={1} bg={'white'} borderColor={colors.muted['200']} borderRadius={'lg'} p={4}>
                        <HStack space={3} justifyContent={'flex-start'} alignItems={'center'}>
                            <Avatar bg="muted.400"  alignSelf="center" size="lg" source={{uri : UserHelper.getUserimage(currentUser.data)}} />
                            <VStack>
                                <Heading>{UserHelper.buildUsername(currentUser.data)}</Heading>
                                <Text>Dein öffentliches Profil anschauen</Text>
                            </VStack>
                        </HStack>
                    </Box>
                </Pressable>
            }

            <DashedLine />

            {subnavItems.map((_item, index) => {
              
                if (_item.divider == true) {
                    return <Col xs={12} key={index}><DashedLine /></Col>;
                }
                
                let onPressEvent = null;
                
                if (_item?.internal) {
                    onPressEvent = () => { navigate(_item.internal) };
                }

                if (_item?.external) {
                    onPressEvent = () => { window.open(_item.external) };
                }

                if (_item?.showSOSLink) {
                    onPressEvent = () => {
                        if (currentUser?.data?.emergency_uid) {
                            window.open(Config.host + '/sos/find/' + currentUser?.data?.emergency_uid);
                        }
                    };
                }

                if (_item?.logout) {
                    onPressEvent = () => {
                        SweetAlert.fire({
                            title: 'Abmelden',
                            text: 'Möchtest du dich wirklich abmelden?',

                        })

                        SweetAlert.fire({
                            title: 'Abmelden',
                            text : 'Möchtest du dich wirklich abmelden?',
                            showCancelButton: true,
                            confirmButtonText: 'Ja, abmelden',
                            cancelButtonText: 'Abbrechen'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                dispatch(setUser({}));
                                ReactSession.set('user', {});
                                navigate('/auth/login');
                            }
                        });

                    };
                }


                return <Col className={'pointer'} xs={12} md={6} key={index} onClick={onPressEvent}>
                        <Box shadow={1} justifyContent={'flex-start'} style={{margin: '3%'}} key={index} borderWidth={1} bg={'white'} borderColor={colors.muted['200']} borderRadius={'lg'} p={4}>
                            <HStack>
                                {_item.icon && 
                                    <FontAwesomeIcon color={(_item.iconColor ? _item.iconColor : 'black')} size={'2x'} icon={_item.icon} />
                                }
                            </HStack>                            

                            {_item.image &&
                                <Image alt={'Logo'} resizeMode="contain" width={110} h={25} style={{marginTop: 4}} source={require('~/Assets/layout/logo_color_large.png')} /> 
                            }
                            <Text fontSize={16} fontFamily={'Quicksand'} style={{..._item.titleStyle, fontWeight: 'bold'}}>{_item.title}</Text>

                            <Text fontSize={11} style={{..._item.textStyle}}>{_item.text}</Text>
                        </Box>
                    </Col>
            
            })}
            

          </Row>

    );

}

export default Profile;