import Qonversion, {Environment, LogLevel, QonversionConfigBuilder, UserProperty} from '@qonversion/web-sdk';
import { ReactSession } from 'react-client-session';
import { store } from '~/Services/Store';
import Config from '~/Config/BaseConfig';

export const initializePurchases = () => {
    return;
    
    const state = store.getState();
    const currentUser = state?.user;

    let cookieUser = null;
    let user = null;
    const isDev = true;
    let configuration = null;

    /* Try to catch user */
    try {
        cookieUser = ReactSession.get('user');
        if (cookieUser) {
            cookieUser = JSON.parse(cookieUser);
        }
    } catch(e) {}

    if (!user && currentUser?.data?.id) {
        user = currentUser;
    }

    if (!user && cookieUser?.data?.id) {
        user = cookieUser;
    }

    if (isDev) {
        configuration = new QonversionConfigBuilder(Config.qonversion.projectKey)
            .setEnvironment(Environment.Sandbox) 
            .setLogLevel(LogLevel.Verbose) 
            .setLogTag('M4RLog')
            .build();
        
    } else {
        configuration = new QonversionConfigBuilder(Config.qonversion.projectKey).build();
    }

    if (configuration) {
        const qonversionInstance = Qonversion.initialize(configuration);
        
        if (user && user?.data?.id) {
            qonversionInstance.setUserProperty(UserProperty.CustomUserId, user.data.id);
            qonversionInstance.identify(user.data.id);
        }

    }

};

export const getEntitlements = async () => {
    const qonversionInstance = Qonversion.getSharedInstance();

    const entitlements = await qonversionInstance.getEntitlements();

    return entitlements;
}