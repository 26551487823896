import React from 'react';

import { View, Box, HStack, Avatar, Image, Text, ScrollView, Heading, Button, Badge, useTheme, Skeleton, Pressable, Slider, Divider} from "native-base";
import Loader from '~/Components/Template/Loader';
import { useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from "lodash";
import UserHelper from "~/Helper/UserHelper";
import StylesConfig from '~/Config/Styles';
import { ReactSession } from 'react-client-session';
import Select from 'react-select'
import {toasty} from '~/Helper/Base';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Moment from 'moment';
import 'moment/min/moment-with-locales'

import DatePicker from 'react-datepicker';

const Driver = () => {
    const currentUser = useSelector((state) => state.user);
    const [loading, setLoading] = React.useState(false);
    const { colors } = useTheme();
    const navigate = useNavigate();

    const [formData, setFormData] = React.useState([]);
    
    const SweetAlert = withReactContent(Swal);
    
    const [fields, setFields] = React.useState({
        'class' : {
            'A' : 'A', 
            'A1' : 'A1', 
            'A2' : 'A2', 
            'AN' : 'AN',
            'B196' : 'B196',
            'Sonstiges': 'Sonstiges'
        }
    });

    const _onMount = async () => {
        if (!loading) setLoading(true);

        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/get/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'includes': 'userdata,analytics'})
            })
            .then((res) => res.json())
            .then((json) => {
                if (!_.isEmpty(json.userdata.driverlicense)) {
                    json.userdata.driverlicense = JSON.parse(json.userdata.driverlicense);
                } else {
                    json.userdata.driverlicense = {'class':null, 'since' : null};
                }
                setFormData(json);
                setLoading(false);
            });
        };
    };

    const _onUnMount = async (data) => {
        setLoading(true);
        
        if (!_.isEmpty(currentUser)) {
            let jsonFormData = formData;
            
            await fetch(Config.host + '/api/user/action/set/userdata/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({key : 'driverlicense', 'value_json': JSON.stringify(jsonFormData.userdata.driverlicense)})
            });

            await fetch(Config.host + '/api/user/action/set/user_analytics/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({multi : JSON.stringify(jsonFormData.analytics)})
            }).then(() => {
                toasty('Gespeichert!', 'success');
                setLoading(false);
            });

            
        };
    };

    const _fetchConfig = async (configType) => {
        await fetch(Config.host + '/api/config/get/' + configType +'/', {
            headers: { ...Config.defaultHeaders}
        })
        .then((res) => res.json())
        .then((json) => {
            let _fields = {...fields};
            _fields[configType] = json;
            setFields(_fields);
        });
    };
    React.useEffect(() => {
        if (currentUser) {
            _onMount();
        }
    }, [currentUser])

    React.useEffect(() => {
        Moment.locale('de');
        _fetchConfig('commnication_system');
    }, []);
    
    if (!formData?.userdata) {
        return (<><Loader loading={loading} /></>);
    } else {
        
        return (<>
                
                <Loader loading={loading} />
                <View style={StylesConfig.styles.card.wrapperMt}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Führerschein</Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                    
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Klasse *"
                            className="mb-3"
                        >
                            <Form.Select 
                                value={formData?.userdata?.driverlicense?.class}
                                onChange={(e) => {
                                    let _data = {...formData};
                                    _data.userdata.driverlicense.class = e.target.value;
                                    setFormData(_data);
                                }}
                                >
                                {Object.keys(fields.class).map(item =>  {
                                    return <option value={item}>{fields.class[item]}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Führerschein seit *"
                            className="mb-3"
                        >
                        <input
                            className='form-control'
                            type={'date'} 
                            value={formData.userdata?.driverlicense?.since} 
                            onChange={(e) => {
                                let _data = {...formData};
                                _data.userdata.driverlicense.since = Moment(e.target.value).format('YYYY-MM-DD');
                                setFormData(_data);
                            }} 
                            dateFormat={'dd.MM.yyyy'} />
                        </FloatingLabel>
                    </View>
                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>

                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <Text style={StylesConfig.styles.card.header_text}>Fahrerprofil</Text>
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <Heading size={'md'} color={StylesConfig.color.base}>Deine Fahrstile</Heading>
                        
                        <Divider my={3} />

                        <Text fontSize={'xs'}>
                            Deine Präferenzen zum Fahrstil können Einfluss auf die Touren haben, die du mitfährst. Der Tour-Guide erhält den Durchschnittswert aller Teilnehmer und kann die Tour, den Vorlieben der Teilnehmer anpassen.
                        </Text>
                        

                        {formData.analytics.speeds  && 

                            formData.analytics.speeds.map((item, idx) => {
                                if (!_.isEmpty(item)) {
                                    const iconStyle = {
                                        'gemütlich (StVO)' : {color: StylesConfig.color.success, icon :'fa-regular fa-tachometer-slow'},
                                        'normal (StVO +10 km/h)' : {color: StylesConfig.color.warning, icon :'fa-regular fa-tachometer-average'},
                                        'zügig (StVO +20 km/h)' : {color: StylesConfig.color.danger, icon :'fa-regular fa-tachometer-fast'},
                                        'schnell (kein StVO)' : {color: colors.danger['700'], icon :'fa-regular fa-tachometer-fastest'},
                                    };
                                    return (
                                        <View id={idx} mb={5}>
                                            <HStack mt={3} mb={2} justifyContent={'flex-start'} alignItems={'center'}>
                                                <Box mr={3} justifyContent={'center'} alignItems={'center'} backgroundColor={iconStyle[item.key].color} style={{width: 25, height: 25}}>
                                                    <FontAwesomeIcon color={'white'} icon={iconStyle[item.key].icon}/>
                                                </Box>

                                                <Box>{item.key}</Box>
                                            </HStack>
                                            <Slider
                                                defaultValue={parseInt(item.value)} 
                                                minValue={0} 
                                                maxValue={8} 
                                                step={1}
                                                onChangeEnd={(value) => {
                                                    let _data = {...formData};
                                                    let itemKey = _.findKey(_data.analytics.speeds, function(o){
                                                        return o.key == item.key;
                                                    });
                                                    if (itemKey !== false) {
                                                        _data.analytics.speeds[itemKey] = {'key': item.key, 'value': value};
                                                    }
                                                    
                                                    setFormData(_data);
                                                }}
                                                >
                                                    <Slider.Track>
                                                        <Slider.FilledTrack />
                                                    </Slider.Track>
                                                    <Slider.Thumb />
                                                </Slider>
                                            <HStack justifyContent={'space-between'}>
                                                <Text fontSize={'xs'}>Nie</Text>
                                                <Text fontSize={'xs'} textAlign={'right'}>Sehr gerne</Text>
                                            </HStack>
                                        </View>
                                    );
                                }
                            })
                        }

                        {fields.commnication_system && <>
                            <Heading mt={5} size={'md'} color={StylesConfig.color.base}>Kommunikation</Heading>
                            
                            <Divider my={3} />
                            
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Verfügst du über ein Kommunikations-System? "
                                className="mb-3"
                            >
                                <Form.Select 
                                    value={formData?.analytics?.communication_system}
                                    onChange={e => {
                                        let _data = {...formData};
                                        _data.analytics.communication_system = e.target.value;
                                        setFormData(_data);
                                    }}
                                    >
                                    {Object.keys(fields.commnication_system).map(item =>  {
                                        return <option value={item}>{fields.commnication_system[item]}</option>
                                    })}
                                </Form.Select>
                            </FloatingLabel>

                            </>
                        }

                        <Heading mt={5} size={'md'} color={StylesConfig.color.base}>Einschätzung</Heading>
                        
                        <Divider my={3} />

                        <View>
                            <Text fontSize={'md'} mb={2}>Wie sicher fühlst du dich generell auf dem Motorrad?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.feeling)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(e) => {
                                    setFormData({...formData, analytics : {...formData.analytics, feeling:e.target.value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text fontSize={'xs'}>Sehr unsicher</Text>
                                <Text fontSize={'xs'} textAlign={'center'}>ok</Text>
                                <Text fontSize={'xs'} textAlign={'right'}>Sehr sicher</Text>
                            </HStack>
                        </View>

                        <Heading mt={10} size={'md'} color={StylesConfig.color.base}>Auf den Geraden</Heading>
                        
                        <Divider my={3} />
                        
                        <View mb={10}>
                            <Text fontSize={'md'} mb={2}>Fährst du gerne schnell?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.straight_speed)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, straight_speed:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text fontSize={'xs'}>Nein</Text>
                                <Text fontSize={'xs'} textAlign={'center'}>Manchmal</Text>
                                <Text fontSize={'xs'} textAlign={'right'}>Immer</Text>
                            </HStack>
                        </View>
                        
                        

                        <View>
                            <Text fontSize={'md'} mb={2}>Kannst du zügig Gas geben?</Text>
                            <Text fontSize={'xs'}>Hier geht es einmal um die Leistung deiner Maschine und dein Zug am Gashahn. Je weniger Leistung deine Maschine hat, desto langsamer kannst du den anderen hinterher kommen.</Text>
                            
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.straight_safety)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, straight_safety:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>
                            
                            <HStack justifyContent={'space-between'}>
                                <Text fontSize={'xs'}>Nein</Text>
                                <Text fontSize={'xs'} textAlign={'center'}>Manchmal</Text>
                                <Text fontSize={'xs'} textAlign={'right'}>Immer</Text>
                            </HStack>
                        </View>

                        <Heading mt={10} size={'md'}>In den <Text color={StylesConfig.color.base}>*normalen*</Text> Kurven</Heading>
                        
                        <Divider my={3} />
                        
                        <View mb={10}>
                            <Text fontSize={'md'} mb={2}>Wie schnell fährst du durch Kurven?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.curve_speed)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, curve_speed:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text w={'1/3'} fontSize={'xs'}>Ich bremse stark ab</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'center'}>Normal</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'right'}>Schnell</Text>
                            </HStack>
                        </View>

                        <View mb={10}>
                            <Text fontSize={'md'} mb={2}>Fühlst du dich sicher im Kurvenfahren?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.curve_safety)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, curve_safety:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text w={'1/3'} fontSize={'xs'}>Sehr unsicher</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'center'}>Normal</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'right'}>Sehr sicher</Text>
                            </HStack>
                        </View>


                        <Heading mt={3} size={'md'}>In den <Text color={StylesConfig.color.base}>*spitzen*</Text> Kurven (Spitzkehren)</Heading>
                        
                        <Divider my={3} />
                        
                        <View mb={10}>
                            <Text fontSize={'md'} mb={2}>Wie schnell fährst du durch Spitzkehren?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.sharp_bend_speed)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, sharp_bend_speed:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text w={'1/3'} fontSize={'xs'}>Ich bremse stark ab</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'center'}>Normal</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'right'}>Schnell</Text>
                            </HStack>
                        </View>

                        <View mb={10}>
                            <Text fontSize={'md'} mb={2}>Fühlst du dich sicher im Spitzkehren fahren?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.sharp_bend_safety)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, sharp_bend_safety:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text w={'1/3'} fontSize={'xs'}>Sehr unsicher</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'center'}>Normal</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'right'}>Sehr sicher</Text>
                            </HStack>
                        </View>

                        <Heading mt={3} size={'md'} color={StylesConfig.color.base}>Dein Verantwortungsbewusstsein</Heading>
                        
                        <Divider my={3} />
                        
                        <View mb={10}>
                            <Text fontSize={'md'} mb={2}>Achtest du bei deiner Geschwindigkeit auf anderen Straßenteilnehmer?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.charge_speed)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, charge_speed:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text w={'1/3'} fontSize={'xs'}>Nein</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'center'}>Normal</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'right'}>Viel</Text>
                            </HStack>
                        </View>

                        <View mb={10}>
                            <Text fontSize={'md'} mb={2}>Bist du rücksichtsvoll gegenüber anderen Straßenteilnehmern?</Text>
                            <Slider
                                defaultValue={parseInt(formData?.analytics?.charge_safety)} 
                                minValue={0} 
                                maxValue={8} 
                                step={1}
                                onChangeEnd={(value) => {
                                    setFormData({...formData, analytics : {...formData.analytics, charge_safety:value}});
                                }}
                                >
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>

                            <HStack justifyContent={'space-between'}>
                                <Text w={'1/3'} fontSize={'xs'}>Nein</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'center'}>Normal</Text>
                                <Text w={'1/3'} fontSize={'xs'} textAlign={'right'}>Viel</Text>
                            </HStack>
                        </View>
                        
                    </View>
                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>            

                </View>

                    
            </>
        );
    }
};

export default Driver;