import React, { Component } from 'react';

export default class FormData extends Component {

    constructor(props) {
        super(props);
        this.items = [];
        if (typeof props == 'object') {
            Object.keys(props).map((key) => {
                this.append(key, props[key]);
            });
        }
    };


    append(key, value, stringify = false) {
        if (stringify) {
            this.items.push(encodeURIComponent(key) + '=' + (JSON.stringify(value)));
        } else {
            this.items.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
        }
    };

    build() {
        return this.items.join('&');
    };

};

export const simpleFormData = function(data){
    let formData = new FormData();
    Object.keys(data).map((item) => {
        formData.append(item, data[item]);
        
    });
    return formData.build();
};