import {View, Text, Spinner, Image, VStack, Input, HStack, Box, Button} from 'native-base';

const Loader = ({loading}) => {
    
    return (<div style={{zIndex: 999, position: 'fixed', left:0, top:0}}>
            {loading && 
                <div style={{
                    backgroundColor: '#00000050',
                    position: 'absolute',
                    left: 0,
                    top:0,
                    right: 0,
                    bottom: 0,
                    zIndex: 999999,
                    width: '100vw',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <View rounded={'3xl'} minW={'100px'} minH={'100px'} backgroundColor={'#FFFFFF'} justifyContent={'center'} alignItems={'center'}>
                        <Spinner size="lg"/>
                    </View>
                </div>
            }
        </div>
    );
};

export default Loader;