import Config from '~/Config/BaseConfig';
import {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import UserHelper from '~/Helper/UserHelper';
import _ from 'lodash';
import {Box, View, Avatar, Badge, HStack, Flex, FlatList, Input, Stack, ZStack, Text, ScrollView, Actionsheet, Modal, useTheme, Divider, Pressable} from 'native-base';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import uuid from 'react-uuid';

const ReactionBar = ({type, id, ownerUserId}) => {
    const currentUser = useSelector((state) => state.user);

    const { colors } = useTheme();
    const navigate = useNavigate;
    const [reactions, setReactions] = useState({});
    const [all, setAll] = useState([]);
    const [total, setTotal] = useState(0);
    const [userReaction, setUserReaction] = useState(false)
    const [hintVisible, setHintVisible] = useState(false);
    const [reactionSheetVisible, setReactionSheetVisible] = useState(false);
    
    let reactionBarWidth = 0;
    
    const possibleReactions = [
        'like', 'dislike', 'love', 'lol', 'cry', 'angry', 'think', 'wow'
    ];

    const _reactionStyles = (reaction) => {
        let style = {
            bg: null,
            color: null,
            icon: null,
            size: 20
        }

        if (reaction == 'like') {
            style.icon = 'fa-solid fa-hand-peace';
            style.color = 'white';
            style.size = 18
            style.bg = colors.info['500'];
        }

        if (reaction == 'love') {
            style.icon = 'fa-solid fa-heart';
            style.color = 'white';
            style.size = 15
            style.bg = colors.pink['600'];
        }

        if (reaction == 'lol') {
            style.icon = 'fa-solid fa-face-grin-tears';
            style.color = 'white';
            style.size = 23;
            style.bg = colors.yellow['400'];
        }

        if (reaction == 'dislike') {
            style.icon = 'fa-solid fa-thumbs-down';
            style.color = 'white';
            style.size = 15
            style.bg = colors.primary['900'];
        }

        if (reaction == 'cry') {
            style.icon = 'fa-solid fa-face-sad-tear';
            style.color = 'white';
            style.size = 18;
            style.bg = colors.violet['500'];
        }

        if (reaction == 'angry') {
            style.icon = 'fa-solid fa-face-swear';
            style.color = 'white';
            style.size = 19
            style.bg = colors.danger['600'];
        }

        if (reaction == 'think') {
            style.icon = 'fa-solid fa-face-thinking';
            style.color = 'white';
            style.size = 18
            style.bg = colors.blue['400'];
        }

        if (reaction == 'wow') {
            
            style.icon = 'fa-solid fa-face-surprise';
            style.color = 'white';
            style.size = 19
            style.bg = colors.green['500'];
        }

        if (reaction == 'plus') {
            
            style.icon = 'fa-solid fa-ellipsis';
            style.color = 'white';
            style.size = 15
            style.bg = colors.muted['400'];
        }

        return style;
    }

    const _fetchList = async () => {
        if (!_.isEmpty(currentUser)) {
            
            await fetch(Config.host + '/api/reactions/collection/' + type + '/' + id  + '/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash }
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.length > 0) {
                    let _data = {};
                    let totalCur = 0;
                    let userReaction = false;

                    setAll(json);
                    _.forEach(json, (item) => {
                        
                        if (item.reaction in _data === false) 
                            _data[item.reaction] = [];
                        
                        _data[item.reaction].push(item.user); 

                        if (typeof item.user == 'object' && item.user.id == currentUser.data.id) {
                            userReaction = item.reaction;
                        }

                        totalCur += 1;
                    });
                    setTotal(totalCur);
                    setUserReaction(userReaction);
                    setReactions(_data);
                } else {
                    setTotal(0);
                    setUserReaction(false);
                    setReactions({});

                }
                
            });
        }
    };

    const _setReaction = async (reaction) => {
        if (reaction == 'remove') {
            setUserReaction(false);
        }

        if (!_.isEmpty(currentUser)) {
            await fetch(Config.host + '/api/reactions/set/' + type + '/' + id  + '/'+ reaction + '/' + ownerUserId + '/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash }
            })
            .then((response) => {
                _fetchList()
            });
        }
    };

    useEffect(() => {
        if (currentUser) {
            _fetchList();
        }
    }, [currentUser])

    const _getReactionIcon = (reaction, index) => {
        let style = _reactionStyles(reaction);
        
        if (index == 0) {
            reactionBarWidth = 30;
        } else {
            reactionBarWidth += 30;
        }

        let scaleValue = .7;
        if (type.indexOf('comment') != -1 ) {
            scaleValue = .6;
        }

        return ( 
            <Box 
                h={19}
                key={reaction +'_' + index} 
                w={19} 
                justifyContent={'center'} 
                alignItems={'center'} 
                rounded={'full'} 
                bg={style.bg} 
                zIndex={10000-index} 
                style={{marginLeft: 10*index}}
            >
                <FontAwesomeIcon size={'sm'} color={style.color} icon={style.icon} />
            </Box>
        );
    }

    const _renderReactionInteraction = function() {
        
        return (
            <HStack flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'} >
                {possibleReactions.map((reactionCode) => {
                    
                    let style = _reactionStyles(reactionCode);
                    
                    return (
                        <Pressable key={uuid()} onPress={() => {
                            _setReaction(reactionCode);
                            setHintVisible(false);
                        }}>
                            <Box
                                justifyContent={'center'} 
                                alignItems={'center'} 
                                rounded={'full'}
                                style={{padding: 10, margin: 5}} 
                                bg={style.bg}
                                >
                                <FontAwesomeIcon size={25} color={style.color} icon={style.icon} />
                            </Box>
                        
                        </Pressable>
                    );

                })}   
            </HStack>
        );
    };


    const ReactionTabScreens = () => {
        const [activeIndex, setActiveIndex] = useState(0);
        const [activeItems, setActiveItems] = useState([]);
        const defaultStyle = {
            borderBottomWidth: 3,
            marginHorizontal: 5,
            paddingHorizontal: 15,
            paddingVertical: 10, 
            borderBottomColor: colors.muted['200']
        }
        const activeStyle = {
            ...defaultStyle,
            borderBottomColor: colors.info['600']
        };

        const defaultListStyle = {
            position : 'absolute',
            top: 0,
        };
        
        const _activeItems = () => {
            let itemArray = [];

            if (activeIndex == 0) {
                all.map((item) => {
                    let tmpObj = {
                        user : item.user,
                        reaction : item.reaction
                    };
                    itemArray.push(tmpObj);
                });
            } else {
                Object.keys(reactions).map((reaction, idx) => {
                    if (activeIndex == idx+1) {
                        
                        reactions[reaction].map((item) => {
                            let tmpObj = {
                                user : item,
                                reaction : reaction
                            };
                            itemArray.push(tmpObj);
                        })

                    }
                })
            }

            setActiveItems(itemArray);
        }
        
        useEffect(() => {
            _activeItems();
        }, [activeIndex])

        return (<>
                <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                    <HStack>
                        <Pressable onPress={() => {
							setActiveIndex(0);
						}}>
                            <Box style={(activeIndex == 0) ? activeStyle : defaultStyle}>
                                <HStack space={1} justifyContent={'center'} alignItems={'center'}>
                                    <Text lineHeight={27} fontSize={14}>Alle</Text>
                                    <Text>{total}</Text>
                                </HStack>  
                            </Box>
                        </Pressable>
                        {Object.keys(reactions).map((reaction, idx) => {

                            let style = _reactionStyles(reaction);
                            return (
                                <Pressable key={'reaction_tab_' + idx} onPress={() => {
									setActiveIndex((idx+1));
								}}>
                                    <Box style={(activeIndex == (idx+1)) ? activeStyle : defaultStyle}>
                                        <HStack space={1} justifyContent={'center'} alignItems={'center'}>
                                            {_getReactionIcon(reaction, 0)}
                                            <Text>{reactions[reaction].length}</Text>
                                        </HStack>    
                                    </Box>
                                </Pressable>
                            );

                        })}
                    </HStack>
                </ScrollView>
                
                <FlatList  
                    data={activeItems}
                    minH={100} 
                    maxH={500} 
                    width={'100%'} 
                    extraData={activeItems}
                    renderItem={({item, index}) => {
                        if (item?.user && typeof item.user == 'object') {
                            
                            let image = UserHelper.getUserimage(item.user);

                            return (<>
                                    <Pressable onPress={() => {
                                        setReactionSheetVisible(false);
                                        navigate('/members/detail/' + item.user.id)
                                    }}>
                                        <HStack 
                                            p={2} 
                                            mt={1} 
                                            space={2} 
                                            justifyContent={'flex-start'}
                                            alignItems={'center'}
                                            key={`index_${activeIndex}_id_${index}`}
                                        >
                                            <ZStack h={50} w={50} justifyContent={'flex-end'} alignItems={'flex-end'}>
                                                <Avatar bg="muted.300" source={{uri: image}} />
                                                <Box shadow={4} right={-8} bottom={-8}>{_getReactionIcon(item.reaction, 0)}</Box>
                                            </ZStack>
                                            
                                            <Text>{UserHelper.buildUsername(item.user)}</Text>
                                        </HStack>
                                    </Pressable>
                                    {index < activeItems.length-1 &&
                                        <Divider mb={1} mt={1}/>
                                    }
                                </>
                            )
                        }
                    }}
                />

            </>
        );

    };

    

    return (<>
        {reactions == {}
            ? ''
            : <HStack h={30} justifyContent={'center'} alignItems={'center'}>
                <OverlayTrigger
                    trigger={['click']}
                    key={'top'}
                    show={hintVisible}
                    placement={'top'}
                    overlay={
                        <Popover>
                            <Popover.Body>
                                {_renderReactionInteraction()}
                            </Popover.Body>
                        </Popover>
                    }
                    >
                    
                        {userReaction
                            ?   <Pressable onPress={() => _setReaction('remove')}  onLongPress={() => setHintVisible(!hintVisible)}>
                                    <HStack space={2} justifyContent={'flex-start'} alignItems={'center'}>
                                        {userReaction && <View style={{marginLeft: -10}}>{_getReactionIcon(userReaction, 0)}</View>} 
                                        <Text fontWeight={'bold'}>
                                            {(userReaction == 'dislike') ? 'Gefällt dir nicht' : 'Gefällt dir'}
                                        </Text>
                                    </HStack>
                                </Pressable>
                            :   <Pressable onPress={() => _setReaction('like')} onLongPress={() => setHintVisible(!hintVisible)}>
                                    <HStack space={2} justifyContent={'flex-start'} alignItems={'center'}>
                                        <Text fontWeight={'regular'}>{hintVisible ? 'Ausblenden' : 'Gefällt dir'}</Text>
                                    </HStack>
                                </Pressable>   
                        }
                    
                    
                </OverlayTrigger>
                
                {total > 0 && <Divider orientation='vertical' maxH={5} mx={2} />}
                
                <Actionsheet isOpen={reactionSheetVisible} onClose={() => {
                    setReactionSheetVisible(false)
                }}>
                    <Actionsheet.Content>
                        <ReactionTabScreens />
                    </Actionsheet.Content>
                </Actionsheet>
                <Pressable onPress={() => {
					setReactionSheetVisible(true);
				}}>
                    <ZStack justifyContent={'center'} alignItems={'center'} style={{minWidth:  15 + (Object.keys(reactions).length * 15) }} h={30}>
                        {
                            Object.keys(reactions).map((item, index) => {
                                if (index <= 2) {
                                    return _getReactionIcon(item, index);
                                }
                                
                                if (index == 3) {
                                    return _getReactionIcon('plus', index);
                                }
                            })
                        }
                        
                    </ZStack>
                </Pressable>
                {total > 0 && 
                    <Text style={{marginLeft: 0}}>{total}</Text>
                }
            </HStack>
        }
        </>
    );
}


export default ReactionBar;