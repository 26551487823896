import {useState, useEffect} from 'react';

// Import React and Component
import {Box, View, HStack, Flex, Text, FlatList, VStack, useTheme, Badge, Spinner, Heading, Divider, Pressable} from 'native-base';
import Config from '~/Config/BaseConfig';
import StylesConfig from '~/Config/Styles';
import Moment from 'moment';
import 'moment/min/moment-with-locales';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from 'lodash';
import Commercial from '~/Components/Commercial';
import TourItem from '~/Components/Touren/List/Item';
import PostItem from '~/Components/Posts/List/Item';
import UserItem from '~/Components/Users/List/Item';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Weather from '~/Components/WeatherBlock';
import {fetchConfig} from '~/Helper/ConfigHelper';
import { AdMobBanner } from '~/Components/AdMobs';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Flag from 'react-world-flags'
import { Col, Container, Row } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Platform from 'react-platform-js'
import uuid from 'react-uuid';

const HomeScreen = () => {
    const navigate = useNavigate();
    const { colors } = useTheme();
    const currentUser = useSelector((state) => state.user);

    const [loading, setLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);

    const [touren, setTouren] = useState(null);
    const [posts, setPosts] = useState(null);
    const [scheduledTouren, setScheduledTouren] = useState(null);    
    const [birthday, setBirthdays] = useState(null);    
    const [systemMsg, setSystemMsg] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const daysForcast = 30;

    const _fetchDashbaordData = async (viewtype) => {   
        if (currentUser) {
            await fetch(Config.host + '/api/dashboard/collection/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash},
                body: simpleFormData({
                    'filter': JSON.stringify({
                        'date_to': Moment().add(daysForcast, 'days').format('YYYY-MM-DD'),
                        'umkreis': 'bis 50 km',
                    })
                })
            })
            .then((res) => res.json())
            .then((json) => {
                
                if (_.isArray(json.birthday)) {
                    setBirthdays(json.birthday)
                }
                if (_.isArray(json.tour)) {
                    setTouren(json.tour)
                }

                if (_.isArray(json.post)) {
                    setPosts(json.post)
                }

                if (_.isArray(json.scheduled)) {
                    setScheduledTouren(json.scheduled)
                }
                setRefreshing(false);
            });

        }
    };

    const _fetchSystemMessages = async() => {
        if (currentUser) {
            await fetch(Config.host + '/api/dashboard/systemlog/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash},
                body : simpleFormData({'device' : 'all,web'})
            })
            .then((res) => res.json())
            .then((json) => {
                setSystemMsg([...systemMsg, ...json]);
            });

        }
    };


    useEffect(() => {
        setSystemMsg([]);
        Moment.locale('de');

        if (currentUser) {
            _fetchSystemMessages();
            _fetchDashbaordData();
        }


    }, [currentUser]);

    
    useEffect(() => {
        if (refreshing) {
            _fetchSystemMessages();
            _fetchDashbaordData();
        }
    }, [refreshing]);

    const SystemMessage = ({item}) => {
        let backgroundColor = null;
        let title = null || item?.title;
        let icontype = null;

        if (item?.type == 'info') {
            if (!title) {
                title = 'Info';
            }
            backgroundColor = 'info';
            icontype = 'fa-solid fa-circle-info';
        }

        if (item?.type == 'warning') {
            if (!title) {
                title = 'Hinweis';
            }
            backgroundColor = 'warning';
            icontype = 'fa-solid fa-triangle-exclamation';
        }

        if (item?.type == 'danger') {
            if (!title) {
                title = 'Wichtig';
            }
            backgroundColor = 'danger';
            icontype = 'fa-solid fa-hexagon-exclamation';
        }

        const [expanded, setExpanded] = useState(false);

        return (<div key={uuid()}>
            
            <Pressable onPress={() => setExpanded(!expanded)}>
                <HStack justifyContent={'space-between'} alignItems={'center'} style={{padding: 10}} backgroundColor={ colors[backgroundColor]['500'] }>
                    <HStack space={3} justifyContent={'flex-start'} alignItems={'center'}>
                        <FontAwesomeIcon size={20} icon={icontype} color={'white'} />
                        <Heading color={'white'} fontSize={'md'}>{title}</Heading>
                    </HStack>
                    <FontAwesomeIcon icon={(expanded) ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'} color={'white'} style={{marginRight: 10}}/>
                </HStack>
            </Pressable>    

            {expanded && 
                <View backgroundColor={colors[backgroundColor]['50']} style={{padding: 25}}>
                    {item?.message && <Box>{item.message}</Box>}
                    {item?.created_at && <Text mt={2} fontSize={10}>vom {Moment(item.created_at).format('LL')}</Text>}
                </View>
            }
            
            </div>
        );
    }

    return (<>
    
        <View>
            {/*Platform.OS == 'iOS' &&
                <Pressable onPress={() => window.open('market://details?id=com.milchshake.m4r')}>
                    <HStack mb={1} justifyContent={'space-between'} alignItems={'center'} style={{padding: 10}} backgroundColor={ colors.coolGray['400'] }>
                        <HStack space={3} justifyContent={'flex-start'} alignItems={'center'}>
                            <FontAwesomeIcon size={'2x'} icon={'fa-brands fa-apple'} color={'white'} />
                            <VStack>
                                <Heading color={'white'} fontSize={'md'}>Meet4Ride als App</Heading>
                                <Text color={'white'}>im Apple Store</Text>
                            </VStack>
                        </HStack>
                        <FontAwesomeIcon icon={'fa-solid fa-square-up-right'} color={'white'} style={{marginRight: 10}}/>
                    </HStack>
                </Pressable>
            */}

            {/*Platform.OS == 'Android' &&
                <Pressable onPress={() => window.open('market://details?id=com.milchshake.m4r')}>
                    <HStack mb={1} justifyContent={'space-between'} alignItems={'center'} style={{padding: 10}} backgroundColor={ colors.coolGray['400'] }>
                        <HStack space={3} justifyContent={'flex-start'} alignItems={'center'}>
                            <FontAwesomeIcon size={'2x'} icon={'fa-brands fa-google-play'} color={'white'} />
                            <VStack>
                                <Heading color={'white'} fontSize={'md'}>Meet4Ride als App</Heading>
                                <Text color={'white'}>im Google Play Store</Text>
                            </VStack>
                        </HStack>
                        <FontAwesomeIcon icon={'fa-solid fa-square-up-right'} color={'white'} style={{marginRight: 10}}/>
                    </HStack>
                </Pressable>
            */}

            <Row>
                <Col xs={12} sm={6}>

                    {scheduledTouren == null && touren == null &&  posts == null &&
                        <Spinner m={30} size={'lg'} />
                    }

                    {systemMsg.length > 0 && 
                        <>
                            <VStack space={1}>
                                {systemMsg.map((item, index) => <SystemMessage key={index} item={item}/>)}
                            </VStack>
                        </>
                    }

                    <Weather />
                    
                    <AdMobBanner position={'dashboard_1'} />

                    { scheduledTouren == null || (_.isArray(scheduledTouren) && scheduledTouren.length > 0) &&
                        <>
                        <View style={StylesConfig.styles.card.wrapperMt}>
                            <View style={StylesConfig.styles.card.header}>
                                <Text style={StylesConfig.styles.card.header_text}>Deine anstehende Touren</Text>
                            </View>

                            <View style={StylesConfig.styles.card.body}>
                            <FlatList 
                                ListFooterComponent={''} 
                                data={scheduledTouren}
                                getItemLayout={(data, index) => ({
                                    length : 90, offset : 90 * index, index
                                })}
                                removeClippedSubviews
                                extraData={scheduledTouren}
                                renderItem={({item, index}) => <TourItem key={`scheduledTouren_list_item_${item.master_id}`} tour_id={item.master_id} />}
                            />
                            </View>
                        </View>
                        <AdMobBanner position={'dashboard_1'} />   
                        </>     
                    }

                        
                    
                    { touren == null || (_.isArray(touren) && touren.length > 0) &&
                    
                        <View style={StylesConfig.styles.card.wrapperMt}>
                            <View style={StylesConfig.styles.card.header}>
                                <Text style={StylesConfig.styles.card.header_text}>{daysForcast}-Tage Vorschau</Text>
                                <Text style={{...StylesConfig.styles.card.header_text, fontSize: 12}}>aus deiner Umgebung</Text>
                            </View>

                            <View style={StylesConfig.styles.card.body}>
                            <FlatList 
                                ListFooterComponent={''} 
                                data={touren}
                                getItemLayout={(data, index) => ({
                                    length : 90, offset : 90 * index, index
                                })}
                                removeClippedSubviews
                                extraData={touren}
                                renderItem={({item, index}) => <TourItem key={`scheduledTouren_list_item_${item.master_id}`} tour_id={item.master_id} />}
                            />
                            </View>
                        </View>
                    }
                    
                    <Commercial system={'App'} showBlock pos={3}/>

                </Col>

                
                        
                    { posts == null || (_.isArray(posts) && posts.length > 0) &&
                        
                        <Col xs={12} sm={6}>
                            <div style={StylesConfig.styles.card.wrapperMt}>
                                <View style={StylesConfig.styles.card.header}>
                                    <Text style={StylesConfig.styles.card.header_text}>Neuigkeiten</Text>
                                    <Text style={{...StylesConfig.styles.card.header_text, fontSize: 12}}>von deinen Freunden</Text>
                                </View>

                                <View style={{...StylesConfig.styles.card.body, padding: 0, backgroundColor: 'transparent'}}>
                                
                                    {posts.map((item, index) => 
                                        <div style={{zIndex: null}}>
                                            <PostItem key={`tourItem_list_item_${item.master_id}`} idx={index} count={posts.length} post={{post_id: item.master_id}} />
                                            {index!= 0 && index%5 == 0 && <View mb={5}><AdMobBanner position={'posts_1'} /></View>}
                                        </div> 
                                    )}
                                    
                                </View>
                            </div>
                            <AdMobBanner position={'dashboard_1'} />
                        </Col>
                    }

                

            </Row>


            <HStack space={4} justifyContent={'center'} alignItems={'center'} p={5}>
                <Flag code="DE" height="10" />
                <Text color={'muted.500'}>Made in Germany</Text>
            </HStack>

            <Box style={{height: 80}} />
        </View>

        <Helmet>
            <title>Dashboard</title>
        </Helmet>
    </>
  );
};
 
export default HomeScreen;