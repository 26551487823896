import React from 'react';

import { View, Box, HStack, VStack, Text, useTheme, Pressable, Switch, Button} from "native-base";
import Loader from '~/Components/Template/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '~/Services/UserSlice';

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Config from '~/Config/BaseConfig';
import { simpleFormData } from '~/Helper/FormHelper';
import _ from "lodash";
import UserHelper from "~/Helper/UserHelper";
import StylesConfig from '~/Config/Styles';
import { ReactSession } from 'react-client-session';
import {toasty} from '~/Helper/Base';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { isBrowser, isMobile } from 'react-device-detect';
import QRCode from "qrcode.react";

const Emergency = () => {
    
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.user);
    const SweetAlert = withReactContent(Swal);
    const { colors } = useTheme();
    const [loading, setLoading] = React.useState(true);
    const [formData, setFormData] = React.useState({});
    const [organspender, setOrganspender] = React.useState(false);
    const [svg, setSvg] = React.useState(null);
    const [fields, setFields] = React.useState({
        'bloodgroup' : {
            'Nicht Bekannt' : 'Nicht Bekannt', 
            'A + Positiv' : 'A + Positiv', 
            'A - Negativ' : 'A - Negativ', 
            'B + Positiv' : 'B + Positiv', 
            'B - Negativ' : 'B - Negativ', 
            'AB + Positiv' : 'AB + Positiv', 
            'AB - Negativ' : 'AB - Negativ', 
            '0 + Positiv' : '0 + Positiv', 
            '0 - Negativ' : '0 - Negativ', 
        },
        'diabetes' : {
            'Keine Angabe' : 'Keine Angabe',
            'Nein' : 'Nein',
            'Diabetes Typ 1' : 'Diabetes Typ 1',
            'Diabetes Typ 2' : 'Diabetes Typ 2',
        },
        'organdonor' : {
            'Keine Angabe' : 'Keine Angabe',
            'Nein' : 'Nein',
            'Ja' : 'Ja',
        },
        
    });
    
    React.useEffect(() => {
        if (currentUser && loading) {
            _onMount();
        }
    }, [currentUser])


    const _onMount = async () => {
        if (!loading) setLoading(true);

        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/get/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: { ...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'includes': 'userdata,emergency'})
            })
            .then((res) => res.json())
            .then((json) => {

                if (!json?.emergency) {                
                    json.emergency = {};
                }

                setFormData(json);
                setLoading(false);
            });

        };
    };

    const _onUnMount = async () => {
        if (!loading) setLoading(true);
        if (!_.isEmpty(currentUser)) {
            fetch(Config.host + '/api/user/action/set/userdata/' + currentUser.data.id +'/', {
                method: 'POST',
                headers: {...Config.defaultHeaders, 'token': currentUser.token, 'X-CSRF-TOKEN': currentUser.csrf.hash },
                body : simpleFormData({'key': 'emergency', 'value_json' : JSON.stringify(formData?.emergency)})
            })
            .then(() => {
                toasty('Daten gespeichert', 'success');
                setLoading(false);
            })
        };
    
    };

    const _saveQrCodeToMediaLibrary = async ()  => {
            const canvas = document.getElementById("emergencyQrCode");
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = "qrcode-"+ formData.userdata.emergency_uid +".png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
    };

    

    if (!formData) {
        return (<><Loader loading={loading} /></>);
    } else {
        return (
            <>
                <Loader loading={loading} />
                {formData?.userdata?.emergency_uid && 
                    <View style={StylesConfig.styles.card.wrapperMt}>
                        <View style={StylesConfig.styles.card.header}>
                            <VStack justifyContent={'space-between'} space={3}>
                                <Text style={{...StylesConfig.styles.card.header_text, color: colors.danger['700']}}>Notfall Code</Text>
                            </VStack>
                            
                        </View>

                        <View style={{...StylesConfig.styles.card.body, alignItems:'center'}}>
                            <Text mb={5}>
                                Drucke dir den Notfallcode aus und trage diesen bei dir - ob als Aufkleber auf dem Führerschein oder einlaminiert in deiner Geldbörse. Notfall-Karten werden demnächst bestellbar sein. 
                            </Text>
                            
                            
                            <Pressable onPress={() => _saveQrCodeToMediaLibrary()}>
                                <QRCode id={'emergencyQrCode'} level={'Q'} size={300} value={Config.host +'/sos/find/' + formData.userdata.emergency_uid} />

                                <Button 
                                    mt={3} 
                                    leftIcon={<FontAwesomeIcon color={'white'} icon={'fa-solid fa-save'} />} 
                                    onPress={() => _saveQrCodeToMediaLibrary()}
                                    >
                                    Bild herunterladen
                                </Button>
                            </Pressable>
                            
                        </View>
                    </View>
                }

                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <VStack justifyContent={'space-between'} space={3}>
                            <Text style={{...StylesConfig.styles.card.header_text, color: colors.danger['700']}}>Notfallkontakt</Text>
                        </VStack>
                        
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            >
                            <input
                                className='form-control'
                                type={'text'}
                                value={formData?.emergency?.name} 
                                onChange={(e) => {
                                    setFormData({...formData, emergency: {...formData?.emergency, name: e.target.value}});
                                }} 
                                />
                        </FloatingLabel>

                        <View h={19}/>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Handynummer"
                            >
                            <input
                                className='form-control'
                                type={'text'}
                                value={formData?.emergency?.number} 
                                onChange={(e) => {
                                    setFormData({...formData, emergency: {...formData?.emergency, number: e.target.value}});
                                }} 
                                />
                        </FloatingLabel>

                        <View h={19}/>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="E-Mail"
                            >
                            <input
                                className='form-control'
                                type={'text'}
                                value={formData?.emergency?.email} 
                                onChange={(e) => {
                                    setFormData({...formData, emergency: {...formData?.emergency, email: e.target.value}});
                                }} 
                                />
                        </FloatingLabel>
                        
                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15, zIndex: 2}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
                
                <View style={StylesConfig.styles.card.wrapper}>
                    <View style={StylesConfig.styles.card.header}>
                        <VStack justifyContent={'space-between'} space={3}>
                            <Text style={{...StylesConfig.styles.card.header_text, color: colors.danger['700']}}>Angaben für den Rettungsdienst</Text>
                        </VStack>
                        
                    </View>

                    <View style={StylesConfig.styles.card.body}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Blutgruppe"
                            className="mb-3"
                        >
                            <Form.Select 
                                value={formData?.emergency?.bloodgroup}
                                onChange={(e) => {
                                    setFormData({...formData, emergency: {...formData?.emergency, bloodgroup: e.target.value}})
                                }}
                                >
                                {Object.keys(fields.bloodgroup).map(item =>  {
                                    return <option value={item}>{fields.bloodgroup[item]}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Diabetes"
                            className="mb-3"
                        >
                            <Form.Select 
                                value={formData?.emergency?.diabetes}
                                onChange={(e) => {
                                    setFormData({...formData, emergency: {...formData?.emergency, diabetes: e.target.value}})
                                }}
                                >
                                {Object.keys(fields.diabetes).map(item =>  {
                                    return <option value={item}>{fields.diabetes[item]}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Unverträglichkeiten"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.emergency?.incompatibilities} 
                                onChange={(e) => {
                                    setFormData({...formData, emergency: {...formData?.emergency, incompatibilities: e.target.value}});
                                }} 
                                />
                        </FloatingLabel>

                        
                        <View h={19}/>
                        
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Sonstige Informationen für den Rettungsdienst"
                            >
                            <input
                                className='form-control'
                                type={'text'} 
                                value={formData?.emergency?.other} 
                                onChange={(e) => {
                                    setFormData({...formData, emergency: {...formData?.emergency, other: e.target.value}});
                                }} 
                                />
                        </FloatingLabel>

                        <View h={19}/>

                        <Pressable onPress={() => {
                            let setValue = 'Nein';
                            if (formData?.emergency?.organdonor != 'Ja') {
                                setValue = 'Ja';
                            }
                            setFormData({...formData, emergency: {...formData?.emergency, organdonor: setValue}})
                        }}>
                            <HStack space={5} justifyContent={'flex-start'} alignItems={'center'}>
                                <Switch size="sm" isChecked={formData?.emergency?.organdonor == 'Ja'} onToggle={() => {
                                    let setValue = 'Nein';
                                    if (formData?.emergency?.organdonor != 'Ja') {
                                        setValue = 'Ja';
                                    }
                                    setFormData({...formData, emergency: {...formData?.emergency, organdonor: setValue}})
                                }}/>
                                <Box>Organspender</Box>
                            </HStack>
                        </Pressable>
                        <small className="form-text">Weitere Informationen hier: <a href="https://www.organspende-info.de/" target="_blank">https://www.organspende-info.de/</a></small>
                        
                        
                        
                    </View>

                    <View style={{...StylesConfig.styles.card.footer, marginBottom: 35, paddingVertical: 15, zIndex: 2}}>
                        <HStack justifyContent={'flex-end'}>
                            <Button onPress={() => _onUnMount()}>Speichern</Button>
                        </HStack>
                    </View>
                </View>
            </>
        );
    }
};

export default Emergency;