import {Box, HStack, Text, VStack, Heading, useTheme} from 'native-base';

import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StylesConfig from '~/Config/Styles';
import Moment from 'moment';
import 'moment/min/moment-with-locales';

import TourHelper from '~/Helper/TourHelper';

const TourListItem = ({...props}) => {
    const { colors } = useTheme();
    
    if (!props?.item?.date)
        return;

    const getIcon = function(status, wasGuide, isPast){
        let icon = null;
        let style = {
            box : {
                width: 40,
                height: 40,
                justifyContent : 'center',
                alignItems:'center',
                backgroundColor: 'red',
                marginRight: 10,
                backgroundColor: colors.blue['400'],
                opacity : 1
            },
            icon : {
                size: 'lg',
                color:'white'
            }
        };

        //if (status == 2) {
            icon = 'fa-solid fa-square-check';
            //style.box.backgroundColor = colors.danger['400'];
        //}
        
        if (wasGuide) {
            icon = 'fa-solid fa-shield-check';
            style.box.backgroundColor = colors.primary['500'];
        }
        
        if (!isPast) {
            icon = "fa-solid fa-hourglass-clock";
            style.box.opacity = 0.3;
        }

        return <Box style={style.box}><FontAwesomeIcon size={style.icon.size} color={style.icon.color} icon={icon}/></Box>;
    };

    const _humanize = function(date, time) {
        const DateObj = TourHelper.getTourStatus(date, time, null, true);
        
        if (DateObj.status == 0 || DateObj.status == 1) {
            return Moment(date + ' ' + time).format('LL');
        } else {
            return DateObj.text;
        }
        
    };

    return (<HStack mb={3}>
            {getIcon(props.item.status, props.item.wasGuide, props.item.isPast)}
            <VStack justifyContent={'center'}>
                <Heading size={'sm'}>{props.item.title}</Heading>
                <Text>{_humanize(props.item.date, props.item.time)}</Text>
            </VStack>
        </HStack>);
}

const TourList = ({...props}) => {
    
    const _runList = function() {
        let itemArray = [];

        _.forEach(props.list, (item, key) => {
            itemArray.push(<TourListItem key={key} item={item} />)
        });

        return itemArray;
    }

    return (<>{_runList()}</>);
        
};

export default TourList;